import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';
import { Error } from '../types/api/Error';

export type State = Error;

const [getNodeError, setNodeError] = createStore<State | undefined>(
  StoreId.NodeError,
  undefined,
);

export const reset = setNodeError(undefined);

export { getNodeError, setNodeError };

export const useNodeError = () => {
  const [nodeError] = useStore<State>(StoreId.NodeError);
  return nodeError;
};
