import { useEffect } from 'react';
import ActionCable, { Channel } from 'actioncable';
import { config } from '../config';
import { updateAutomationLogs } from './useAutomationLogs';
import { useAuth } from './useAuth';
import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';
import { WebSocketChannelId } from '../types/api/WebSocketChannelId';
import { useAutomationId } from './useAutomationId';
import { setSocket, setVariable } from './useGraph';
import { updateNotifications } from './useNotifications';
import { setReport } from './reports/useReports';
import { setRule } from './ruleEngine/useRules';
import { addScreenshot } from './reports/useScreenshots';
import { setAutomation } from './useAutomations';
import { setAutomation as setDetailAutomation } from './useAutomation';

const wsBaseUrl = config.apiBaseUrl.replace(/http/gi, 'ws');
const cable = ActionCable.createConsumer(`${wsBaseUrl}/websocket`);

export type State = {
  LogsChannel?: Channel;
  ReportsChannel?: Channel;
  RulesChannel?: Channel;
  ScreenshotsChannel?: Channel;
  NotificationsChannel?: Channel;
  AutomationChannel?: Channel;
};

const { setState, getState } = createStore<State>(
  StoreId.WebSocketChannels,
  {},
);

const received = (data: any, channel: WebSocketChannelId) => {
  if (channel === WebSocketChannelId.LogsChannel) {
    updateAutomationLogs(data);
  }

  if (channel === WebSocketChannelId.AutomationChannel) {
    // TODO: is there a better way?
    if (data.schema) {
      setSocket(data);
      return;
    }
    // automation channel sending automation
    if (data.organization) {
      setAutomation(data);
      setDetailAutomation(data);
      return;
    }
    // automation channel sending variable
    setVariable(data);
  }

  if (channel === WebSocketChannelId.NotificationsChannel) {
    updateNotifications(data);
  }

  if (channel === WebSocketChannelId.ReportsChannel) {
    setReport(data);
  }

  if (channel === WebSocketChannelId.RulesChannel) {
    setRule(data);
  }

  if (channel === WebSocketChannelId.ScreenshotsChannel) {
    addScreenshot(data);
  }
};

export const removeSubscription = (id: WebSocketChannelId) => {
  const channels = getState();
  const { [id]: channel, ...rest } = channels;
  channel?.unsubscribe();
  setState({ ...rest });
};

export const createSubscription = (channel: WebSocketChannelId) =>
  cable.subscriptions.create(
    { channel },
    { received: (data: any) => received(data, channel) },
  );

export const createSubscriptionWithId = (
  channel: WebSocketChannelId,
  id: string,
) =>
  cable.subscriptions.create(
    { channel, id },
    { received: (data: any) => received(data, channel) },
  );

export const addSubscription = (channel: WebSocketChannelId, id?: string) => {
  const channels = getState();

  const subscribedChannel = id
    ? createSubscriptionWithId(channel, id)
    : createSubscription(channel);

  setState({ ...channels, [channel]: subscribedChannel });
};

export const useWebSocket = () => {
  const { isAuthenticated } = useAuth();
  const automationId = useAutomationId();

  useEffect(() => {
    if (!automationId)
      return () => removeSubscription(WebSocketChannelId.AutomationChannel);

    addSubscription(WebSocketChannelId.AutomationChannel, automationId);

    return () => removeSubscription(WebSocketChannelId.AutomationChannel);
  }, [automationId]);

  useEffect(() => {
    if (!isAuthenticated)
      return () => {
        removeSubscription(WebSocketChannelId.ReportsChannel);
        removeSubscription(WebSocketChannelId.RulesChannel);
        removeSubscription(WebSocketChannelId.ScreenshotsChannel);
        removeSubscription(WebSocketChannelId.NotificationsChannel);
        removeSubscription(WebSocketChannelId.AutomationChannel);
      };

    addSubscription(WebSocketChannelId.ReportsChannel);
    addSubscription(WebSocketChannelId.RulesChannel);
    addSubscription(WebSocketChannelId.ScreenshotsChannel);
    addSubscription(WebSocketChannelId.NotificationsChannel);

    return () => {
      removeSubscription(WebSocketChannelId.RulesChannel);
      removeSubscription(WebSocketChannelId.ReportsChannel);
      removeSubscription(WebSocketChannelId.ScreenshotsChannel);
      removeSubscription(WebSocketChannelId.NotificationsChannel);
    };
  }, [isAuthenticated]);

  return {};
};
