import { useEffect } from 'react';

export const useKeyPress = (key: string, onPress: () => void) => {
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => event.key === key && onPress();

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [key, onPress]);
};
