import styled, { css } from 'styled-components';
import { Label } from '../v2/typography/Typography';
import { media } from '../../styles/media';

interface ContainerProps {
  checked?: boolean;
  disabled?: boolean;
  error?: boolean;
  invert?: boolean;
}

export const Container = styled.div<ContainerProps>`
  transition: color 0.35s, border-color 0.35s;
  position: relative;
  height: 22px;
  width: 22px;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  background-color: ${({ theme }) => theme.color.lightest};
  color: ${({ theme }) => theme.color.lightGrey};
  border-radius: 4px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  align-items: center;

  flex-shrink: 0;
  flex-grow: 0;

  input {
    position: absolute;
    width: 22px;
    height: 22px;
    opacity: 0;
    cursor: pointer;
  }

  ${({ invert }) =>
    invert &&
    css`
      order: 2;
      margin-left: ${({ theme }) => theme.spacing.xs};
    `}

  svg {
    transition: opacity 0.1s, margin 0.35s;
    width: 100%;
    height: 100%;
    padding: 3px;
    box-sizing: border-box;
    pointer-events: none;
    opacity: 0;
    margin-top: -4px;

    ${({ checked }) =>
      checked &&
      css`
        opacity: 1;
        margin-top: 0;
      `}
  }

  ${({ checked }) =>
    checked &&
    css`
      color: ${({ theme }) => theme.color.brandSecondary};
      border-color: ${({ theme }) => theme.color.brandSecondary};
    `}

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.color.danger};
      border-color: ${({ theme }) => theme.color.danger};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.lightGrey};
      background-color: ${({ theme }) => theme.color.backgroundGrey};
      border-color: ${({ theme }) => theme.color.lightGrey};
    `}
`;

export const CheckBoxLabel = styled(Label)<ContainerProps>`
  margin-left: ${({ theme }) => theme.spacing.s};
  user-select: none;
  font-weight: inherit;

  ${({ checked }) =>
    checked &&
    css`
      font-weight: bold;
    `}
`;

export const Description = styled.span`
  margin-top: ${({ theme }) => theme.spacing.xs};
  user-select: none;
  font-weight: 400;
  font-size: 0.9em;
  display: block;
  color: ${({ theme }) => theme.color.darkGrey};
`;

interface GroupProps {
  hasLabel: boolean;
}

export const Group = styled.div<GroupProps>`
  display: flex;

  ${({ hasLabel }) =>
    hasLabel &&
    media.md`
      ${Container} {
        margin-top: ${({ theme }) => theme.spacing.xs};
      }
    `}
`;
