import { FC } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Route } from '../../../types/ui/Route';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Integrations } from '../../../assets/illustrations/integrations.svg';
import { Form } from './Form';
import { Divider, BackLink } from './styled';
import { IllustratedPage } from '../../../components/illustratedPage/IllustratedPage';
import { config } from '../../../config';
import { FacebookButton } from '../../../components/facebookButton/FacebookButton';
import { GoogleButton } from '../../../components/googleButton/GoogleButton';
import { Space } from 'antd';
import { Link } from 'react-router-dom';

export const Register: FC = () => {
  const { t } = useTranslation('register');
  return (
    <IllustratedPage illustration={<Integrations />} title={t('title')}>
      <Form />
      <Divider>
        <span>{t('or')}</span>
      </Divider>
      <Space direction="vertical" size="middle">
        <FacebookButton
          href={`${config.apiBaseUrl}/v1/integration/facebook/sign_up`}
          type="register"
        />
        <GoogleButton
          href={`${config.apiBaseUrl}/v1/integration/google/sign_up`}
          type="register"
        />
      </Space>
      <Link to={Route.Login}>
        <BackLink type="link" icon={<ArrowLeftOutlined />}>
          {t('login')}
        </BackLink>
      </Link>
    </IllustratedPage>
  );
};
