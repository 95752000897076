import { config } from '../../config';
import { ElementId } from '../../types/ui/ElementId';

export const exportLink = (automationId: string, elementIds: ElementId[]) => {
  const params = elementIds.map((item) => `${item.klass}_ids[]=${item.id}`);

  return `${
    config.apiBaseUrl
  }/v1/automation/automations/${automationId}/export.json?${params.join('&')}`;
};
