import { FC } from 'react';
import { ReactComponent as InputIcon } from '../../../../assets/icons/input.svg';
import { ReactComponent as OutputIcon } from '../../../../assets/icons/output.svg';
import { ReactComponent as ProcessorIcon } from '../../../../assets/icons/processor.svg';
import { ReactComponent as GroupIcon } from '../../../../assets/icons/group-outline.svg';
import { NodeIcon } from '../../../graphEditor/elements/NodeIcon';

type Type = 'input' | 'output' | 'group' | 'node' | 'processor';
interface IconProps {
  type: Type;
  category?: string;
  height?: number;
  width?: number;
}

export const Icon: FC<IconProps> = ({
  type,
  category,
  height = 32,
  width = 32,
}) => {
  switch (type) {
    case 'processor':
      return <ProcessorIcon height={height} width={width} />;
    case 'input':
      return <InputIcon height={height} width={width} />;
    case 'output':
      return <OutputIcon height={height} width={width} />;
    case 'group':
      return <GroupIcon height={height} width={width} />;
    case 'node':
      return <NodeIcon category={category} settings={{ height, width }} />;
  }
};
