import {
  Graph,
  GraphDiff,
  Nodes,
  Sockets,
  Groups,
  Edges,
  Widgets,
  Variables,
} from '../../types/api/automation/Graph';

export const diffToGraph = (graph: Graph, diff: GraphDiff) => ({
  ...graph,
  nodes: diffToNodes(
    graph.nodes,
    diff.created?.nodes || {},
    diff.updated?.nodes || {},
    diff.deleted?.nodes || {},
  ),
  groups: diffToGroups(
    graph.groups,
    diff.created?.groups || {},
    diff.updated?.groups || {},
    diff.deleted?.groups || {},
  ),
  edges: diffToEdges(
    graph.edges,
    diff.created?.edges || {},
    diff.updated?.edges || {},
    diff.deleted?.edges || {},
  ),
  inputs: diffToInputs(
    graph.inputs,
    diff.created?.inputs || {},
    diff.updated?.inputs || {},
    diff.deleted?.inputs || {},
  ),
  outputs: diffToOutputs(
    graph.outputs,
    diff.created?.outputs || {},
    diff.updated?.outputs || {},
    diff.deleted?.outputs || {},
  ),
  widgets: diffToWidgets(
    graph.widgets,
    diff.created?.widgets || {},
    diff.updated?.widgets || {},
    diff.deleted?.widgets || {},
  ),
  variables: diffToVariables(
    graph.variables,
    diff.created?.variables || {},
    diff.updated?.variables || {},
    diff.deleted?.variables || {},
  ),
});

const diffToNodes = (
  nodes: Nodes,
  created: Nodes,
  updated: Nodes,
  deleted: Nodes,
) => {
  const ns = { ...nodes };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete ns[id];
  });
  return { ...ns, ...updated, ...created };
};

const diffToGroups = (
  groups: Groups,
  created: Groups,
  updated: Groups,
  deleted: Groups,
) => {
  const gs = { ...groups };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete gs[id];
  });
  return { ...gs, ...updated, ...created };
};

const diffToWidgets = (
  widgets: Widgets,
  created: Widgets,
  updated: Widgets,
  deleted: Widgets,
) => {
  const ws = { ...widgets };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete ws[id];
  });
  return { ...ws, ...updated, ...created };
};

const diffToVariables = (
  variables: Variables,
  created: Variables,
  updated: Variables,
  deleted: Variables,
) => {
  const vs = { ...variables };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete vs[id];
  });
  return { ...vs, ...updated, ...created };
};

const diffToEdges = (
  edges: Edges,
  created: Edges,
  updated: Edges,
  deleted: Edges,
) => {
  const es = { ...edges };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete es[id];
  });
  return { ...es, ...updated, ...created };
};

const diffToInputs = (
  inputs: Sockets,
  created: Sockets,
  updated: Sockets,
  deleted: Sockets,
) => {
  const is = { ...inputs };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete is[id];
  });
  return { ...is, ...updated, ...created };
};

const diffToOutputs = (
  outputs: Sockets,
  created: Sockets,
  updated: Sockets,
  deleted: Sockets,
) => {
  const os = { ...outputs };
  const deletedIds = Object.keys(deleted);
  deletedIds.forEach((id) => {
    delete os[id];
  });
  return { ...os, ...updated, ...created };
};
