import { useEffect } from 'react';
import api from '../../api/admin/organizations';
import { Organization } from '../../types/api/admin/Organization';
import { setNotification } from '../useUiNotifications';
import { StoreId } from '../../types/ui/StoreId';
import { createStore } from 'usestore-react';
import { useAuth } from '../useAuth';
import { OrganizationForm } from '../../types/api/entity/OrganizationForm';
import { Pagination } from '../../types/api/admin/Pagination';

export type State = {
  organizations?: Organization[];
  pagination?: Pagination;
  isLoading?: boolean;
  isLoadingOnDelete?: boolean;
};

const { getState, setState, useStore } = createStore<State>(
  StoreId.AdminOrganizations,
  {},
);

export const fetchOrganizations = async (params: URLSearchParams) => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getOrganizations(params);
    if (response.ok) {
      const { list: organizations, pagination } = await response.json();
      setState({ organizations, pagination });
      return true;
    }
  } catch {}

  setState(({ organizations, pagination }) => ({ organizations, pagination }));
  setNotification({
    type: 'error',
    entity: 'organizations',
    description: 'An error occured on fetching organizations.',
  });
  return false;
};

export const updateOrganization = async (
  id: string,
  form: OrganizationForm,
) => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.updateOrganization(id, form);
    if (response.ok) {
      const organizationResponse = await response.json();
      setState(({ organizations, pagination }) => ({
        pagination,
        organizations: organizations?.map((a) =>
          a.id === id ? organizationResponse : a,
        ),
      }));

      return true;
    }
  } catch {}

  setState(({ organizations, pagination }) => ({ organizations, pagination }));
  return false;
};

export const deleteOrganization = async (id: string) => {
  const { organizations, pagination } = getState();
  setState({ organizations, pagination, isLoadingOnDelete: true });
  try {
    const response = await api.deleteOrganization(id);
    if (response.ok) {
      setState({
        organizations: organizations?.filter((item) => item.id !== id),
        pagination,
      });
      setNotification({
        type: 'success',
        entity: 'organization',
        description: 'Successfully deleted organization.',
      });
      return true;
    }
  } catch {}
  setNotification({
    type: 'error',
    entity: 'organization',
    description: 'An error occured on deleting organization.',
  });
  setState({ organizations, pagination });
  return false;
};

export const useOrganizations = () => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore();
  const { organizations, pagination, isLoading, isLoadingOnDelete } = state;

  useEffect(() => {
    const { organizations, isLoading } = getState();
    if (isAuthenticated) {
      if (!organizations && !isLoading) {
        const params = new URLSearchParams(window.location.search);
        fetchOrganizations(params);
      }
    } else if (organizations) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    organizations,
    pagination,
    isLoading,
    isLoadingOnDelete,
  };
};
