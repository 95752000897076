import { Dispatch, SetStateAction, useCallback } from 'react';
import { useKeyPress } from '../../../../hooks/useKeyPress';

export const useTreeNavigation = (
  keys: string[],
  setId: Dispatch<SetStateAction<string | undefined>>,
) => {
  const onArrowDown = useCallback(
    () =>
      setId((selectedId) => {
        if (!selectedId) {
          return;
        }
        const index = keys.findIndex((id) => id === selectedId);
        return keys[index + 1] || keys[0];
      }),
    [setId, keys],
  );

  useKeyPress('ArrowDown', onArrowDown);

  const onArrowUp = useCallback(
    () =>
      setId((selectedId) => {
        if (!selectedId) {
          return;
        }
        const index = keys.findIndex((id) => id === selectedId);
        return keys[index - 1] || keys[keys.length - 1];
      }),
    [setId, keys],
  );

  useKeyPress('ArrowUp', onArrowUp);
};
