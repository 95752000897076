import { TreeDataNode } from 'antd';

export const mapDataNodeToKeys = ({
  key,
  children,
  isLeaf = true,
}: TreeDataNode): string[] => {
  if (children && children.length > 0) {
    return children.flatMap(mapDataNodeToKeys);
  }
  return isLeaf ? [`${key}`] : [];
};
