import { Sorting } from '../types/ui/Sorting';

interface SortMethod<T> {
  (a: T, b: T): -1 | 1 | 0;
}

export const sortByKey = <T>(
  key: keyof T,
  direction: Sorting,
): SortMethod<T> => {
  if (direction === 'ASC') return sortByKeyAsc(key);
  return sortByKeyDesc(key);
};

export const sortByKeyAsc =
  <T>(key: keyof T): SortMethod<T> =>
  (a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  };

export const sortByKeyDesc =
  <T>(key: keyof T): SortMethod<T> =>
  (a, b) => {
    if (a[key] > b[key]) {
      return -1;
    }
    if (a[key] < b[key]) {
      return 1;
    }
    return 0;
  };
