export enum StoreId {
  AddMenu = 'AddMenu',
  AdminAutomationIssues = 'AdminAutomationIssues',
  AdminAutomations = 'AdminAutomations',
  AdminDashboard = 'AdminDashboard',
  AdminInvites = 'AdminInvites',
  AdminJobs = 'AdminJobs',
  AdminLogs = 'AdminLogs',
  AdminMetaFilters = 'AdminMetaFilters',
  AdminNodes = 'AdminNodes',
  AdminOrganizations = 'AdminOrganizations',
  AdminOrganizationsLoading = 'AdminOrganizationsLoading',
  AdminQuery = 'AdminQuery',
  AdminReports = 'AdminReports',
  AdminRules = 'AdminRules',
  AdminRuns = 'AdminRuns',
  AdminUsers = 'AdminUsers',
  AdminWorkers = 'AdminWorkers',
  Auth = 'Auth',
  AuthAutoInitialize = 'AuthAutoInitialize',
  Automation = 'Automation',
  AutomationBackups = 'AutomationBackups',
  AutomationCredentials = 'AutomationCredentials',
  AutomationFilters = 'AutomationFilters',
  AutomationId = 'AutomationId',
  AutomationLogs = 'AutomationLogs',
  Automations = 'Automations',
  AutomationsAudits = 'AutomationsAudits',
  CanvasMargin = 'CanvasMargin',
  Confirmation = 'Confirmation',
  ContextualMenu = 'ContextualMenu',
  Credentials = 'Credentials',
  CustomMetrics = 'CustomMetrics',
  EdgeLoading = 'EdgeLoading',
  EditorActions = 'EditorActions',
  EditorDragElement = 'EditorDragElement',
  EditorDragElements = 'EditorDragElements',
  EditorElementStack = 'EditorElementStack',
  EditorExpansion = 'EditorExpansion',
  EditorGroupIds = 'EditorGroupIds',
  ExecutionTimes = 'ExecutionTimes',
  FacebookAdAccountIdOptions = 'FacebookAdAccountIdOptions',
  FacebookAdAccounts = 'FacebookAdAccounts',
  FacebookAdIdOptions = 'FacebookAdIdOptions',
  FacebookAdsetIdOptions = 'FacebookAdsetIdOptions',
  FacebookCampaignIdOptions = 'FacebookCampaignIdOptions',
  FacebookSpreadsheetIdOptions = 'FacebookSpreadsheetIdOptions',
  Folders = 'Folders',
  GoogleAdsAdAccountIdOptions = 'GoogleAdsAdAccountIdOptions',
  GoogleAdsAdAccounts = 'GoogleAdsAdAccounts',
  GoogleAdsAdGroupIdOptions = 'GoogleAdsAdGroupIdOptions',
  GoogleAdsAdIdOptions = 'GoogleAdsAdIdOptions',
  GoogleAdsCampaignIdOptions = 'GoogleAdsCampaignIdOptions',
  GoogleAnalyticsAccounts = 'GoogleAnalyticsAccounts',
  Graph = 'Graph',
  GraphLoading = 'GraphLoading',
  GroupLoading = 'GroupLoading',
  Invites = 'Invites',
  LibraryGroups = 'LibraryGroups',
  NewAutomationTags = 'NewAutomationTags',
  NodeError = 'NodeError',
  NodeLoading = 'NodeLoading',
  NodeUpdateLoading = 'NodeUpdateLoading',
  Notifications = 'Notifications',
  PinterestAdAccounts = 'PinterestAdAccounts',
  Processors = 'Processors',
  RecipeFilters = 'RecipeFilters',
  Recipes = 'Recipes',
  ReportDataSources = 'ReportDataSources',
  ReportDimensions = 'ReportDimensions',
  ReportEditId = 'ReportEditId',
  ReportMetrics = 'ReportMetrics',
  Reports = 'Reports',
  ReportScreenshots = 'ReportScreenshots',
  ReportValues = 'ReportValues',
  ReportView = 'ReportView',
  ReportViews = 'ReportViews',
  RuleEngineActivities = 'RuleEngineActivities',
  RuleEngineOptions = 'RuleEngineOptions',
  Rules = 'Rules',
  Schemas = 'Schemas',
  Settings = 'Settings',
  SidebarCollapsed = 'SidebarCollapsed',
  SlackConversationIdOptions = 'SlackConversationIdOptions',
  SlackConversations = 'SlackConversations',
  SnapchatAdAccountIdOptions = 'SnapchatAdAccountIdOptions',
  SnapchatAdSquadIdOptions = 'SnapchatAdSquadIdOptions',
  SnapchatAdIdOptions = 'SnapchatAdIdOptions',
  SnapchatCampaignIdOptions = 'SnapchatCampaignIdOptions',
  SocketLoading = 'SocketLoading',
  SquaredanceOrganizationIdOptions = 'SquaredanceOrganizationIdOptions',
  StorageDataStores = 'StorageDataStores',
  Tags = 'Tags',
  TikilaFunctions = 'TikilaFunctions',
  TikTokAdAccountIdOptions = 'TikTokAdAccountIdOptions',
  TikTokAdAccounts = 'TikTokAdAccounts',
  TikTokAdGroupIdOptions = 'TikTokAdGroupIdOptions',
  TikTokAdIdOptions = 'TikTokAdIdOptions',
  TikTokCampaignIdOptions = 'TikTokCampaignIdOptions',
  UiNotifications = 'UiNotifications',
  Users = 'Users',
  WebSocketChannels = 'WebSocketChannels',
  WidgetEditId = 'WidgetEditId',
  WidgetLoading = 'WidgetLoading',
}
