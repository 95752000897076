import { Button } from './styled';
import { FeedbackBubble } from '../../components/v2/feedbackBubble/FeedbackBubble';
import { useUiNotifications } from '../../stores/useUiNotifications';

// Notification system that renders Feedback on variouse cases
export const Notifications = () => {
  const notification = useUiNotifications();
  if (!notification) {
    return null;
  }
  const { type, description, action } = notification;
  return (
    <FeedbackBubble type={type} onClick={notification.onClick}>
      <div>{description}</div>
      {action && (
        <Button type="button" onClick={action.onClick}>
          {action.title}
        </Button>
      )}
    </FeedbackBubble>
  );
};
