import { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Route } from '../../../types/ui/Route';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Integrations } from '../../../assets/illustrations/integrations.svg';
import { Form } from './Form';
import { Divider, BackLink, StyledAlert } from './styled';
import { IllustratedPage } from '../../../components/illustratedPage/IllustratedPage';
import { config } from '../../../config';
import { trackEvent } from '../../../util/trackEvent';
import { useInvite } from '../../../hooks/useInvite';
import { FacebookButton } from '../../../components/facebookButton/FacebookButton';
import { Space } from 'antd';
import { GoogleButton } from '../../../components/googleButton/GoogleButton';

export const AcceptInvite: FC = () => {
  const { t } = useTranslation('acceptInvite');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const { data, error } = useInvite(id);
  const organizationName = data?.organization?.name;

  return (
    <IllustratedPage
      illustration={<Integrations />}
      title={
        organizationName
          ? t('title', { organizationName })
          : t('titleWithoutOrg')
      }
    >
      {error && (
        <StyledAlert type="error" message={t(`error:${error}`)} showIcon />
      )}
      {!error && (
        <>
          <Form {...data} />
          <Divider>
            <span>{t('or')}</span>
          </Divider>
          <Space direction="vertical" size="middle">
            <FacebookButton
              type="register"
              onClick={() => trackEvent('Login With Facebook')}
              href={`${config.apiBaseUrl}/v1/integration/facebook/sign_up_from_invite?invite_id=${id}`}
              disabled={!data?.organization}
            />
            <GoogleButton
              type="register"
              href={`${config.apiBaseUrl}/v1/integration/google/sign_up_from_invite?invite_id=${id}`}
              disabled={!data?.organization}
            />
          </Space>
        </>
      )}
      <Link to={Route.Login}>
        <BackLink type="link" icon={<ArrowLeftOutlined />}>
          {t('login')}
        </BackLink>
      </Link>
    </IllustratedPage>
  );
};
