export const isPathAllowed = (paths: string[], path: string): boolean => {
  // Check for exact matches first
  if (paths.includes(path)) return true;

  // Check for dynamic segment matches
  const dynamicMatch = paths.some((routePath) => {
    if (!routePath.includes(':')) {
      // If the route does not have dynamic segments, check if the current path starts with the route path
      return path.startsWith(routePath);
    }
    // For routes with dynamic segments, use regex for matching
    const regex = new RegExp(
      `^${routePath.replace(/:[^\s/]+/g, '([^\\s/]+)')}$`,
    );
    return regex.test(path);
  });

  if (dynamicMatch) return true;

  // If no exact or dynamic match, check for "starts with" logic for base paths
  return paths.some((basePath) => path.startsWith(basePath));
};
