import { SiderProps } from 'antd/lib/layout/Sider';
import { Layout, Menu, MenuProps } from 'antd';
import styled, { css } from 'styled-components';

export const StyledSider = styled(Layout.Sider)<SiderProps>`
  background: ${({ theme }) => theme.color.secondary6};

  > :first-child {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    position: sticky;
    overflow-y: auto;
    top: 0;
  }

  .ant-layout-sider-children {
    height: 100%;
  }
`;

export const LogoContainer = styled.div<{ inverted: boolean }>`
  display: grid;
  place-items: center;
  height: 100%;

  > svg {
    width: 28px;
  }

  transition: color 0.3s, background 0.3s;

  color: ${({ theme }) => theme.color.primary3};
  :hover {
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color.white}06;
  }

  ${({ inverted }) =>
    inverted &&
    css`
      color: ${({ theme }) => theme.color.white};
      :hover {
        color: ${({ theme }) => theme.color.primary3};
      }
    `}
`;

export const HeaderContainer = styled.div<{ highlighted: boolean }>`
  display: flex;
  flex-direction: column;
  height: 112px;

  > a {
    flex: 1;
  }
  > :last-child {
    padding: ${({ theme }) => theme.spacing.xs};
  }

  ${({ highlighted }) =>
    highlighted &&
    css`
      background: ${({ theme }) => theme.color.danger};
    `}
`;

export const CollapseButton = styled.div`
  display: flex;
  flex-shrink: 0;
  height: 40px;
  align-items: center;
  cursor: pointer;
  background: ${({ theme }) => theme.color.secondary7};
  color: ${({ theme }) => theme.color.secondary4};

  padding: 0 ${({ theme }) => theme.spacing.m};

  transition: color 0.3s, background 0.3s;
  :hover {
    color: ${({ theme }) => theme.color.lightest};
    background: ${({ theme }) => theme.color.secondary7}DD;
  }
`;

export const StyledMenu = styled(Menu)<MenuProps>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: transparent;
  color: ${({ theme }) => theme.color.lightest};
  border-color: ${({ theme }) => theme.color.secondary6};

  height: 100%;

  &.ant-menu-inline-collapsed {
    width: 48px !important;
  }

  > .ant-menu-item {
    flex-shrink: 0;
    :not(.ant-menu-item-selected) {
      color: ${({ theme }) => theme.color.lightest};
      :hover {
        background: ${({ theme }) => theme.color.primary1} !important;
        color: ${({ theme }) => theme.color.brandPrimary} !important;
      }
      :not(:hover) a {
        color: ${({ theme }) => theme.color.lightest} !important;
      }
      span {
        transition: none;
      }
    }
  }

  .ant-menu-submenu {
    margin-right: -1px;
    overflow-x: clip;

    .ant-menu-item {
      color: ${({ theme }) => theme.color.darkest} !important;
      padding-right: 0;
    }

    .ant-menu-item-selected,
    .ant-menu-item:hover {
      background: ${({ theme }) => theme.color.primary3} !important;
      color: ${({ theme }) => theme.color.brandPrimary} !important;
      span {
        transition: none;
      }
    }
    .ant-menu-submenu-title {
      color: ${({ theme }) => theme.color.lightest};
    }
    > .ant-menu-submenu-title {
      :hover {
        background: ${({ theme }) => theme.color.primary1};
      }
    }

    &.ant-menu-submenu-open {
      background: linear-gradient(
        180deg,
        ${({ theme }) => theme.color.primary2} calc(100% - 4px),
        transparent calc(100% - 4px)
      );
    }
    > .ant-menu {
      background: ${({ theme }) => theme.color.primary2};
    }
    &.ant-menu-submenu-open,
    :hover {
      > .ant-menu-submenu-title {
        background: ${({ theme }) => theme.color.primary2};
        color: ${({ theme }) => theme.color.primary6};
      }
    }

    :not(:hover) {
      color: ${({ theme }) => theme.color.lightest};
    }
  }
`;

export const OrgSwitcherMenu = styled(Menu)<{ $inverse: boolean }>`
  border: none;
  color: ${({ theme }) => theme.color.darkest};
  height: 40px;

  li {
    display: flex;
    place-items: center;
    align-items: center;
    height: 100%;
    margin-inline-start: 8px;
  }

  &.ant-menu-inline-collapsed {
    width: 48px !important;
    li {
      margin-inline-start: 0;
    }
  }

  ${({ $inverse }) =>
    $inverse &&
    css`
      background: ${({ theme }) => theme.color.danger};
      color: ${({ theme }) => theme.color.lightest};
    `}
`;
