import adminApi from '../../api/admin/token';
import { ActAsOrganizationFrom } from '../../types/api/admin/ActAsOrganizationFrom';
import { setNotification } from '../useUiNotifications';
// Admin actions on token
export const updateToken = async (id: string, form: ActAsOrganizationFrom) => {
  const response = await adminApi.actAsOrganization(id, form);
  if (response.ok) {
    window.location.reload();
    window.location.href = '/';
  }

  setNotification({
    type: 'error',
    entity: 'user',
    description: 'An error occured on switching organization for user',
  });
};
