import { useEffect } from 'react';
import api from '../../api/report/screenshots';
import { Screenshot } from '../../types/api/report/Screenshot';
import { useAuth } from '../useAuth';
import { StoreId } from '../../types/ui/StoreId';
import { createStore } from 'usestore-react';
import { getDiff } from 'recursive-diff';
import { useParams } from 'react-router-dom';

export interface State {
  report_id?: string;
  screenshots?: Screenshot[];
  isLoading?: boolean;
}

const [getState, setStoreState, useStore] = createStore<State>(
  StoreId.ReportScreenshots,
  {},
);

const setState: typeof setStoreState = (state) => {
  const currentState = getState();
  const newState = typeof state === 'function' ? state(currentState) : state;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(
      `[${StoreId.ReportScreenshots}] state change`,
      getDiff(currentState, newState),
    );
  }

  return setStoreState(newState);
};

export const addScreenshot = (data: Screenshot) => {
  setState((state) => {
    if (data.report_report_id === state.report_id) {
      return { ...state, screenshots: [...(state.screenshots || []), data] };
    }

    return state;
  });
};

export const replaceScreenshot = (data: Screenshot) => {
  setState((state) => {
    if (data.report_report_id === state.report_id) {
      return {
        ...state,
        screenshots: (state.screenshots || []).map((item) =>
          item.id === data.id ? data : item,
        ),
      };
    }

    return state;
  });
};

export const updateScreenshot = async (
  report_id: string,
  id: string,
  form: { url: string },
) => {
  try {
    const response = await api.updateReportScreenshot(report_id, id, form);
    if (response.ok) {
      const screenshot: Screenshot = await response.json();
      replaceScreenshot(screenshot);
      return true;
    }
  } catch {}
  return false;
};

export const createScreenshot = async (
  report_id: string,
  form: { identifier: string; url: string },
) => {
  try {
    const response = await api.createReportScreenshot(report_id, form);
    if (response.ok) {
      const screenshot: Screenshot = await response.json();
      addScreenshot(screenshot);
      return true;
    }
  } catch {}
  return false;
};

export const fetchScreenshots = async (id: string) => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getReportScreenshots(id);
    if (response.ok) {
      const screenshots = await response.json();
      setState({
        report_id: id,
        screenshots: screenshots || [],
      });
      return true;
    }
  } catch {}
  setState({});
  return false;
};

export const useScreenshots = (): State => {
  const { isAuthenticated } = useAuth();
  // based on the current url
  const { id } = useParams();
  const [state] = useStore();
  const { screenshots, isLoading, report_id } = state;

  useEffect(() => {
    const { screenshots, isLoading, report_id } = getState();

    if (isAuthenticated) {
      if (!isLoading && id && id !== report_id) {
        fetchScreenshots(id);
      }
    } else if (screenshots) {
      setState({});
    }
  }, [isAuthenticated, id]);

  return { screenshots, isLoading, report_id };
};
