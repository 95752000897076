import styled, { css } from 'styled-components';
import { touchSupport } from '../../../util/touchSupport';
import { hexToRGBA } from '../../../util/hexToRgba';

export interface ButtonProps {
  fullWidth?: boolean;
  disabled?: boolean;
  empty?: boolean;
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  warning?: boolean;
  danger?: boolean;
  small?: boolean;
  medium?: boolean;
  confirmed?: boolean;
  outline?: boolean;
  outlinePrimary?: boolean;
  outlineSecondary?: boolean;
  outlineSuccess?: boolean;
  outlineDanger?: boolean;
  outlineWarning?: boolean;
  hasIcon?: boolean;
  blink?: boolean;
}

export const Button = styled.button<ButtonProps>`
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  height: 44px;
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.color.darkerGrey};
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.backgroundGrey};
  padding: 0 20px;
  font-weight: 600;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  transition: background-color 0.2s, opacity 0.2s, color 0.2s;
  justify-content: center;

  &:focus {
    outline: none;
  }

  ${({ hasIcon }) =>
    hasIcon
      ? css`
          padding-left: 12px;
          svg {
            height: 22px;
            width: 22px;
            transform: translateY(-3px);
            transition: fill 0.2s;
            fill: ${({ theme }) => theme.color.darkerGrey};
          }
        `
      : css`
          svg {
            height: 30px;
            width: 25px;
            flex-grow: 0;
          }
        `}

  ${({ small }) =>
    small &&
    css`
      height: 22px;
      margin: 2px 0;
      padding: 0 8px;
      div {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
      }
      svg {
        height: 18px;
        width: 16px;
        color: inherit;
        transform: translateX(-2px) translateY(-1px);
      }
    `}

    ${({ medium }) =>
    medium &&
    css`
      height: 32px;
      margin: 2px 0;
      padding: 0 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      font-size: 16px;
      div {
        font-size: 14px;
        letter-spacing: 0.2px;
      }
      svg {
        height: 18px;
        width: 18px;
        color: inherit;
        transform: translateX(-5px) translateY(-1px);
      }
    `}

  ${!touchSupport &&
  css`
    &:hover,
    &:focus {
      opacity: 0.8;
      background-color: ${({ theme }) =>
        hexToRGBA(theme.color.brandSecondary, 0.1)};
      color: ${({ theme }) => theme.color.brandSecondary};

      svg {
        fill: ${({ theme }) => theme.color.brandSecondary};
      }
    }
  `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100% !important;
    `}

  ${({ empty }) =>
    empty &&
    css`
      background-color: transparent;
      border: transparent;
      color: ${({ theme }) => theme.color.darkGrey};
    `}

  ${({ success }) =>
    success &&
    css`
      background-color: ${({ theme }) => theme.color.success};
      color: ${({ theme }) => theme.color.white};
    `}

  ${({ warning }) =>
    warning &&
    css`
      background-color: ${({ theme }) => theme.color.warning};
      color: ${({ theme }) => theme.color.white};
    `}

  ${({ danger }) =>
    danger &&
    css`
      background-color: ${({ theme }) => theme.color.danger};
      color: ${({ theme }) => theme.color.white};
    `}

  ${({ confirmed }) =>
    confirmed &&
    css`
      background-color: ${({ theme }) => hexToRGBA(theme.color.success, 0.1)};
      color: ${({ theme }) => theme.color.success};
      pointer-events: none;
      color: ${({ theme }) => theme.color.white};
    `}

  ${({ primary }) =>
    primary &&
    css`
      background-color: ${({ theme }) => theme.color.brandPrimary};
      color: ${({ theme }) => theme.color.white};
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      background-color: ${({ theme }) => theme.color.brandSecondary};
      color: ${({ theme }) => theme.color.white};
    `}

  ${({ outline }) =>
    outline &&
    css`
      background-color: transparent;
      color: inherit;
      border: 1px solid ${({ theme }) => theme.color.grey};
    `}

  ${({ outlinePrimary }) =>
    outlinePrimary &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.brandPrimary};
      color: ${({ theme }) => theme.color.brandPrimary};

      &:hover,
      &:focus {
        opacity: 1;
      }
    `}

  ${({ outlineSecondary }) =>
    outlineSecondary &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.brandSecondary};
      color: ${({ theme }) => theme.color.brandSecondary};

      &:hover,
      &:focus {
        opacity: 1;
      }
    `}

  ${({ outlineSuccess }) =>
    outlineSuccess &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.success};
      color: ${({ theme }) => theme.color.success};
    `}

  ${({ outlineWarning }) =>
    outlineWarning &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.warning};
      color: ${({ theme }) => theme.color.warning};
    `}

  ${({ outlineDanger }) =>
    outlineDanger &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.danger};
      color: ${({ theme }) => theme.color.danger};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
      &:focus,
      &:hover {
        pointer-events: none;
      }
      background: transparent;
      border: 1px solid ${({ theme }) => hexToRGBA(theme.color.grey, 0.4)};
      color: ${({ theme }) => theme.color.grey};

      svg {
        opacity: 0.5 !important;
      }
    `}

    ${({ blink }) =>
    blink &&
    css`
      svg {
        @keyframes blinker {
          50% {
            color: rgba(255, 255, 255, 0.5);
          }
        }
        animation: blinker 1s linear infinite;
      }
    `}
`;

export const Text = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 23px;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: center;

  > svg {
    max-height: 23px;
    max-width: 23px;
    margin: 0 ${({ theme }) => theme.spacing.s} -${({ theme }) =>
        theme.spacing.xs} 0;
    flex-shrink: 0;
  }
`;
