import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Badge } from 'antd';
import {
  BellOutlined,
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { logout, useAuth } from '../../../../stores/useAuth';
import { Header, UserContainer, NotificationContainer } from './styled';
import { Route } from '../../../../types/ui/Route';
import { useNotifications } from '../../../../stores/useNotifications';

export const DashboardHeader: FC = () => {
  const { t } = useTranslation('dashboardHeader');
  const { token } = useAuth();
  const { notifications } = useNotifications();
  const { pathname } = useLocation();

  const isGuest = token?.user.role === 'guest';

  return (
    <Header>
      {!isGuest && (
        <NotificationContainer
          key={Route.Notifications}
          to={Route.Notifications}
          selected={pathname === Route.Notifications}
        >
          <Badge count={notifications?.filter((item) => !item.read).length}>
            <BellOutlined />
          </Badge>
        </NotificationContainer>
      )}
      <Dropdown
        menu={{
          items: [
            {
              key: 'logout',
              label: t('logout'),
              icon: <LogoutOutlined />,
              onClick: logout,
            },
          ],
        }}
        placement="bottomRight"
        overlayStyle={{ minWidth: 150 }}
      >
        <UserContainer>
          <UserOutlined />
          <div>{token?.user?.name || token?.user?.email}</div>
          <DownOutlined />
        </UserContainer>
      </Dropdown>
    </Header>
  );
};
