import styled, { css } from 'styled-components';
import { touchSupport } from '../../util/touchSupport';
import { hexToRGBA } from '../../util/hexToRgba';

interface Props {
  /**
   * @default false
   */
  selected?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  nohover?: boolean;
  noshadow?: boolean;
}

export const Container = styled.div<Props>`
  transition: padding 0.35s;
  padding: 0;
  box-sizing: border-box;
`;

export const InnerContainer = styled.div<Props>`
  width: 100%;
  justify-content: flex-start;
  height: 100%;
  align-self: stretch;
  transition: box-shadow 0.35s, transform 0.35s, border 0.35s;
  border: 1px solid ${({ theme }) => theme.color.lighterGrey};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.lightest};
  box-shadow: 0 2px 5px 0 ${({ theme }) => hexToRGBA(theme.color.black, 0.07)};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xs};
  display: flex;
  flex-direction: column;
  ${({ selected, disabled, nohover }) =>
    selected
      ? css`
          border: 1px solid ${({ theme }) => theme.color.brandSecondary40};
          /* Hardcoded color follows just for one-time use, we might later port it in the theme if we find a case */
          background: #f7feff;
        `
      : !touchSupport &&
        !disabled &&
        !nohover &&
        css`
          &:hover {
            ${hoveredStyle};
          }
        `};
  ${({ highlighted, selected, disabled }) =>
    highlighted && !selected && !disabled && !touchSupport && highlightedStyle}
  ${({ highlighted }) =>
    highlighted &&
    css`
      transform: translateY(-3px);
    `}
  ${({ noshadow }) =>
    noshadow &&
    css`
      box-shadow: none;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(100);
      background: ${({ theme }) => hexToRGBA(theme.color.lightest, 0.8)};
      border: 1px solid ${({ theme }) => hexToRGBA(theme.color.lightGrey, 0.3)};
      cursor: default;
      box-shadow: none;
      opacity: 0.8;
    `}
`;

const hoveredStyle = css`
  box-shadow: 0 4px 10px -1px ${({ theme }) => hexToRGBA(theme.color.lightGrey, 0.6)};
  border: 1px solid ${({ theme }) => hexToRGBA(theme.color.brandSecondary, 0.5)};
  cursor: pointer;
`;

const highlightedStyle = css`
  box-shadow: 0 4px 10px -1px ${({ theme }) => hexToRGBA(theme.color.lightGrey, 0.6)};
  border: 1px solid ${({ theme }) => hexToRGBA(theme.color.brandSecondary, 0.5)};
  cursor: pointer;
  &:hover {
    background: #fafeff;
    box-shadow: 0 3px 10px -1px ${({ theme }) => hexToRGBA(theme.color.lightGrey, 0.5)};
  }
`;
