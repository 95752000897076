import { FC, PropsWithChildren } from 'react';
import {
  Container,
  Content,
  IconContainer,
  TextContainer,
  CloseContainer,
} from './styled';
import { ReactComponent as Checkmark } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as Error } from '../../../assets/icons/cross-circle.svg';
import { ReactComponent as Info } from '../../../assets/icons/info.svg';
import { ReactComponent as Warning } from '../../../assets/icons/warning.svg';
import { ReactComponent as Close } from '../../../assets/ui/close.svg';

type Props = PropsWithChildren & {
  type: string;
  onClick?: (e: any) => void;
};

export const icons = (icon: string) => {
  switch (icon) {
    case 'success':
      return <Checkmark />;
    case 'error':
      return <Error />;
    case 'warning':
      return <Warning />;
    default:
      return <Info />;
  }
};

export const FeedbackBubble: FC<Props> = ({ type, onClick, children }) => (
  <Container>
    <Content>
      <IconContainer type={type}>{icons(type)}</IconContainer>
      <TextContainer>{children}</TextContainer>
      <CloseContainer onClick={onClick}>
        <Close />
      </CloseContainer>
    </Content>
  </Container>
);
