import { config } from '../../config';
import { CredentialForm } from '../../types/api/integration/CredentialForm';
import { headers } from '../auth/headers';

export const getCredentials = () =>
  fetch(`${config.apiBaseUrl}/v1/integration/credentials`, {
    method: 'GET',
    ...headers(),
  });

export const createCredential = (form: CredentialForm) =>
  fetch(`${config.apiBaseUrl}/v1/integration/credentials`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

export const updateCredential = (id: string, form: CredentialForm) =>
  fetch(`${config.apiBaseUrl}/v1/integration/credentials/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

export const deleteCredential = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/integration/credentials/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

export default {
  getCredentials,
  createCredential,
  updateCredential,
  deleteCredential,
};
