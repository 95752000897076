import { FC } from 'react';
import { Container, TitleContainer, CloseContainer } from './styled';
import { ReactComponent as CloseIcon } from '../../../../assets/ui/close.svg';

export interface ModalProps {
  onClose?: () => void;
  title?: string;
}

export const Header: FC<ModalProps> = ({ title, onClose }) => (
  <Container>
    <TitleContainer>{title}</TitleContainer>
    <CloseContainer>
      <CloseIcon onClick={() => onClose && onClose()} />
    </CloseContainer>
  </Container>
);
