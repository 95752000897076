import { useState } from 'react';
import { PasswordResetForm } from '../types/api/auth/PasswordResetForm';
import { updateUser } from '../api/entity/users';
import { useAuth } from '../stores/useAuth';

export const usePasswordReset = () => {
  const { token, isAuthenticated } = useAuth();

  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  const onSubmit = async (newPassword: PasswordResetForm['password']) => {
    if (!isAuthenticated || !token) {
      setError('auth/missing-token');
      return;
    }
    setLoading(true);
    setSuccess(false);
    setError(undefined);
    try {
      const response = await updateUser(token.user.id, {
        password: newPassword,
      });
      setLoading(false);
      if (response.ok) {
        setSuccess(true);
      } else {
        setError(response.statusText || 'generic');
      }
    } catch {
      setLoading(false);
      setError('generic');
    }
  };

  return { success, error, isLoading, onSubmit };
};
