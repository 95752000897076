import { useEffect } from 'react';
import api from '../api/automation/tags';
import { Tag } from '../types/api/automation/Tag';
import { setNotification } from './useUiNotifications';
import { StoreId } from '../types/ui/StoreId';
import { useStore, createStore } from 'usestore-react';
import { useAuth } from '../stores/useAuth';

export type State = {
  tags?: Tag[];
  isLoading?: boolean;
};

const { setState, getState } = createStore<State>(StoreId.Tags, {});

export const fetchTags = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getTags();
    if (response.ok) {
      setState({ tags: await response.json() });
      return true;
    }
  } catch {}

  setState(({ tags }) => ({ tags }));
  setNotification({
    entity: 'tag',
    type: 'error',
    description: 'An error occured on fetching tags.',
  });
  return false;
};

export const updateTags = (automationTags: Tag[]) =>
  setState(({ tags }) => {
    const uniqueTags = [
      ...new Map(
        [...(tags || []), ...automationTags].map((item) => [item.name, item]),
      ).values(),
    ];
    return {
      tags: uniqueTags,
    };
  });

export const useTags = () => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore<State>(StoreId.Tags);
  const { isLoading, tags } = state;

  useEffect(() => {
    const { tags, isLoading } = getState();
    if (isAuthenticated) {
      if (!tags && !isLoading) {
        fetchTags();
      }
    } else if (tags) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    isLoading,
    tags,
  };
};
