import styled, { css } from 'styled-components';

export const StyledButton = styled.a<{ fullWidth?: boolean }>`
  background-color: #4285f4;
  height: 40px;
  border-radius: 2px;
  display: flex;

  svg {
    flex-shrink: 0;
    margin: -3px;
    margin-right: 5px;
    pointer-events: none;
  }

  color: white;
  :hover {
    color: white;
  }
  :active {
    background: #3268d6;
  }

  > span {
    flex-grow: 1;
    font-size: 16px;
    line-height: 40px;
    padding-right: 12px;
    font-family: Roboto, sans-serif;
    overflow: hidden;
    text-align: center;
    text-overflow: clip;
    white-space: nowrap;
  }

  &[disabled] {
    pointer-events: none;
    background: #ebebeb;
    color: #8d8d8d;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;
