import { Groups } from '../../types/api/automation/Graph';

export const replaceGroupIdFromGroups = (
  groups: Groups,
  groupId: string,
  parentId?: string,
): Groups => {
  const newGroups: Groups = {};
  Object.values(groups).forEach((group) => {
    if (group.group_id === groupId) {
      newGroups[group.id] = { ...group, group_id: parentId };
    } else {
      newGroups[group.id] = group;
    }
  });
  return newGroups;
};
