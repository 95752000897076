import { FC, useCallback } from 'react';
import { useAddMenu } from '../../../../stores/useAddMenu';
import { reset as resetEditorActions } from '../../../../stores/useEditorActions';
import { Library } from '../../../automation/processors/Library';

export const NewModal: FC = () => {
  const [addMenu, setAddMenu] = useAddMenu();

  const onClose = useCallback(() => {
    setAddMenu(undefined);
    resetEditorActions();
  }, [setAddMenu]);

  if (addMenu?.type !== 'node') {
    return null;
  }
  return <Library open onClose={onClose} coordinates={addMenu.coordinates} />;
};
