import { Coordinates } from '../types/api/automation/Graph';
import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';
import { batchUpdateAutomationGraph } from './useGraph';
import { ElementIdWithCoordinates } from '../types/ui/ElementId';

export type State = ElementIdWithCoordinates[];

const { getState, setState, useStore, reset } = createStore<State | undefined>(
  StoreId.EditorDragElements,
  undefined,
);

export { reset };

export const startDragElements = (
  event: MouseEvent,
  svgStage: SVGSVGElement,
  automationId: string,
  elements?: ElementIdWithCoordinates[],
) => {
  if (!elements) return;
  const dragElements = [...elements];
  setState(dragElements);

  event.preventDefault();
  let point = svgStage.createSVGPoint();
  point.x = event.clientX;
  point.y = event.clientY;
  const p = svgStage.getScreenCTM()?.inverse();
  point = point.matrixTransform(p);

  const dragOffsets = {} as Record<string, Coordinates>;

  dragElements.forEach((dragElement) => {
    dragOffsets[dragElement.id] = {
      x: point.x - dragElement.coordinates.x,
      y: point.y - dragElement.coordinates.y,
    };
  });

  const mousemove = (event: MouseEvent) => {
    point.x = event.clientX;
    point.y = event.clientY;

    const cursor = point.matrixTransform(p);
    setState(
      dragElements.map((item) => ({
        ...item,
        coordinates: {
          x: cursor.x - dragOffsets[item.id].x,
          y: cursor.y - dragOffsets[item.id].y,
        },
      })),
    );
  };

  const mouseup = async () => {
    document.removeEventListener('mousemove', mousemove);
    document.removeEventListener('mouseup', mouseup);
    const dragElements = getState();
    if (!dragElements) {
      return;
    }
    setState(undefined);

    const movedElements = dragElements.filter(({ coordinates }, index) => {
      const { x, y } = elements[index].coordinates;
      return coordinates.x !== x || coordinates.y !== y;
    });
    if (movedElements.length === 0) return;
    await batchUpdateAutomationGraph(automationId, movedElements);
  };

  document.addEventListener('mousemove', mousemove);
  document.addEventListener('mouseup', mouseup);
};

export const useDragElements = () => {
  const [state] = useStore();
  return state;
};
