import { Rect } from '../types/ui/Rect';
import { Nodes, Groups } from '../types/api/automation/Graph';
import { ElementId } from '../types/ui/ElementId';

export const findElementsInRect = (rect: Rect, nodes: Nodes, groups?: Groups) =>
  [
    ...Object.values(nodes)
      .filter(({ processor_id }) => !['start', 'stop'].includes(processor_id))
      .filter(({ x, y }) => rect.isPointInside({ x, y }))
      .map(({ id }) => ({ id, klass: 'node' })),
    ...Object.values(groups || [])
      .filter(({ x, y }) => rect.isPointInside({ x, y }))
      .map(({ id }) => ({ id, klass: 'group' })),
  ] as ElementId[];
