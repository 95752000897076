import { ElementIdWithCoordinates } from '../types/ui/ElementId';
import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';

export type State = ElementIdWithCoordinates[];

const { setState, useStore, reset } = createStore<State | undefined>(
  StoreId.EditorElementStack,
  undefined,
);

export { reset };

export const setStack = setState;

export const useElementStack = () => {
  const [state] = useStore();
  return state;
};
