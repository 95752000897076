import { config } from '../../config';
import { LoginForm } from '../../types/api/auth/LoginForm';
import { RegisterForm } from '../../types/api/auth/RegisterForm';
import { PasswordLessForm } from '../../types/api/auth/PasswordLessForm';
import { ConfirmForm } from '../../types/api/auth/ConfirmForm';
import fetch from 'isomorphic-unfetch';
import { headers } from './headers';

const tokenUrl = (id: string, params?: URLSearchParams) => {
  const url = `${config.apiBaseUrl}/v1/auth/tokens/${id}`;
  if (!params) return url;
  return `${url}?${params.toString()}`;
};

export const getToken = async (tokenId: string, params?: URLSearchParams) =>
  fetch(tokenUrl(tokenId, params), {
    method: 'GET',
    ...headers(tokenId),
  });

export const deleteToken = async (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/auth/tokens/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

export const postPasswordLess = async (form: PasswordLessForm) =>
  fetch(`${config.apiBaseUrl}/v1/auth/tokens/magic`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

export const postToken = async (form: LoginForm) =>
  fetch(`${config.apiBaseUrl}/v1/auth/tokens`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

export const postUser = async (form: RegisterForm) =>
  fetch(`${config.apiBaseUrl}/v1/entity/users`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

export const emailConfirm = async (form: ConfirmForm) =>
  fetch(`${config.apiBaseUrl}/v1/entity/users/confirm`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });
