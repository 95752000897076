import styled, { css } from 'styled-components';

export const PageTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 1.6em;
  padding: ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.m} 0
    ${({ theme }) => theme.spacing.m};
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

interface LabelProps {
  disabled?: boolean;
  ellipsis?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-family: inherit;
  font-weight: 500;
  letter-spacing: 0.16px;
  margin-top: 5px;
  margin-bottom: 2px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.color.lightGrey};
    `}

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;
