import { config } from '../../config';
import { AutomationCreateData } from '../../types/api/automation/AutomationCreateForm';
import { AutomationUpdateForm } from '../../types/api/automation/AutomationUpdateForm';
import { AutomationBatchForm } from '../../types/api/automation/AutomationBatchForm';
import { RecipeToGraphForm } from '../../types/ui/RecipeToGraphForm';
import { exportLink } from '../../util/automations/exportLink';
import { ElementId } from '../../types/ui/ElementId';
import { StartRunForm } from '../../types/api/automation/Run';
import { headers } from '../auth/headers';
import { CredentialForm } from '../../types/api/automation/Credential';

const getAutomations = () =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations`, {
    method: 'GET',
    ...headers(),
  });

const getAutomation = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}`, {
    method: 'GET',
    ...headers(),
  });

const getAutomationRuns = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/runs`, {
    method: 'GET',
    ...headers(),
  });

const getAutomationAudits = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/audits`, {
    method: 'GET',
    ...headers(),
  });

const getAutomationBackups = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/backups`, {
    method: 'GET',
    ...headers(),
  });

const createAutomationBackup = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/backups`, {
    method: 'POST',
    ...headers(),
  });

const getAutomationBackup = (id: string, backup_id: string) =>
  fetch(
    `${config.apiBaseUrl}/v1/automation/automations/${id}/backups/${backup_id}`,
    {
      method: 'GET',
      ...headers(),
    },
  );

const getAutomationCredentials = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/credentials`, {
    method: 'GET',
    ...headers(),
  });

const createAutomationCredential = (id: string, form: CredentialForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/credentials`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const getAutomationCredential = (id: string, credential_id: string) =>
  fetch(
    `${config.apiBaseUrl}/v1/automation/automations/${id}/credentials/${credential_id}`,
    {
      method: 'GET',
      ...headers(),
    },
  );

const deleteAutomationCredential = (id: string, credential_id: string) =>
  fetch(
    `${config.apiBaseUrl}/v1/automation/automations/${id}/credentials/${credential_id}`,
    {
      method: 'DELETE',
      ...headers(),
    },
  );

const getAutomationLogs = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/logs`, {
    method: 'GET',
    ...headers(),
  });

const getAutomationJobs = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/jobs`, {
    method: 'GET',
    ...headers(),
  });

const runAutomation = (id: string, form?: StartRunForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/run`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const abortAutomation = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/abort`, {
    method: 'POST',
    ...headers(),
  });

const livePushAutomation = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/live_push`, {
    method: 'POST',
    ...headers(),
  });

const createAutomation = (data: AutomationCreateData) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations`, {
    method: 'POST',
    body: JSON.stringify(data),
    ...headers(),
  });

const importToAutomationGraph = (id: string, form: RecipeToGraphForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/import`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const updateAutomation = (id: string, form: AutomationUpdateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const batchAutomationGraph = (id: string, form: AutomationBatchForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/batch`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const batchDestroyAutomationGraph = (
  id: string,
  form: { node_ids?: string[]; group_ids?: string[] },
) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/batch_destroy`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const deleteAutomation = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const duplicateAutomation = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/duplicate`, {
    method: 'POST',
    ...headers(),
  });

const resetAutomation = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/automations/${id}/reset`, {
    method: 'POST',
    ...headers(),
  });

const getAutomationGraph = (automationId: string) =>
  fetch(
    `${config.apiBaseUrl}/v1/automation/automations/${automationId}/graph`,
    {
      method: 'GET',
      ...headers(),
    },
  );

const exportFromAutomation = (automationId: string, elementIds: ElementId[]) =>
  fetch(exportLink(automationId, elementIds), {
    method: 'GET',
    ...headers(),
  });

export default {
  abortAutomation,
  duplicateAutomation,
  batchAutomationGraph,
  batchDestroyAutomationGraph,
  createAutomation,
  createAutomationBackup,
  createAutomationCredential,
  deleteAutomation,
  deleteAutomationCredential,
  exportFromAutomation,
  getAutomation,
  getAutomationBackup,
  getAutomationBackups,
  getAutomationCredential,
  getAutomationCredentials,
  getAutomationAudits,
  getAutomationGraph,
  getAutomationJobs,
  getAutomationLogs,
  getAutomationRuns,
  getAutomations,
  importToAutomationGraph,
  resetAutomation,
  runAutomation,
  updateAutomation,
  livePushAutomation,
};
