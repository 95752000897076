import { Modal as antdModal } from 'antd';
import { useEffect } from 'react';

const { useModal } = antdModal;

const Modal: Pick<typeof antdModal, 'confirm' | 'info'> = {
  confirm: antdModal.confirm,
  info: antdModal.info,
};

export { Modal };

export const ModalAndNotificationProvider = () => {
  const [modalInstance, modalContext] = useModal();

  useEffect(() => {
    Modal.confirm = (...args) => {
      // eslint-disable-next-line no-console
      console.warn(
        'Avoid using Modal.confirm and prefer using the Modal as a component',
      );
      return modalInstance.confirm(...args);
    };
    Modal.info = (...args) => {
      // eslint-disable-next-line no-console
      console.warn(
        'Avoid using Modal.info and prefer using the Modal as a component',
      );
      return modalInstance.info(...args);
    };
  }, [modalInstance]);

  return modalContext;
};
