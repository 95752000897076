import { config } from '../../config';
import { headers } from '../auth/headers';

const getReportScreenshots = (reportId: string) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${reportId}/screenshots`, {
    method: 'GET',
    ...headers(),
  });

const createReportScreenshot = (
  reportId: string,
  form: { identifier: string; url: string },
) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${reportId}/screenshots`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const updateReportScreenshot = (
  reportId: string,
  id: string,
  form: { url: string },
) =>
  fetch(
    `${config.apiBaseUrl}/v1/report/reports/${reportId}/screenshots/${id}`,
    {
      method: 'PUT',
      body: JSON.stringify({
        ...form,
      }),
      ...headers(),
    },
  );

export default {
  getReportScreenshots,
  createReportScreenshot,
  updateReportScreenshot,
};
