import nodesApi from '../api/automation/nodes';
import errorRulesApi from '../api/automation/nodes/errorRules';
import groupsApi from '../api/automation/groups';
import widgetsApi from '../api/automation/widgets';
import variablesApi from '../api/automation/variables';
import edgesApi from '../api/automation/edges';
import socketsApi from '../api/automation/sockets';
import automationApi from '../api/automation/automations';
import { reset as resetDragElement } from './useDragElements';
import { reset as resetElementStack } from './useElementStack';
import { ElementIdWithCoordinates } from '../types/ui/ElementId';
import {
  Graph,
  Group,
  GroupWithSockets,
  NodeWithSockets,
  Socket,
  SocketKind,
  Edges,
  Variable,
} from '../types/api/automation/Graph';
import { GroupCreateForm } from '../types/api/automation/GroupCreateForm';
import {
  WidgetCreateForm,
  WidgetUpdateForm,
} from '../types/api/automation/WidgetForm';
import {
  VariableCreateForm,
  VariableUpdateForm,
} from '../types/api/automation/VariableForm';
import { GroupEditForm } from '../types/api/automation/GroupEditForm';
import { EdgeForm } from '../types/api/automation/EdgeForm';
import { Error } from '../types/api/Error';
import { RecipeToGraphForm } from '../types/ui/RecipeToGraphForm';
import { reset as resetEditorActions } from '../stores/useEditorActions';
import { setAutomationId } from '../stores/useAutomationId';
import {
  NodeCreateForm,
  NodeForm,
  ResetNodeSocketForm,
} from '../types/api/automation/NodeForm';
import { SocketUpdateForm } from '../types/api/automation/SocketUpdateForm';
import { setNotification } from './useUiNotifications';
import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';
import { diffToGraph } from '../util/graph/diffToGraph';
import { setGroupId } from './useGroupIds';
import { genUUID } from '../util';
import { replaceGroupIdFromGroups } from '../util/graph/replaceGroupIdFromGroups';
import { replaceGroupIdFromNodes } from '../util/graph/replaceGroupIdFromNodes';
import { removeGroupAndAdaptParent } from '../util/graph/removeGroupAndAdaptParent';
import { setNodeLoading } from './useNodeLoading';

import { setNodeError } from './useNodeError';
import { setWidgetLoading } from './useWidgetLoading';
import { setGroupLoading } from './useGroupLoading';
import { setEdgeLoading } from './useEdgeLoading';
import { setSocketLoading } from './useSocketLoading';
import { AutomationBatchForm } from '../types/api/automation/AutomationBatchForm';
import { getDiff } from 'recursive-diff';
import { notification } from 'antd';

export type State = Graph | undefined;

export const helpers = { genUUID };

export const reset = () => {
  setState(undefined);
  setLoading(false);
  setNodeLoading({});
  setNodeError(undefined);
};

const errorToDescription = (error: Error) => {
  let msg = error.error;
  if (error.details) {
    msg = `${msg} - ${error.details}`;
  }
  if (error.errors) {
    msg = `${msg} - ${error.errors
      .map((item) => JSON.stringify(item))
      .join(', ')}`;
  }
  return msg;
};

const nodeResponseToState = async (response: Response) => {
  const { node, inputs, outputs }: NodeWithSockets = await response.json();
  const state = getState();
  if (!state) return;
  setState({
    ...state,
    nodes: { ...state.nodes, [node.id]: node },
    outputs: { ...state.outputs, ...outputs },
    inputs: { ...state.inputs, ...inputs },
  });
};

const setSocketOnGraph = (socket: Socket) => {
  const graph = getState();
  if (!graph) {
    return;
  }
  if (socket.kind === 'automation/input') {
    setState({ ...graph, inputs: { ...graph.inputs, [socket.id]: socket } });
  } else {
    setState({ ...graph, outputs: { ...graph.outputs, [socket.id]: socket } });
  }
};

const [getState, setStoreState] = createStore<State>(StoreId.Graph, undefined);

const setState: typeof setStoreState = (state) => {
  const currentState = getState();
  const newState = typeof state === 'function' ? state(currentState) : state;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(
      `[${StoreId.Graph}] state change`,
      getDiff(currentState, newState),
    );
  }

  return setStoreState(newState);
};

const [, setLoading] = createStore(StoreId.GraphLoading, false);

export const getGraph = getState;

export const togglePublishGroupSocket = async (
  id: string,
  socket: Pick<Socket, 'title' | 'kind' | 'id'>,
  isPublic: boolean,
) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);
  try {
    const response = isPublic
      ? groupsApi.unpublishGroupSocket
      : groupsApi.publishGroupSocket;
    const result = await response(id, { socket_id: socket.id });
    if (result.ok) {
      const diff = await result.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      setGroupLoading(false);

      const description = isPublic
        ? `Socket ${socket.title} is no longer available outside of this group`
        : `The socket ${socket.title} is now available outside of this group`;
      setNotification({
        type: 'success',
        entity: 'group',
        description,
      });
      return true;
    }
  } catch {}

  setGroupLoading(false);
  setNotification({
    type: 'error',
    entity: 'group',
    description: 'An error occured on making socket public.',
  });
  return false;
};

export const getGroup = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);
  try {
    const response = await groupsApi.getGroup(id);
    if (response.ok) {
      const group: Group = await response.json();

      setState({ ...state, groups: { ...state.groups, [group.id]: group } });
      setGroupLoading(false);
      return true;
    }
  } catch {}

  setGroupLoading(false);
  setNotification({
    type: 'error',
    entity: 'group',
    description: 'An error occured on fetching the group.',
  });
  return false;
};

export const updateGroup = async (id: string, form: GroupEditForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);
  try {
    const response = await groupsApi.updateGroup(id, form);
    if (response.ok) {
      const group: Group = await response.json();

      setState({ ...state, groups: { ...state.groups, [group.id]: group } });
      setGroupLoading(false);
      setNotification({
        type: 'success',
        entity: 'group',
        description: 'Successfully updated group.',
      });
      return true;
    }
  } catch {}

  setGroupLoading(false);
  setNotification({
    type: 'error',
    entity: 'group',
    description: 'An error occured on updated group.',
  });
  return false;
};

export const ungroupGroup = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);
  try {
    const response = await groupsApi.ungroupGroup(id);
    if (response.ok) {
      const parentGroupId = state.groups[id].group_id;

      const filterdGroups = removeGroupAndAdaptParent(
        state.groups,
        id,
        parentGroupId,
      );
      const groups = replaceGroupIdFromGroups(filterdGroups, id, parentGroupId);
      const nodes = replaceGroupIdFromNodes(state.nodes, id, parentGroupId);

      setState({ ...state, groups, nodes });
      setGroupLoading(false);
      setNotification({
        type: 'success',
        entity: 'group',
        description: 'Successfully ungrouped group.',
      });
      return true;
    }
  } catch {}

  setGroupLoading(false);
  setNotification({
    type: 'error',
    entity: 'group',
    description: 'An error occured on ungrouping of a group.',
  });
  return false;
};

// TODO: optimisitc destroy would be nice to have but maybe the API should give back the graph
export const destroyGroup = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);
  try {
    const response = await groupsApi.destroyGroup(id);
    if (response.ok) {
      await resetGraph(state.automation_id);
      setGroupLoading(false);
      return true;
    }
  } catch {}
  setGroupLoading(false);
  return false;
};

export const createGroup = async (form: GroupCreateForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);
  try {
    const response = await groupsApi.createGroup(form);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      setGroupLoading(false);
      setNotification({
        type: 'success',
        entity: 'group',
        description: 'Successfully created group.',
      });
      return true;
    }
  } catch {}

  setGroupLoading(false);
  setNotification({
    type: 'error',
    entity: 'group',
    description: 'An error occured on creating group.',
  });
  return false;
};

// Crud Edge
export const destroyEdge = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setEdgeLoading(true);
  try {
    const response = await edgesApi.destroyEdge(id);
    setEdgeLoading(false);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      const removed = state.edges[id];
      setState(diffToGraph(state, diff));
      setNotification({
        type: 'success',
        entity: 'edge',
        description: 'Successfully deleted connection.',
        action: {
          onClick: () => createEdge(removed),
          title: 'Undo',
        },
      });
      return true;
    }
  } catch {}

  setEdgeLoading(false);
  setNotification({
    type: 'error',
    entity: 'edge',
    description: 'An error occured on deletion of a connection.',
  });
  return false;
};

export const createEdge = async (form: EdgeForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  // optimistic
  const optimisticId = helpers.genUUID();
  setState({
    ...state,
    edges: {
      ...state.edges,
      [optimisticId]: { id: optimisticId, ...form },
    },
  });

  setEdgeLoading(true);
  try {
    const response = await edgesApi.createEdge(form);
    setEdgeLoading(false);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      // remove optimistic edge
      delete state.edges[optimisticId];

      setState(diffToGraph(state, diff));
      setNotification({
        type: 'success',
        entity: 'edge',
        description: 'Successfully created connection.',
      });
      return true;
    }
  } catch {}

  setEdgeLoading(false);
  // reset to state before optimisic set
  setState(state);
  setNotification({
    type: 'error',
    entity: 'edge',
    description: 'An error occured on creation of a connection.',
  });
  return false;
};

export const upgradeNode = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await nodesApi.upgradeNode(id);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      notification.success({ message: 'Successfully upgraded node' });
      return true;
    }

    const error: Error = await response.json();
    notification.error({ message: error.details });
    return false;
  } catch {}

  notification.error({
    message: 'An error occured on upgrading node. Try again.',
  });

  return false;
};

export const resetNode = async (id: string, form: ResetNodeSocketForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  setNodeLoading((s) => ({ ...s, [id]: true }));
  try {
    const response = await nodesApi.resetNode(id, form);
    setNodeLoading((s) => ({ ...s, [id]: false }));
    if (response.ok) {
      // Response with sockets
      nodeResponseToState(response);
      setNotification({
        type: 'success',
        entity: 'node',
        description: 'Successfully cleared data of node.',
      });
      return true;
    }
  } catch {}
  setNodeLoading((s) => ({ ...s, [id]: false }));
  setNotification({
    type: 'error',
    entity: 'node',
    description: 'An error occured on clearing data of node.',
  });
  return false;
};

export const batchExportDisconnectedInputs = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }

  const { edges, inputs } = state;

  const connectedIds = uniqueConnectedIds(edges);
  const ids = Object.keys(inputs).filter((id) => !connectedIds.includes(id));

  const form: AutomationBatchForm = {
    inputs: {},
  };
  const optimisticInputs: Graph['inputs'] = {};

  ids.forEach((id) => {
    form.inputs![id] = { export_enabled: true };
    optimisticInputs[id] = { ...inputs[id], id, export_enabled: true };
  });

  setState({
    ...state,
    inputs: { ...inputs, ...optimisticInputs },
  });

  try {
    const response = await automationApi.batchAutomationGraph(id, form);
    if (response.ok) {
      setNotification({
        type: 'success',
        entity: 'graph',
        description:
          'Successfully enabled export of inputs with no edges attached.',
      });
      return;
    }
  } catch {}

  resetDragElement();
  resetElementStack();
  setState(state);

  setNotification({
    type: 'error',
    entity: 'graph',
    description: 'An error occured on making inputs public graph elements.',
  });
};

const uniqueConnectedIds = (edges: Edges) => {
  const connectedInputIds: string[] = [];
  Object.keys(edges).forEach((id) => {
    connectedInputIds.push(edges[id].input_id);
  });
  return [...new Set(connectedInputIds)];
};

export const batchUpdateAutomationGraph = async (
  id: string,
  elements: ElementIdWithCoordinates[],
) => {
  const state = getState();
  if (!state) {
    return;
  }

  const nodes = elements.filter(({ klass }) => klass === 'node');
  const groups = elements.filter(({ klass }) => klass === 'group');

  const form: AutomationBatchForm = {
    nodes: {},
    groups: {},
  };
  const optimisticGroups: Graph['groups'] = {};
  const optimisticNodes: Graph['nodes'] = {};

  nodes.forEach(({ id, coordinates }) => {
    form.nodes![id] = { ...coordinates };
    optimisticNodes[id] = { ...state.nodes[id], id, ...coordinates };
  });

  groups.forEach(({ id, coordinates }) => {
    form.groups![id] = { ...coordinates };
    optimisticGroups[id] = { ...state.groups[id], id, ...coordinates };
  });

  setState({
    ...state,
    nodes: { ...state.nodes, ...optimisticNodes },
    groups: { ...state.groups, ...optimisticGroups },
  });

  try {
    const response = await automationApi.batchAutomationGraph(id, form);
    if (response.ok) {
      return;
    }
  } catch {}

  resetDragElement();
  resetElementStack();
  setState(state);

  setNotification({
    type: 'error',
    entity: 'graph',
    description: 'An error occured on moving graph elements.',
  });
};

export const resetSocket = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setSocketLoading(true);
  try {
    const response = await socketsApi.resetSocket(id);
    setSocketLoading(false);
    if (response.ok) {
      setSocketOnGraph(await response.json());
      setNotification({
        type: 'success',
        entity: 'socket',
        description: `Successfully resetted socket.`,
      });
      return true;
    }
  } catch {}
  setSocketLoading(false);
  setNotification({
    type: 'error',
    entity: 'socket',
    description: `An error occured while resetting socket.`,
  });
  return false;
};

export const updateNode = async (
  id: string,
  form: NodeForm,
  onClose?: () => void,
) => {
  const state = getState();
  if (!state) {
    return;
  }
  setNodeError(undefined);
  setNodeLoading((s) => ({ ...s, [id]: true }));
  try {
    const response = await nodesApi.updateNode(id, form);
    setNodeLoading((s) => ({ ...s, [id]: false }));
    if (response.ok) {
      nodeResponseToState(response);

      setNotification({
        type: 'success',
        entity: 'node',
        description: `Successfully updated socket of node.`,
      });
      onClose?.();
      return true;
    }

    const error: Error = await response.json();
    setNodeError(error);
    setNotification({
      type: 'error',
      entity: 'node',
      description: errorToDescription(error),
    });
    return false;
  } catch {}
  setNodeLoading((s) => ({ ...s, [id]: false }));
  setNotification({
    type: 'error',
    entity: 'node',
    description: `An error occured on update socket of node.`,
  });
  return false;
};

export const duplicateNode = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setNodeError(undefined);
  setLoading(true);
  try {
    const response = await nodesApi.duplicateNode(id);
    setLoading(false);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      resetEditorActions();
      return true;
    }

    const error: Error = await response.json();
    setNodeError(error);
  } catch {}
  setLoading(false);
  return false;
};

export const duplicateGroup = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setLoading(true);
  try {
    const response = await groupsApi.duplicateGroup(id);
    setLoading(false);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      resetEditorActions();
      return true;
    }
  } catch {}
  setLoading(false);
  return false;
};

export const createNode = async (form: NodeCreateForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  setNodeLoading((s) => ({ ...s, on_create_id: true }));
  try {
    const response = await nodesApi.createNode(form);
    setNodeLoading((s) => ({ ...s, on_create_id: false }));
    if (response.ok) {
      nodeResponseToState(response);
      setNotification({
        type: 'success',
        entity: 'node',
        description: `Successfully created node.`,
      });
      resetEditorActions();
      return true;
    }

    const error: Error = await response.json();
    setNodeError(error);
    setNotification({
      type: 'error',
      entity: 'node',
      description: errorToDescription(error),
    });
    return false;
  } catch {}
  setNodeLoading((s) => ({ ...s, on_create_id: false }));
  setNotification({
    type: 'error',
    entity: 'node',
    description: 'An error occured on creation of node.',
  });
  return false;
};

export const batchDestroyNodes = async (ids: string[]) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await nodesApi.batchDestroyNodes({ ids });
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      resetEditorActions();
      return true;
    }
  } catch {}
  return false;
};

export const batchDestroyAutomationGraph = async (
  id: string,
  form: { node_ids?: string[]; group_ids?: string[] },
) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await automationApi.batchDestroyAutomationGraph(id, form);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      resetEditorActions();
      return true;
    }
  } catch {}
  return false;
};

export const destroyNode = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setNodeLoading((s) => ({ ...s, [id]: true }));
  try {
    const response = await nodesApi.destroyNode(id);
    setNodeLoading((s) => ({ ...s, [id]: false }));
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      resetEditorActions();
      return true;
    }
  } catch {}
  setNodeLoading((s) => ({ ...s, [id]: false }));
  return false;
};

export const updateSocket = async (
  id: string,
  socketKind: SocketKind,
  form: SocketUpdateForm,
) => {
  const state = getState();
  if (!state) {
    return;
  }
  setSocketLoading(true);
  setNodeError(undefined);
  try {
    const response = await socketsApi.updateSocket(id, form);
    if (response.ok) {
      setSocketOnGraph(await response.json());
      setSocketLoading(false);
      setNotification({
        type: 'success',
        entity: socketKind,
        description: `Successfully updated ${socketKind}.`,
      });
      return true;
    }

    const error: Error = await response.json();
    setNodeError(error);
    setSocketLoading(false);
    setNotification({
      type: 'error',
      entity: socketKind,
      description: errorToDescription(error),
    });
    return false;
  } catch {}
  setSocketLoading(false);
  setNotification({
    type: 'error',
    entity: socketKind,
    description: `An error occured on updated ${socketKind}.`,
  });
  return false;
};

export const getSocket = async (
  id: string,
  socketKind: SocketKind,
  params?: Record<string, string | boolean>,
) => {
  const state = getState();
  if (!state) {
    return;
  }
  setSocketLoading(true);
  try {
    const response = await socketsApi.getSocket(id, params);
    if (response.ok) {
      setSocketOnGraph(await response.json());
      setSocketLoading(false);
      return true;
    }

    const error: Error = await response.json();
    setNodeError(error);
    setSocketLoading(false);
    setNotification({
      type: 'error',
      entity: 'socket',
      description: errorToDescription(error),
    });
    return false;
  } catch {}
  setSocketLoading(false);
  setNotification({
    type: 'error',
    entity: 'socket',
    description: `An error occured on fetching the ${socketKind} socket.`,
  });
  return false;
};

export const getNode = async (id: string, params?: Record<string, boolean>) => {
  const state = getState();
  if (!state) {
    return;
  }
  setNodeLoading((s) => ({ ...s, [id]: true }));
  setNodeError(undefined);
  try {
    const query = params
      ? `${Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')}`
      : undefined;

    const response = await nodesApi.getNode(id, query);
    if (response.ok) {
      nodeResponseToState(response);
      setNodeLoading((s) => ({ ...s, [id]: false }));
      return true;
    }

    setNodeLoading((s) => ({ ...s, [id]: false }));
    const error: Error = await response.json();

    setNodeError(error);
    setNotification({
      type: 'error',
      entity: 'node',
      description: errorToDescription(error),
    });
    return false;
  } catch {
    setNodeLoading((s) => ({ ...s, [id]: false }));
    setNotification({
      type: 'error',
      entity: 'node',
      description: 'An error occured on fetching the node.',
    });
    return false;
  }
};

// CURD Node

export const resetAutomationGraph = async (automationId: string) => {
  setLoading(true);
  try {
    const response = await automationApi.resetAutomation(automationId);
    if (response.ok) {
      const graphResponse: Graph = await response.json();
      setState(graphResponse);
      setLoading(false);
      setNotification({
        type: 'success',
        entity: 'automation',
        description:
          'Successfully cleard all data fields of sockets in automation.',
      });
      return true;
    }
  } catch {}
  setLoading(false);
  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured in clearing data of automation.',
  });
  return false;
};

export const resetGroup = async (id: string, form: ResetNodeSocketForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  setGroupLoading(true);

  try {
    const response = await groupsApi.resetGroup(id, form);

    if (response.ok) {
      const groupResponse: GroupWithSockets = await response.json();
      setState({
        ...state,
        groups: {
          ...state.groups,
          [id]: { ...groupResponse.group },
        },
        inputs: { ...state.inputs, ...groupResponse.inputs },
        outputs: { ...state.outputs, ...groupResponse.outputs },
      });
      setGroupLoading(false);
      setNotification({
        type: 'success',
        entity: 'group',
        description: 'Successfully resetted group sockets.',
      });
      return true;
    }
  } catch {}

  setGroupLoading(false);
  setNotification({
    type: 'error',
    entity: 'group',
    description: 'An error occured on resetting the group',
  });
  return false;
};

export const resetGraph = async (automationId: string) => {
  setAutomationId(automationId);
  setGroupId(undefined);
  setLoading(true);
  try {
    const response = await automationApi.getAutomationGraph(automationId);
    if (response.ok) {
      const graph: Graph = await response.json();
      setLoading(false);
      setState(graph);
      return true;
    }
  } catch {}
  setLoading(false);
  setNotification({
    type: 'error',
    entity: 'graph',
    description: 'An error occured on fetching the graph',
  });
  return false;
};

export const setSocket = async (data: Socket) => {
  const state = getState();
  // make sure it is the right automation
  if (!state || state.automation_id !== data.automation_id) {
    return;
  }
  setSocketOnGraph(data);
};

export const setVariable = async (data: Variable) => {
  const state = getState();
  // make sure it is the right automation
  if (!state || state.automation_id !== data.automation_id) {
    return;
  }
  setState({ ...state, variables: { ...state.variables, [data.id]: data } });
};

export const importToAutomationGraph = async (
  automationId: string,
  form: RecipeToGraphForm,
) => {
  setLoading(true);
  try {
    const response = await automationApi.importToAutomationGraph(
      automationId,
      form,
    );
    if (response.ok) {
      const graph: Graph = await response.json();
      setState(graph);
      setLoading(false);
      resetEditorActions();
      return true;
    }
    const error: Error = await response.json();
    return error;
  } catch {}
  setLoading(false);
  return { code: 'unknown', error: 'Unknown error' };
};

// BEGIN Create & Update & Delete Widgets

export const createWidget = async (form: WidgetCreateForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  setWidgetLoading(true);
  try {
    const response = await widgetsApi.createWidget(form);
    if (response.ok) {
      // update widgets
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      setWidgetLoading(false);
      setNotification({
        type: 'success',
        entity: 'widget',
        description: 'Successfully created widget.',
      });
      return true;
    }
  } catch {}

  setWidgetLoading(false);
  setNotification({
    type: 'error',
    entity: 'widget',
    description: 'An error occured on creating widget.',
  });
  return false;
};

export const updateWidget = async (
  id: string,
  automationId: string,
  form: WidgetUpdateForm,
) => {
  const state = getState();
  if (!state) {
    return;
  }
  setWidgetLoading(true);
  try {
    const response = await widgetsApi.updateWidget(id, form);
    if (response.ok) {
      // update widgets
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      setWidgetLoading(false);
      setNotification({
        type: 'success',
        entity: 'widget',
        description: 'Successfully updated widget.',
      });
      return true;
    }
  } catch {}

  setWidgetLoading(false);
  setNotification({
    type: 'error',
    entity: 'widget',
    description: 'An error occured on updated widget.',
  });
  return false;
};

export const destroyWidget = async (id: string, automationId: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setWidgetLoading(true);
  try {
    const response = await widgetsApi.destroyWidget(id);
    if (response.ok) {
      // update widgets
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      setWidgetLoading(false);
      setNotification({
        type: 'success',
        entity: 'widget',
        description: 'Successfully destroyed widget.',
      });
      return true;
    }
  } catch {}

  setWidgetLoading(false);
  setNotification({
    type: 'error',
    entity: 'widget',
    description: 'An error occured on destroying of a widget.',
  });
  return false;
};

// END Create & Update & Delete Widgets

// Start Create & Update & Delete Variables

export const createVariable = async (form: VariableCreateForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await variablesApi.createVariable(form);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      return true;
    }
  } catch {}
  return false;
};

export const updateVariable = async (id: string, form: VariableUpdateForm) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await variablesApi.updateVariable(id, form);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      return true;
    }
  } catch {}
  return false;
};

export const destroyVariable = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await variablesApi.destroyVariable(id);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));

      return true;
    }
  } catch {}
  return false;
};
// End Create & Update & Delete Variables

// Start CUD Error Rules

export const createErrorRule = async (
  node_id: string,
  form: { expression: string; error_type: string },
) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await errorRulesApi.createErrorRule(node_id, form);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      return true;
    }
  } catch {}
  return false;
};

export const updateErrorRule = async (
  node_id: string,
  id: string,
  form: { expression: string; error_type: string },
) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await errorRulesApi.updateErrorRule(node_id, id, form);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      return true;
    }
  } catch {}
  return false;
};

export const destroyErrorRule = async (node_id: string, id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  try {
    const response = await errorRulesApi.destroyErrorRule(node_id, id);
    if (response.ok) {
      const diff = await response.json();
      const state = getState();
      if (!state) {
        return;
      }
      setState(diffToGraph(state, diff));
      return true;
    }
  } catch {}
  return false;
};

// End CUD Error Rules

export const useGraph = () => {
  const [graph] = useStore<State>(StoreId.Graph);
  const [isLoading] = useStore<boolean>(StoreId.GraphLoading);

  return {
    graph,
    isLoading,
  };
};
