import { FC, PropsWithChildren } from 'react';
import { Container, Content, Main, FixedContainer, Overlay } from './styled';
import { Header } from './header/Header';

export type ModalProps = PropsWithChildren & {
  onClose?: () => void;
  className?: string;
  title?: string;
  noscroll?: boolean;
};

export const Modal: FC<ModalProps> = ({
  onClose,
  className,
  children,
  title,
  noscroll,
}) => (
  <FixedContainer>
    <Container>
      <Overlay onClick={() => onClose && onClose()} />
      <Content className={className}>
        {title && <Header title={title} onClose={onClose} />}
        <Main noscroll={noscroll}>{children}</Main>
      </Content>
    </Container>
  </FixedContainer>
);
