import { Nodes } from '../../types/api/automation/Graph';

export const replaceGroupIdFromNodes = (
  nodes: Nodes,
  groupId: string,
  parentId?: string,
): Nodes => {
  const newNodes: Nodes = {};
  Object.values(nodes).forEach((node) => {
    if (node.group_id === groupId) {
      newNodes[node.id] = { ...node, group_id: parentId };
    } else {
      newNodes[node.id] = node;
    }
  });
  return newNodes;
};
