import { config } from '../../config';
import {
  WidgetUpdateForm,
  WidgetCreateForm,
} from '../../types/api/automation/WidgetForm';
import { headers } from '../auth/headers';

const createWidget = (form: WidgetCreateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/widgets`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const destroyWidget = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/widgets/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const updateWidget = (id: string, form: WidgetUpdateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/widgets/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

export default {
  createWidget,
  destroyWidget,
  updateWidget,
};
