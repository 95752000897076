import styled, { css } from 'styled-components';

export const StyledButton = styled.a<{ fullWidth?: boolean }>`
  background-color: #1877f2;
  border-color: #1877f2;
  height: 40px;
  border-radius: 4px;
  display: flex;

  svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin: 8px 8px 8px 12px;
  }

  color: white;
  :hover {
    color: white;
  }

  > span {
    flex-grow: 1;
    font-weight: bold;
    font-size: 16px;
    line-height: 40px;
    padding-right: 12px;
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: 0.25px;
    overflow: hidden;
    text-align: center;
    text-overflow: clip;
    white-space: nowrap;
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  &[disabled] {
    pointer-events: none;
  }
`;
