interface Config {
  apiBaseUrl: string;
  useAuthorizationHeader: boolean;
}

const { NODE_ENV } = process.env;

const getBackendUrl = () => {
  if (NODE_ENV === 'test')
    return process.env.REACT_APP_API_URL || 'https://localhost:3000';
  return process.env.REACT_APP_API_URL;
};

const REACT_APP_API_URL = getBackendUrl();

if (NODE_ENV === 'production') {
  Object.entries({
    REACT_APP_API_URL,
  }).forEach(([key, value]) => {
    if (!value) {
      throw new Error(`Please set ${key}`);
    }
  });
}
export const config: Config = {
  apiBaseUrl: REACT_APP_API_URL!,
  useAuthorizationHeader: Boolean(
    process.env.REACT_APP_USE_AUTHORIZATION_HEADER,
  ),
};
