import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.m};
`;
