import styled from 'styled-components';
import { media } from '../../../../styles/media';

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.s};
  color: ${({ theme }) => theme.color.darkest};
  input {
    box-sizing: border-box;
    max-width: 100%;
  }
  ${media.md`
    display: flex;
    flex-direction: column;
  `}
`;

export const ColorPicker = styled.div`
  display: grid;
  padding: ${({ theme }) => theme.spacing.xs} 0;
  grid-template-columns: 60px 60px 60px 60px 60px;
  grid-template-rows: 60px;
  column-gap: 4px;
  row-gap: 4px;
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

interface ColorProps {
  color: string;
}

export const ColorContainer = styled.label<ColorProps>`
  background: ${({ color }) => color};
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.lightest};
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 2px;
  padding: 2px;
  overflow: hidden;

  svg {
    height: 22px;
    width: 22px;
    display: none;
    margin-top: 2px;
  }

  & > input:checked {
    border: 2px solid ${({ theme }) => theme.color.darkest};
  }

  & > input:checked + svg {
    display: block;
  }

  &:focus {
    outline: none;
  }
`;

export const ColorInput = styled.input`
  position: absolute;
  appearance: none;
  width: 100%;
  height: 100%;
  z-index: 0;
  outline: none;
  cursor: pointer;
  border: 2px solid transparent;
`;
