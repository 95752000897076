import { likePattern } from '../likePattern';

type QueryProps = {
  name: string;
  disabled?: boolean;
  paging?: { limit?: number; page?: number };
  sorting?: { sort: string; direction: string };
};

export const buildQueryParams = ({
  name,
  paging,
  sorting,
  disabled,
}: QueryProps) => {
  let params = '';

  if (paging) {
    params += `limit=${paging.limit}&page=${paging.page}`;
  }

  if (disabled !== undefined) {
    params += `&filter=disabled: { "=": "${disabled}" }`;
  }

  if (name) {
    params += `&filter=name: { like: "${likePattern(name)}" }`;
  }

  if (sorting) {
    params += `&sort=${sorting.sort}&direction=${sorting.direction}`;
  }
  return new URLSearchParams(params);
};
