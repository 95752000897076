import { config } from '../../config';

const httpOnly = {
  credentials: 'include',
  headers: new Headers({
    'Content-Type': 'application/json',
  }),
};

const authorizationHeader = (tokenId?: string) => ({
  headers: new Headers({
    Authorization: sessionStorage.getItem('token_id') || tokenId || '',
    'Content-Type': 'application/json',
  }),
});

export const headers = (tokenId?: string) => {
  if (config.useAuthorizationHeader) {
    return authorizationHeader(tokenId);
  }

  return httpOnly;
};
