import './Polyfills';
import 'antd/dist/reset.css';
import 'react-datasheet-grid/dist/style.css';
import { FC } from 'react';
import { init, Replay, BrowserTracing } from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'styled-components';
import { darkMode, lightMode } from './styles/theme';
import { Routes } from './Routes';
import { Layout } from './layout/Layout';
import { ErrorBoundary } from './errorBoundary/ErrorBoundary';
import { BrowserRouter } from 'react-router-dom';
import { useWebSocket } from './stores/useWebSocket';
import { useSettings } from './stores/useSettings';
import './i18n/i18n';

const { REACT_APP_KITCHN_ENV, REACT_APP_KITCHN_COMMIT_SHA } = process.env;
init({
  dsn: 'https://3e2d7922e84044f3a992751c88e92fb8@o481715.ingest.sentry.io/5650819',
  release: REACT_APP_KITCHN_COMMIT_SHA,
  enabled: !!REACT_APP_KITCHN_ENV,
  environment: REACT_APP_KITCHN_ENV,
  attachStacktrace: true,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Replay()],
});

export const App: FC = () => {
  const { hasDarkMode } = useSettings();
  useWebSocket();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={hasDarkMode ? darkMode : lightMode}>
        <BrowserRouter>
          <Layout>
            <Routes />
          </Layout>
        </BrowserRouter>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

createRoot(document.getElementById('root')!).render(<App />);
