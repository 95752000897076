import { FC } from 'react';
import { Container } from './styled';

interface Props {
  name?: string;
  value?: string;
  id?: string;
  ref?: (e: any) => void;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  autofocus?: boolean;
}

export const Input: FC<Props> = ({
  autofocus,
  name,
  value,
  id,
  type,
  required,
  disabled,
  onKeyDown,
  onChange,
  ref,
  ...rest
}) => (
  <Container
    {...rest}
    name={name}
    value={value}
    autoFocus
    autoComplete="off"
    id={id}
    type={type}
    required={required}
    disabled={disabled}
    onChange={onChange}
    onKeyDown={onKeyDown}
    ref={ref}
  />
);
