import { Form, Button, Alert, Typography } from 'antd';
import styled from 'styled-components';

export const FormItem = styled(Form.Item)`
  label {
    font-size: 16px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const BackLink = styled(StyledButton)`
  margin-top: ${({ theme }) => theme.spacing.l};
  color: ${({ theme }) => theme.color.secondary6};
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const Description = styled(Typography.Text)`
  margin-bottom: ${({ theme }) => theme.spacing.l};
  text-align: center;
`;
