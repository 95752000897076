import styled, { css } from 'styled-components';
import { media } from '../../styles/media';
import { touchSupport } from '../../util/touchSupport';
import SVG from '../../assets/loading/spinner.svg';

interface SmallProps {
  small?: boolean;
}

interface TitleProps {
  ellipsis?: boolean;
}

interface ContainerProps extends SmallProps {
  open?: boolean;
  selected?: boolean;
  disabled?: boolean;
}

export const SelectContainer = styled.div<SmallProps>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;

  height: ${({ small }) => (small ? 32 : 46)}px;

  > select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    /* Set as 16px for mobiles to not zoom in when clicking */
    font-size: 16px;

    ${media.md`
      display: none;
    `}
  }

  > svg {
    margin-left: ${({ theme }) => theme.spacing.s};
    flex-shrink: 0;
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  background: ${({ theme }) => theme.color.lightest};
  color: ${({ theme }) => theme.color.lightGrey};
  box-sizing: border-box;

  border-radius: 2px;
  ${({ selected, theme }) => css`
    border: 1px solid
      ${selected ? theme.color.lighterGrey : theme.color.lighterGrey};
  `}

  width: 100%;
  cursor: pointer;

  ${SelectContainer} > svg {
    color: ${({ theme }) => theme.color.darkest};
    width: 22px;
  }

  ${!touchSupport &&
  css`
    &:hover {
      border-color: ${({ theme }) => theme.color.darkest};
    }
  `}

  ${({ open }) =>
    open &&
    css`
      border-color: ${({ theme }) => theme.color.inputSelect}!important;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      ${SelectContainer} > svg {
        transform: rotate(180deg);
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.lightGrey};
      background-color: ${({ theme }) => theme.color.backgroundGrey};
      border-color: ${({ theme }) => theme.color.lightGrey};
    `}
`;

export const Title = styled.div<TitleProps>`
  width: 100%;
  color: ${({ theme }) => theme.color.darkest};
  pointer-events: none;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 18px;
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

export const Placeholder = styled(Title)<TitleProps>`
  width: 100%;
  pointer-events: none;
  color: ${({ theme }) => theme.color.lighterGrey};
`;

export const OptionsContainer = styled.div<SmallProps>`
  z-index: 10;
  position: absolute;
  top: ${({ small }) => (small ? 34 : 47)}px;
  left: -1px;
  overflow-y: auto;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.lightest};
  border: 1px solid ${({ theme }) => theme.color.lighterGrey};
  border-radius: 2px;
  font-size: 16px;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  margin-top: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
  padding: 0;
  max-height: 80vh;
`;

interface Props {
  value: string;
  disabled?: boolean;
  selected?: boolean;
}

export const Option = styled.div<Props>`
  box-sizing: border-box;

  display: flex;
  align-items: center;
  > * {
    pointer-events: none;
  }

  > :nth-child(2) {
    margin-left: ${({ theme }) => theme.spacing.s};
  }

  div {
    padding: ${({ theme }) => theme.spacing.m};
    color: ${({ theme }) => theme.color.brandSecondary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      background: ${({ theme }) => theme.color.backgroundGrey};
      > * {
        color: ${({ theme }) => theme.color.grey};
      }
    `}

  ${({ selected, disabled }) =>
    !disabled &&
    selected &&
    css`
      background: ${({ theme }) => theme.color.lightest};

      ${!touchSupport &&
      css`
        &:hover > * {
          background: ${({ theme }) => theme.color.backgroundGrey};
          color: ${({ theme }) => theme.color.darkest};
        }
      `}
    `}

  ${({ selected, disabled }) =>
    !disabled &&
    !selected &&
    !touchSupport &&
    css`
      &:hover {
        background: ${({ theme }) => theme.color.lightest};
        > * {
          color: ${({ theme }) => theme.color.brandSecondary};
        }
      }
    `}
`;

interface SpinnerProps {
  isVisible: boolean;
}

export const Spinner = styled.div<SpinnerProps>`
  background: url(${SVG}) no-repeat center center;
  background-size: cover;
  height: 18px;
  width: 18px;
  position: absolute;
  right: 35px;
  top: 14px;
  display: none;

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: block;
    `}
`;
