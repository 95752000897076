import { config } from '../../config';
import { EdgeForm } from '../../types/api/automation/EdgeForm';
import { headers } from '../auth/headers';

export const getEdges = () =>
  fetch(`${config.apiBaseUrl}/v1/automation/edges`, {
    method: 'GET',
    ...headers(),
  });

export const createEdge = (form: EdgeForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/edges`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

export const destroyEdge = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/edges/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

export const updateEdge = (id: string, form: EdgeForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/edges/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

export default {
  updateEdge,
  destroyEdge,
  createEdge,
  getEdges,
};
