import { config } from '../../config';
import {
  VariableUpdateForm,
  VariableCreateForm,
} from '../../types/api/automation/VariableForm';
import { headers } from '../auth/headers';

const createVariable = (form: VariableCreateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/variables`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const destroyVariable = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/variables/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const updateVariable = (id: string, form: VariableUpdateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/variables/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

export default {
  createVariable,
  destroyVariable,
  updateVariable,
};
