export enum ProviderId {
  Facebook = 'facebook',
  GoogleAds = 'google_ads',
  AppleSearchAds = 'apple_search_ads',
  AppleAppStoreConnect = 'apple_app_store_connect',
  Tableau = 'tableau',
  Taboola = 'taboola',
  Hyros = 'hyros',
  Emarsys = 'emarsys',
  AppsFlyer = 'apps_flyer',
  Adjust = 'adjust',
  Shopify = 'shopify',
  Linkedin = 'linkedin',
  Microsoft365 = 'microsoft365',
  MondayCom = 'monday_com',
  Asana = 'asana',
  AwsAthena = 'aws_athena',
  Airtable = 'airtable',
  Snapchat = 'snapchat',
  TikTok = 'tik_tok',
  Twitter = 'twitter',
  TwitterAds = 'twitter_ads',
  Pinterest = 'pinterest',
  GoogleAnalytics = 'google_analytics',
  GoogleBigQuery = 'google_big_query',
  GoogleDv360 = 'google_dv360',
  GoogleDrive = 'google_drive',
  GoogleSheets = 'google_sheets',
  Slack = 'slack',
  Notion = 'notion',
  Dropbox = 'dropbox',
  DropboxBusiness = 'dropbox_business',
  Outbrain = 'outbrain',
  Youtube = 'youtube',
  Squaredance = 'squaredance',
  Sigma = 'sigma',
  Frontify = 'frontify',
  Shotstack = 'shotstack',
}

export type Provider = keyof typeof ProviderId;

export const PROVIDER_IDS = Object.values(ProviderId) as ProviderId[];

export const PROVIDERS = Object.keys(ProviderId) as Provider[];

export const PROVIDER_NO_AUTHORIZE = [
  'monday_com',
  'asana',
  'airtable',
  'hyros',
  'shopify',
  'emarsys',
  'apps_flyer',
  'adjust',
  'squaredance',
  'shotstack',
] as ProviderId[];

export const PROVIDER_WITH_ACCOUNTS = [
  'facebook',
  'tik_tok',
  'pinterest',
  'google_ads',
  'google_analytics',
] as ProviderId[];

export const PROVIDER_WITH_CONFIG = [
  'facebook',
  'tik_tok',
  'pinterest',
  'google_ads',
  'google_analytics',
  'slack',
] as ProviderId[];

export const PROVIDER_WITH_MULTI_CREDENTIALS = [
  'tik_tok',
  'pinterest',
  'facebook',
  'shopify',
] as ProviderId[];
