import { FC, useState } from 'react';
import { Container, ColorPicker } from './styled';
import { Modal, ModalProps } from '../../modal/Modal';
import { Button } from '../../button/Button';
import { updateAutomation } from '../../../../stores/useAutomations';
import { Input } from '../../input/Input';
import { useAutomation } from '../../../../stores/useAutomation';
import { ColorSelect } from './ColorSelect';
import { Label } from '../../typography/Typography';
import { FormGroup } from '../../forms/Forms';
import { useTags } from '../../../../stores/useTags';

interface Props extends ModalProps {
  onClose: () => void;
  automationId: string;
  title?: string;
}

const colorOptions = [
  {
    color: '#9A9A9A',
    title: 'color-01',
  },
  {
    color: '#DE9597',
    title: 'color-02',
  },
  {
    color: '#6B93E1',
    title: 'color-03',
  },
  {
    color: '#92CA7F',
    title: 'color-04',
  },
  {
    color: '#E6BD5C',
    title: 'color-05',
  },
  {
    color: '#C0C0C0',
    title: 'color-06',
  },
  {
    color: '#CA7FC4',
    title: 'color-07',
  },
  {
    color: '#90D1E6',
    title: 'color-08',
  },
  {
    color: '#73D1BD',
    title: 'color-09',
  },
  {
    color: '#C4AC80',
    title: 'color-10',
  },
];

export const Content: FC<Props> = ({ onClose, title, automationId }) => {
  const { tags } = useTags();
  const [color, setColor] = useState<string>('#9A9A9A');
  const [name, setName] = useState<string>('');

  const { automation } = useAutomation(automationId);
  if (!automation || !tags) return null;

  const onSave = () => {
    updateAutomation(automationId, {
      tags: [...automation.tags, { name, color }],
    });
    onClose();
  };

  const isExistingTag = Boolean(tags.find((t) => t.name === name));

  return (
    <Modal onClose={onClose} title={title}>
      <Container>
        <FormGroup>
          <Label htmlFor="tagname">Name</Label>
          <Input
            name="name"
            id="tagname"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <Label>Color</Label>
        <ColorPicker>
          {colorOptions.map((o) => (
            <ColorSelect
              key={o.title}
              color={o.color}
              checked={color === o.color}
              title={o.title}
              onChange={(e) => setColor(e.target.value)}
            />
          ))}
        </ColorPicker>
        <Button disabled={isExistingTag} success onClick={onSave}>
          Save
        </Button>
      </Container>
    </Modal>
  );
};
