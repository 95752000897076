import { config } from '../../config';
import {
  NodeForm,
  NodeCreateForm,
  ResetNodeSocketForm,
  ProcessNodeForm,
} from '../../types/api/automation/NodeForm';
import { headers } from '../auth/headers';

const getNodes = () =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes`, {
    method: 'GET',
    ...headers(),
  });

const getNode = (id: string, query?: string) => {
  let url = `${config.apiBaseUrl}/v1/automation/nodes/${id}`;
  if (query) {
    url = `${url}?${query}`;
  }
  return fetch(url, {
    method: 'GET',
    ...headers(),
  });
};

const createNode = (form: NodeCreateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const destroyNode = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const duplicateNode = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${id}/duplicate`, {
    method: 'POST',
    ...headers(),
  });

const updateNode = (id: string, form: NodeForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const upgradeNode = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${id}/upgrade`, {
    method: 'POST',
    ...headers(),
  });

const batchDestroyNodes = (form: { ids: string[] }) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/batch_destroy`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const resetNode = (id: string, form: ResetNodeSocketForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${id}/reset`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const processNode = (id: string, form: ProcessNodeForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${id}/process`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

export default {
  createNode,
  duplicateNode,
  destroyNode,
  batchDestroyNodes,
  getNode,
  getNodes,
  resetNode,
  updateNode,
  upgradeNode,
  processNode,
};
