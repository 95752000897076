import styled from 'styled-components';
import { media } from '../../styles/media';

export const FormGroup = styled.div`
  display: block;
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.spacing.s};
  position: relative;

  ${media.md`
  `}
`;
