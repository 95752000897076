import styled, { css } from 'styled-components';
import { media } from '../../../styles/media';

interface Props {
  lead?: boolean;
}

export const P = styled.p<Props>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${({ theme }) => theme.color.darkGrey};
  margin: 0;

  ${media.md`
    font-size: 18px;
    line-height: 25px;
    letter-spacing: initial;
  `}

  ${({ lead }) =>
    lead &&
    css`
      line-height: 22px;
      letter-spacing: 0.2px;

      ${media.md`
            color: ${({ theme }) => theme.color.black};
            font-size: 24px;
            line-height: 35px;
            letter-spacing: initial;
          `}
    `}
`;
