const basic = {
  fontFamily: {
    primary: `'DM Sans', sans-serif`,
    secondary: `'Roboto', sans-serif`,
  },
  spacing: {
    xs: '4px',
    s: '8px',
    m: '16px',
    ml: '24px',
    l: '32px',
    xl: '64px',
  },
};

export const lightMode = {
  ...basic,
  color: {
    brandPrimary: '#423EBD',
    brandPrimary75: '#9791A5',
    brandPrimary40: '#C4BDD4',
    brandPrimary18: '#EFEFF9',
    brandSecondary: '#5756E3',
    brandSecondary75: '#8C8CE9',
    brandSecondary40: '#D8D8F6',
    brandSecondary18: '#F3F3FF',
    brandTertiary: '#567DE3',
    brandTertiary75: '#9FB3E0',
    brandTertiary40: '#E8EFFF',
    brandTertiary18: '#F1F4FA',
    success75: '#8DCBA0',
    success40: '#C2E3CC',
    success18: '#E4F2E8',
    danger75: '#F86F6F',
    danger40: '#FBB2B2',
    danger18: '#FDDCDC',
    warning: '#F6BE15',
    warning75: '#F8CE50',
    warning40: '#FBE5A1',
    warning18: '#FDF3D5',
    lightest: '#FFFFFF',
    backgroundGrey: '#FAFAFA',
    lighterGrey: '#EAEAEA',
    lightGrey: '#DDDDDD',
    grey: '#C3C3C3',
    darkGrey: '#979797',
    darkerGrey: '#404040',
    darkest: '#1A1A1A',
    black: '#000000',
    white: '#FFFFFF',
    shadow: 'rgba(0,0,0,0.08)',
    schedule: '#E5CA34',
    inputSelect: '#3C83ED',
    // new naming that matches figma
    primary1: '#F0F2FF',
    primary2: '#F0EDFC',
    primary3: '#DBDFFF',
    primary5: '#5756E3',
    primary6: '#423EBD',
    secondary4: '#9898B1',
    secondary6: '#404069',
    secondary7: '#262542',
    neutral4: '#F0F0F0',
    neutral5: '#D9D9D9',
    gray300: '#DDDDDD',
    success: '#52C41A',
    danger: '#FF4D4F',
    characterTitle: 'rgba(0, 0, 0, 0.85)',
    characterSecondary: 'rgba(0, 0, 0, 0.45)',
  },
};

export const darkMode = {
  ...basic,
  color: {
    ...lightMode.color,
    brandSecondary: '#4D08A5',
    brandSecondary18: '#7A46BC',
    brandSecondary40: '#B89CDB',
    brandSecondary75: '#DFD2EF',
    brandPrimary: '#7776FF',
    brandPrimary18: '#8181EA',
    brandPrimary40: '#BCBBF4',
    brandPrimary75: '#D5D5F8',
    brandTertiary: '#2C93DF',
    brandTertiary18: '#16C6F9',
    brandTertiary40: '#81DCF7',
    brandTertiary75: '#C9F3FF',
    success18: '#8DCBA0',
    success75: '#E4F2E8',
    danger18: '#F86F6F',
    danger75: '#FDDCDC',
    warning18: '#F8CE50',
    warning75: '#FDF3D5',
    lightest: '#1A1A1A',
    backgroundGrey: '#191919',
    darkerGrey: '#eaeaea',
    darkGrey: '#DDDDDD',
    lightGrey: '#686868',
    lighterGrey: '#404040',
    darkest: '#f8f8f8',
  },
};

// basic them for tests and other stuff
export const theme = { ...lightMode };
