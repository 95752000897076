import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as AntForm, Input } from 'antd';
import { TestAttribute } from '../../../types/ui/TestAttribute';
import { StyledAlert, StyledButton, FormItem } from './styled';
import { useAcceptInvite } from '../../../hooks/useAcceptInvite';
import { Invite } from '../../../types/api/entity/Invite';
import { usePasswordValidationRules } from '../../../hooks/usePasswordValidationRules';

type Props = Partial<Pick<Invite, 'id' | 'name' | 'email' | 'organization'>>;

export const Form: FC<Props> = ({ name, email, organization, id }) => {
  const { t } = useTranslation('register');
  const { error, isLoading, onSubmit } = useAcceptInvite();
  const passwordRules = usePasswordValidationRules();

  const onFinish = ({
    password,
    organizationName,
    ...rest
  }: Omit<Parameters<typeof onSubmit>[0]['user'], 'password_confirmation'> & {
    organizationName: string;
  }) =>
    onSubmit({
      user: { ...rest, password, password_confirmation: password },
      id: id!,
    });

  return (
    <AntForm
      layout="vertical"
      onFinish={onFinish}
      validateMessages={{ required: t('error:form.required') }}
      requiredMark={false}
      key={organization?.id}
    >
      {error && (
        <StyledAlert type="error" message={t(`error:${error}`)} showIcon />
      )}
      <FormItem
        name="organizationName"
        label={t('organizationName')}
        rules={[{ required: true }]}
        initialValue={organization?.name}
      >
        <Input size="large" disabled />
      </FormItem>
      <FormItem
        name="name"
        label={t('name')}
        rules={[{ required: true }]}
        initialValue={name}
        hasFeedback
      >
        <Input size="large" />
      </FormItem>
      <FormItem
        name="email"
        label={t('email')}
        rules={[
          { required: true },
          { type: 'email', message: t('error:form.email.invalid') },
        ]}
        initialValue={email}
        hasFeedback
      >
        <Input size="large" />
      </FormItem>
      <FormItem name="password" label={t('password')} rules={passwordRules}>
        <Input.Password size="large" />
      </FormItem>
      <StyledButton
        type="primary"
        htmlType="submit"
        loading={isLoading}
        data-test-id={TestAttribute.AcceptInviteButton}
        size="large"
        disabled={!organization}
      >
        {t('submitButton')}
      </StyledButton>
    </AntForm>
  );
};
