import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = boolean;

const [getSocketLoading, setSocketLoading] = createStore<State>(
  StoreId.SocketLoading,
  false,
);

export { getSocketLoading, setSocketLoading };

export const useSocketLoading = () => {
  const [loading] = useStore<State>(StoreId.SocketLoading);
  return loading;
};
