import { Typography } from 'antd';
import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 219px;
  height: 58px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 6px;
`;

export const StyledParagraph = styled(Typography.Paragraph)`
  font-weight: bold;
  font-size: 14px;
  padding: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.color.black};
`;

export const IconBackground = styled.div`
  background: #fafafa;
  line-height: 70px;
  border-radius: 6px 0px 0px 6px;
`;

export const Content = styled.div`
  margin: 0 4px;
  background: #fafbff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 6px 6px;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing.s} 0;
`;

export const Container = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
`;

export const SocketContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

interface SocketRowProps {
  isOutput?: boolean;
}

export const SocketRow = styled.div<SocketRowProps>`
  display: flex;
  flex-direction: row;
  margin: 0 -6px;
  height: 20px;
  padding: ${({ theme }) => theme.spacing.s} 0;
  justify-content: flex-start;
  ${({ isOutput }) =>
    isOutput &&
    css`
      align-self: flex-end;
    `}
`;

export const SocketTitle = styled.div`
  flex-grow: 1;
  padding: 0 ${({ theme }) => theme.spacing.s};
`;

interface SocketCircleProps {
  schema_id: string;
}

export const SocketCircle = styled.div<SocketCircleProps>`
  margin-top: 5px;
  height: 10px;
  width: 10px;
  background-color: ${({ theme, schema_id }) =>
    schema_id === 'trigger' ? theme.color.success : theme.color.brandSecondary};
  border-radius: 50%;
  flex-grow: 0;
`;
