import styled, { css } from 'styled-components';
import { hexToRGBA } from '../../util/hexToRgba';

export interface ContainerProps {
  /**
   * @default 'default'
   */
  type?: 'default' | 'danger' | 'warning' | 'success';
  absolute?: boolean;
  dark?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background-color: transparent;
  color: ${({ theme }) => theme.color.darkerGrey};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.l}
    ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.s};
  font-family: ${({ theme }) => theme.fontFamily.secondary};
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 1.25;
  text-align: left;
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  width: 100%;

  > svg {
    max-height: 22px;
    max-width: 22px;
    margin-left: ${({ theme }) => theme.spacing.xs};
    margin-right: ${({ theme }) => theme.spacing.s};
    flex-shrink: 0;
    align-self: center;
    justify-self: center;
  }

  li {
    list-style-type: none;
    margin-left: 5px;

    &:before {
      content: '-';
      display: inline-block;
      margin-right: 5px;
    }
  }

  div {
    flex-grow: 1;
    white-space: pre-line;
  }

  a {
    font-weight: bold;
    color: inherit;
  }

  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      bottom: 0;
      right: 0;
      width: auto;
      z-index: 20;
      box-sizing: border-box;
      width: 99.5vw;
    `};

  ${({ type }) => {
    switch (type) {
      case 'success':
        return css`
          border: 1px solid
            ${({ theme }) => hexToRGBA(theme.color.success40, 1)};
          background: ${({ theme }) => hexToRGBA(theme.color.success, 0.1)};
          svg {
            color: ${({ theme }) => theme.color.success};
          }
        `;
      case 'danger':
        return css`
          border: 1px solid ${({ theme }) => hexToRGBA(theme.color.danger40, 1)};
          background: ${({ theme }) => hexToRGBA(theme.color.danger, 0.1)};
          svg {
            color: ${({ theme }) => theme.color.danger};
          }
        `;
      case 'warning':
        return css`
          border: 1px solid
            ${({ theme }) => hexToRGBA(theme.color.warning40, 1)};
          background: ${({ theme }) => hexToRGBA(theme.color.warning40, 0.1)};
          svg {
            color: ${({ theme }) => theme.color.warning};
          }
        `;
    }
    return css`
      border: 1px solid
        ${({ theme }) => hexToRGBA(theme.color.brandTertiary, 1)};
      background: ${({ theme }) => hexToRGBA(theme.color.brandTertiary, 0.1)};
      svg {
        color: ${({ theme }) => theme.color.brandTertiary};
      }
    `;
  }}

  ${({ dark }) =>
    dark &&
    css`
      background-color: ${({ theme }) => hexToRGBA(theme.color.darkest, 0.8)};
      color: ${({ theme }) => theme.color.lightest};
      border: none;
      margin: 5px;
      width: auto;
      flex-grow: 0;
      max-width: 300px;
      left: 0;

      a {
        color: white !important;
        font-weight: bold;
        text-decoration: underline;
      }
    `};
`;
