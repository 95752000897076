import { FC, useEffect, useState } from 'react';
import { Form as AntForm, Input } from 'antd';
import { TestAttribute } from '../../../types/ui/TestAttribute';
import { useTranslation } from 'react-i18next';
import { loginFromForm, useAuth } from '../../../stores/useAuth';
import {
  FormItem,
  StyledButton,
  ForgotPasswordLink,
  StyledAlert,
} from './styled';
import { Route } from '../../../types/ui/Route';
import { Link } from 'react-router-dom';

export const Form: FC = () => {
  const [paramError, setParamError] = useState<null | undefined | string>(
    undefined,
  );
  // get error from url on social login fail
  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    setParamError(params.get('error_description'));
  }, []);

  const { error: authError, isLoading } = useAuth();
  const { t } = useTranslation('login');

  const error = paramError || authError;

  return (
    <AntForm
      layout="vertical"
      onFinish={loginFromForm}
      validateMessages={{ required: t('error:form.required') }}
      requiredMark={false}
    >
      {error && <StyledAlert type="error" message={error} showIcon />}
      <FormItem
        name="email"
        label={t('email')}
        rules={[
          { required: true },
          { type: 'email', message: t('error:form.email.invalid') },
        ]}
        hasFeedback
      >
        <Input size="large" />
      </FormItem>
      <FormItem
        name="password"
        label={t('password')}
        rules={[{ required: true }]}
      >
        <Input.Password size="large" />
      </FormItem>
      <Link to={Route.PasswordLess}>
        <ForgotPasswordLink type="link">
          {t('forgotPassword')}
        </ForgotPasswordLink>
      </Link>
      <StyledButton
        type="primary"
        htmlType="submit"
        loading={isLoading}
        data-test-id={TestAttribute.LoginButton}
        size="large"
      >
        {t('submitButton')}
      </StyledButton>
    </AntForm>
  );
};
