import { FC, useCallback, useEffect, useState } from 'react';
import { Spinner } from '../../../components/spinner/Spinner';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { emailConfirm } from '../../../api/auth/auth';
import { Route } from '../../../types/ui/Route';

export const EmailConfirm: FC = () => {
  const { t } = useTranslation('emailConfirm');
  const navigate = useNavigate();

  const [error, setErrror] = useState<string | undefined>(undefined);
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const email_confirmation_code = params.get('code') || '';

  const handleConfirm = useCallback(async () => {
    const response = await emailConfirm({ email_confirmation_code });
    if (response.ok) {
      navigate(Route.EmailConfirmSuccess);
    }

    const error = await response.json();
    setErrror(JSON.stringify(error));
  }, [email_confirmation_code, navigate]);

  useEffect(() => {
    handleConfirm();
  }, [handleConfirm]);

  if (error) {
    return <Result status="403" title="403" subTitle={t('errorMessage')} />;
  }

  return <Spinner />;
};
