import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = boolean;

const [, setSidebarCollapsed, useStore] = createStore<State>(
  StoreId.SidebarCollapsed,
  false,
);

export { setSidebarCollapsed };

export const useSidebarCollapsed = () => {
  const [collapsed] = useStore();
  return collapsed;
};
