import { useState } from 'react';
import { AcceptInviteForm } from '../types/api/auth/AcceptInviteForm';
import api from '../api/entity/invites';
import { humanizeError } from '../util/humanizeError';
import { setToken } from '../stores/useAuth';

export const useAcceptInvite = () => {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  const onSubmit = async ({ id, ...form }: AcceptInviteForm) => {
    setLoading(true);
    setSuccess(false);
    setError(undefined);
    try {
      const response = await api.postInviteSignup(id, form);
      const json = await response.json();
      setLoading(false);
      if (response.ok) {
        setToken(json);
        setSuccess(true);
      } else {
        setError(humanizeError(json));
      }
    } catch {
      setLoading(false);
      setError('generic');
    }
  };

  return { success, error, isLoading, onSubmit };
};
