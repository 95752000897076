import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

type AutomationsSettings = Record<string, AutomationSetting>;
interface AutomationSetting {
  readmeClosed: boolean;
}

export interface State {
  hasDarkMode: boolean;
  automations: AutomationsSettings;
}

const DEFAULT_STATE = { hasDarkMode: false, automations: {} };

export const getDefaultState = () => {
  try {
    const settings = localStorage.getItem(StoreId.Settings);
    if (!settings) {
      return DEFAULT_STATE;
    }
    return JSON.parse(settings);
  } catch {
    return DEFAULT_STATE;
  }
};

const { setState, useStore, getState } = createStore<State>(
  StoreId.Settings,
  getDefaultState(),
);

export const setDarkMode = (hasDarkMode: boolean) => {
  const state = getState();
  if (!state) return;

  try {
    localStorage.setItem(
      StoreId.Settings,
      JSON.stringify({ ...state, hasDarkMode }),
    );
  } catch {}

  setState((state) => ({ ...state, hasDarkMode }));
};

export const setAutomationReadmeClosed = (id: string) => {
  const state = getState();
  if (!state) return;
  const automations = { ...state.automations, [id]: { readmeClosed: true } };
  try {
    localStorage.setItem(
      StoreId.Settings,
      JSON.stringify({ ...state, automations }),
    );
  } catch {}
  setState({ ...state, automations });
};

export const useSettings = () => {
  const [state] = useStore();
  return state;
};
