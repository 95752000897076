import { config } from '../../config';
import { SocketUpdateForm } from '../../types/api/automation/SocketUpdateForm';
import { headers } from '../auth/headers';

const getSocket = (id: string, params?: Record<string, boolean | string>) => {
  let url = `${config.apiBaseUrl}/v1/automation/sockets/${id}`;

  const query = params
    ? `${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`
    : undefined;

  if (query) {
    url = `${url}?${query}`;
  }
  return fetch(url, {
    method: 'GET',
    ...headers(),
  });
};

const updateSocket = (id: string, form: SocketUpdateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/sockets/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const resetSocket = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/sockets/${id}/reset`, {
    method: 'POST',
    ...headers(),
  });

export default {
  getSocket,
  updateSocket,
  resetSocket,
};
