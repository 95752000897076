import { FC, MouseEvent, useState, useEffect } from 'react';
import { Container, CheckBoxLabel, Group, Description } from './styled';
import { ReactComponent as Check } from '../../assets/ui/check.svg';

interface Props {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  required?: boolean;
  error?: boolean;
  label?: string;
  onChange?: (checked: boolean) => any;
  invert?: boolean;
  description?: string;
}

export const Checkbox: FC<Props> = ({
  name,
  checked,
  disabled,
  onChange,
  required,
  error,
  label,
  invert,
  description,
}) => {
  const [selected, setSelected] = useState(!!checked);
  const toggle = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      return;
    }
    setSelected(!selected);
    if (onChange) {
      onChange(!selected);
    }
  };

  useEffect(() => {
    setSelected(!!checked);
  }, [checked]);

  return (
    <Group hasLabel={!!label} onClick={toggle}>
      <Container
        invert={invert}
        disabled={disabled}
        checked={selected}
        error={error}
      >
        <input
          type="checkbox"
          onChange={() => {}}
          name={name}
          checked={selected}
          disabled={disabled}
        />
        <Check />
      </Container>
      {label && (
        <CheckBoxLabel checked={selected} htmlFor={name} disabled={disabled}>
          {label}
          {description && <Description>{description} </Description>}
        </CheckBoxLabel>
      )}
    </Group>
  );
};
