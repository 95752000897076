import { Component, PropsWithChildren } from 'react';
import { captureException } from '@sentry/browser';
import { ErrorMessage } from './ErrorMessage';

interface State {
  error?: boolean;
}

export class ErrorBoundary extends Component<PropsWithChildren, State> {
  public state: State = {};

  static getDerivedStateFromError(error: Error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    captureException(error);

    return { error: true };
  }

  componentDidCatch(error: Error) {
    captureException(error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorMessage />;
    }
    const { children } = this.props;
    return children;
  }
}
