import { Navigate, useLocation } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';
import { Route as RouteType } from '../types/ui/Route';
import { useAuth } from '../stores/useAuth';
import { Spinner } from '../components/spinner/Spinner';
import { isPathAllowed } from '../util/auth/isPathAllowed';

export const PrivateRoute: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const { isInitialized, isAuthenticated, allowedRoutes, token } = useAuth();
  if (!isInitialized) {
    return <Spinner />;
  }
  if (!isAuthenticated) {
    const to = `${RouteType.Login}?redirect_url=${encodeURIComponent(
      window.location.href,
    )}`;
    return <Navigate to={to} />;
  }

  const isOnboarding = token?.organization.details.in_onboarding === true;
  const isStudioPlan = token?.organization.plan === 'studio';
  if (
    isAuthenticated &&
    isStudioPlan &&
    isOnboarding &&
    !(location.pathname === RouteType.Onboarding)
  ) {
    return <Navigate to={RouteType.Onboarding} />;
  }

  if (location.pathname === RouteType.Onboarding && !isOnboarding) {
    return <Navigate to={RouteType.Reports} />;
  }

  if (!isPathAllowed(allowedRoutes, currentPath)) {
    return <Navigate to={allowedRoutes[0]} />;
  }

  return <>{children}</>;
};
