import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: 65vh;
`;
export const TreeContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const Info = styled.div`
  flex-grow: 0;
  width: 300px;
  border-left: 1px solid ${({ theme }) => theme.color.neutral4};
  padding: 0 ${({ theme }) => theme.spacing.s};
  overflow-y: auto;
`;

export const Selection = styled.div`
  flex-grow: 1;
  padding: 0 ${({ theme }) => theme.spacing.s};
`;
