import { config } from '../../../config';
import { headers } from '../../auth/headers';

const getErrorRules = (node_id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${node_id}/error_rules`, {
    method: 'GET',
    ...headers(),
  });

const createErrorRule = (
  node_id: string,
  form: { expression: string; error_type: string },
) =>
  fetch(`${config.apiBaseUrl}/v1/automation/nodes/${node_id}/error_rules`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const updateErrorRule = (
  node_id: string,
  id: string,
  form: { expression: string; error_type: string },
) =>
  fetch(
    `${config.apiBaseUrl}/v1/automation/nodes/${node_id}/error_rules/${id}`,
    {
      method: 'PUT',
      body: JSON.stringify(form),
      ...headers(),
    },
  );

const destroyErrorRule = (node_id: string, id: string) =>
  fetch(
    `${config.apiBaseUrl}/v1/automation/nodes/${node_id}/error_rules/${id}`,
    {
      method: 'DELETE',
      ...headers(),
    },
  );

export default {
  getErrorRules,
  createErrorRule,
  updateErrorRule,
  destroyErrorRule,
};
