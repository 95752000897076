import { PropsWithChildren, memo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { ConfigProvider } from 'antd';
import { theme } from './theme';

const DocumentStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }
  #root {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  body {
    padding: 0;
    width: 100%;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    text-decoration: none;
  }
  .ant-tooltip-content a {
    color: ${({ theme }) => theme.color.lightest};
  }
  /* This seems to be a bug on the antd component so we fix it */
  .ant-picker-time-panel-column:after {
    height: 0;
  }

  /* AntD dropdown can be too high increasing the page height so we limit max-height */
  .ant-dropdown > .ant-dropdown-menu {
    max-height: 70vh;
    overflow: auto;
  }

  /* AntD static notification calls arent styled using our theme so we style it here */
  .ant-notification-notice-wrapper.ant-notification-notice-wrapper {
    &,
    * {
      font-family: ${theme.fontFamily.secondary};
    }
    &,
    button {
      border-radius: 2px;
    }
    .ant-btn-primary {
      background: ${theme.color.primary6};
      :hover,
      :active {
        background: #2A2A96;
      }
    }
    .ant-btn-link {
      color: ${theme.color.primary6};
      :hover,
      :active {
        color: ${theme.color.primary6}BB;
      }
      :focus-visible {
        outline-color: ${theme.color.primary6}77;
      }
    }
  }

  #webpack-dev-server-client-overlay {
    display: none;
  }
`;

export const GlobalStyle = memo(({ children }: PropsWithChildren) => (
  <>
    <Normalize />
    <DocumentStyle />
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.color.primary6,
          colorLink: theme.color.primary6,
          borderRadius: 2,
          fontFamily: theme.fontFamily.secondary,
        },
      }}
    >
      {children}
    </ConfigProvider>
  </>
));
