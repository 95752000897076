import { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  CollapseButton,
  StyledSider,
  LogoContainer,
  HeaderContainer,
  StyledMenu,
} from './styled';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ApartmentOutlined,
  ApiOutlined,
  DatabaseOutlined,
  KeyOutlined,
  SettingOutlined,
  LineChartOutlined,
  ForkOutlined,
  NumberOutlined,
} from '@ant-design/icons';
import { Route } from '../../../types/ui/Route';
import { ReactComponent as Logo } from '../../../assets/logo/logo-icon.svg';
import { useAuth } from '../../../stores/useAuth';
import {
  useSidebarCollapsed,
  setSidebarCollapsed,
} from '../../../stores/useSidebarCollapsed';
import { OrganizationSwitcher } from './OrganizationSwitcher';
import { MenuProps } from 'antd';
import { isPathAllowed } from '../../../util/auth/isPathAllowed';
import { isStagingDomain } from '../../../util/checkDomain';

export const DashboardNavigation: FC = () => {
  const { t } = useTranslation('dashboardNavigation');
  const { isGod, token, allowedRoutes } = useAuth();
  const { pathname } = useLocation();
  const isStaging = isStagingDomain();
  const collapsed = useSidebarCollapsed();
  const ownOrgSelected =
    !token?.home_organization ||
    token?.home_organization.id === token?.organization.id;

  const reportsEnabled = token?.organization.details.reports_enabled;
  const rulesEnabled = token?.organization.details.rules_enabled;
  const isGuest = token?.user.role === 'guest';
  const isStudioPlan = token?.organization.plan === 'studio';

  const selectedKeys = [
    pathname,
    ...(pathname.startsWith(Route.Settings) ? [Route.Settings] : []),
    ...(pathname.startsWith(Route.Integrations) ? [Route.Integrations] : []),
  ];

  const toggleCollapsed = () => setSidebarCollapsed(!collapsed);

  const findOpenKeys = () => {
    if (pathname.startsWith(Route.Admin)) {
      return ['admin'];
    }
    if (pathname.startsWith(Route.AdPlatform)) {
      return ['ad-platform'];
    }
    if (pathname.startsWith(Route.RuleEngine)) {
      return ['rule-engine'];
    }
    return [];
  };

  const [openKeys, setOpenKeys] = useState(findOpenKeys());

  useEffect(() => {
    const findOpenKeys = () => {
      if (pathname.startsWith(Route.Admin)) {
        return ['admin'];
      }
      if (pathname.startsWith(Route.AdPlatform)) {
        return ['ad-platform'];
      }
      if (pathname.startsWith(Route.RuleEngine)) {
        return ['rule-engine'];
      }
      return [];
    };
    setOpenKeys(findOpenKeys());
  }, [pathname]);

  let items: MenuProps['items'] = [
    {
      key: Route.Automations,
      icon: <ApartmentOutlined />,
      label: <Link to={Route.Automations}>{t('automations')}</Link>,
    },
    {
      key: Route.Integrations,
      icon: <ApiOutlined />,
      label: <Link to={Route.Integrations}>{t('integrations')}</Link>,
    },
  ];

  if (!isStudioPlan) {
    items.push({
      key: Route.StorageDataStores,
      icon: <DatabaseOutlined />,
      label: <Link to={Route.StorageDataStores}>{t('dataStores')}</Link>,
    });
  }

  if (isStaging || isGod) {
    items.push({
      key: 'ad-platform',
      icon: <NumberOutlined />,
      label: t('adPlatform'),
      onTitleClick: () =>
        setOpenKeys((keys) => (keys.length > 0 ? [] : ['ad-platform'])),
      children: [
        {
          key: Route.AdPlatformCustomMetrics,
          label: (
            <Link to={Route.AdPlatformCustomMetrics}>{t('customMetrics')}</Link>
          ),
        },
        {
          key: Route.AdPlatformLaunch,
          label: <Link to={Route.AdPlatformLaunch}>{t('launch')}</Link>,
        },
      ],
    });
  }

  if (isStaging || reportsEnabled) {
    items.push({
      key: Route.Reports,
      icon: <LineChartOutlined />,
      label: <Link to={Route.Reports}>{t('reports')}</Link>,
    });
  }

  if (isStaging || rulesEnabled) {
    items.push({
      key: 'rule-engine',
      icon: <ForkOutlined />,
      label: t('ruleEngine'),
      onTitleClick: () =>
        setOpenKeys((keys) => (keys.length > 0 ? [] : ['rule-engine'])),
      children: [
        {
          key: Route.RuleEngineRules,
          label: <Link to={Route.RuleEngineRules}>{t('rules')}</Link>,
        },
        {
          key: Route.RuleEngineActivities,
          label: <Link to={Route.RuleEngineActivities}>{t('activities')}</Link>,
        },
      ],
    });
  }

  if (isGod) {
    items.push({
      key: 'admin',
      icon: <KeyOutlined />,
      label: t('admin'),
      onTitleClick: () =>
        setOpenKeys((keys) => (keys.length > 0 ? [] : ['admin'])),
      children: [
        {
          key: Route.AdminWorkers,
          label: <Link to={Route.AdminWorkers}>{t('workers')}</Link>,
        },
        {
          key: Route.AdminOrganizations,
          label: (
            <Link to={Route.AdminOrganizations}>{t('organizations')}</Link>
          ),
        },
        {
          key: Route.AdminAutomations,
          label: <Link to={Route.AdminAutomations}>{t('automations')}</Link>,
        },
        {
          key: Route.AdminAutomationIssues,
          label: (
            <Link to={Route.AdminAutomationIssues}>
              {t('automationIssues')}
            </Link>
          ),
        },
        {
          key: Route.AdminNodes,
          label: <Link to={Route.AdminNodes}>{t('nodes')}</Link>,
        },
        {
          key: Route.AdminInvites,
          label: <Link to={Route.AdminInvites}>{t('invites')}</Link>,
        },
        {
          key: Route.AdminReports,
          label: <Link to={Route.AdminReports}>{t('reports')}</Link>,
        },
        {
          key: Route.AdminRules,
          label: <Link to={Route.AdminRules}>{t('rules')}</Link>,
        },
        {
          key: Route.AdminUsers,
          label: <Link to={Route.AdminUsers}>{t('users')}</Link>,
        },
        {
          key: Route.AdminLogs,
          label: <Link to={Route.AdminLogs}>{t('logs')}</Link>,
        },
        {
          key: Route.AdminRuns,
          label: <Link to={Route.AdminRuns}>{t('runs')}</Link>,
        },
        {
          key: Route.AdminJobs,
          label: <Link to={Route.AdminJobs}>{t('jobs')}</Link>,
        },
      ],
    });
  }

  items.push({
    key: Route.Settings,
    icon: <SettingOutlined />,
    label: <Link to={Route.Settings}>{t('settings')}</Link>,
    style: { marginTop: 'auto' },
  });

  if (isGuest) {
    items = items.filter(
      (item) =>
        item && item.key && isPathAllowed(allowedRoutes, item.key as string),
    );
  }

  if (!token) {
    return null;
  }

  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      collapsedWidth={48}
      trigger={null}
    >
      <HeaderContainer highlighted={!ownOrgSelected}>
        <Link to={Route.Settings}>
          <LogoContainer inverted={!ownOrgSelected}>
            <Logo />
          </LogoContainer>
        </Link>
        <OrganizationSwitcher />
      </HeaderContainer>
      <StyledMenu
        selectedKeys={selectedKeys}
        mode="inline"
        key={`${pathname.startsWith(Route.Admin)}`}
        openKeys={openKeys}
        items={items}
      />
      <CollapseButton onClick={toggleCollapsed}>
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </CollapseButton>
    </StyledSider>
  );
};
