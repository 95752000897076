import { FC, PropsWithChildren } from 'react';
import { Container, ContainerProps } from './styled';
import { ReactComponent as SuccessIcon } from '../../assets/icons/checkmark.svg';
import { ReactComponent as DangerIcon } from '../../assets/icons/cross.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

type Props = PropsWithChildren &
  ContainerProps & {
    withIcon?: boolean;
    absolute?: boolean;
  };

const getIcon = (type: ContainerProps['type']) => {
  switch (type) {
    case 'danger':
      return <DangerIcon />;
    case 'warning':
      return <WarningIcon />;
    case 'success':
      return <SuccessIcon />;
  }
  return <InfoIcon />;
};

export const FeedbackMessage: FC<Props> = ({
  type,
  withIcon = true,
  children,
  absolute,
  ...rest
}) => (
  <Container type={type} {...rest} absolute={absolute}>
    {withIcon && getIcon(type)}
    <div>{children}</div>
  </Container>
);
