import { useEffect } from 'react';
import api from '../api/automation/automations';
import { Automation } from '../types/api/automation/Automation';
import { setNotification } from './useUiNotifications';
import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';
import { updateAutomationRunDetails } from './useAutomations';
import { Run, StartRunForm } from '../types/api/automation/Run';
import { AutomationUpdateForm } from '../types/api/automation/AutomationUpdateForm';
import { Route } from '../types/ui/Route';

export type State = {
  automation?: Automation;
  isLoading?: boolean;
};

const { getState, setState, useStore } = createStore<State>(
  StoreId.Automation,
  {},
);

export const abortAutomation = async (id: string) => {
  const { automation } = getState();
  if (!automation) return;
  try {
    const response = await api.abortAutomation(id);
    if (response.ok) {
      const run: Run = await response.json();
      setAutomation(updateAutomationRunDetails(automation, run));
      return true;
    }
  } catch {}

  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured on aborting of automation.',
  });
  return false;
};

export const runAutomation = async (id: string, form?: StartRunForm) => {
  const { automation } = getState();
  if (!automation) return;
  try {
    const response = await api.runAutomation(id, form);
    if (response.ok) {
      const run: Run = await response.json();
      setAutomation(updateAutomationRunDetails(automation, run));
      return true;
    }
  } catch {}

  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occurred on starting of automation.',
  });
  return false;
};

export const fetchAutomation = async (id: string) => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getAutomation(id);
    if (response.ok) {
      setState({ automation: await response.json() });
      return true;
    }
    if (response.status === 404) {
      window.location.href = Route.NotFound;
    }
  } catch {}

  setState(({ automation }) => ({ automation }));
  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured on fetching automation.',
  });
  return false;
};

export const setAutomation = (automation: Automation) =>
  getState().automation?.id === automation.id && setState({ automation });

export const updateAutomation = async (
  id: string,
  form: AutomationUpdateForm,
) => {
  try {
    const response = await api.updateAutomation(id, form);
    if (response.ok) {
      const automation: Automation = await response.json();
      setAutomation(automation);
      return true;
    }
  } catch {}
  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured on updating the automation.',
  });
  return false;
};

export const useAutomation = (id?: string) => {
  const [state] = useStore();

  useEffect(() => {
    if (!id) {
      return;
    }
    const { automation, isLoading } = getState();

    if (!automation && !isLoading) {
      fetchAutomation(id);
    }
  }, [id]);

  return state;
};
