import { useEffect } from 'react';
import api from '../api/automation/automations';
import { useAuth } from './useAuth';
import { Log } from '../types/api/automation/Log';
import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';
import { setNotification } from './useUiNotifications';
import { getDiff } from 'recursive-diff';
import { useAutomationId, getAutomationId } from './useAutomationId';
import { sortByKeyAsc } from '../util/sort';

export interface State {
  automation_id?: string;
  automationLogs?: Log[];
  isLoading?: boolean;
}

const [getState, setStoreState, useStore] = createStore<State>(
  StoreId.AutomationLogs,
  {},
);

const setState: typeof setStoreState = (state) => {
  const currentState = getState();
  const newState = typeof state === 'function' ? state(currentState) : state;

  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(
      `[${StoreId.AutomationLogs}] state change`,
      getDiff(currentState, newState),
    );
  }

  return setStoreState(newState);
};

export const fetchAutomationLogs = async (id: string) => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getAutomationLogs(id);
    if (response.ok) {
      const automationLogs = await response.json();
      setState({ automation_id: id, automationLogs: automationLogs || [] });
      return true;
    }
  } catch {}
  setNotification({
    type: 'error',
    entity: 'log',
    description: 'An error occured on fetching logs.',
  });
  setState({});
  return false;
};

export const updateAutomationLogs = (log: Log) =>
  log.automation_id === getAutomationId() &&
  setState((state) => ({
    ...state,
    automationLogs: [...(state.automationLogs || []), log].sort(
      sortByKeyAsc('created_at'),
    ),
  }));

export const useAutomationLogs = (): State => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore();
  const { automationLogs = [], isLoading } = state;
  const automationId = useAutomationId();

  useEffect(() => {
    const { automationLogs, automation_id, isLoading } = getState();

    if (isAuthenticated) {
      if (!isLoading && automationId && automationId !== automation_id) {
        fetchAutomationLogs(automationId);
      }
    } else if (automationLogs) {
      setState({});
    }
  }, [isAuthenticated, automationId]);

  return { automationLogs, isLoading };
};
