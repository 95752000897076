import { FC } from 'react';
import { theme } from '../../../styles/theme';
import { ReactComponent as CoreIcon } from '../../../assets/icons/gear-outline.svg';
import { ProviderIcon } from '../../v2/provider/ProviderIcon';
import { PROVIDER_IDS } from '../../../types/api/integration/ProviderId';

interface Settings {
  x?: number;
  y?: number;
  height: number;
  width: number;
}

interface Props {
  category?: string | null;
  settings: Settings;
}

export const NodeIcon: FC<Props> = ({ category, settings }) => {
  const provider_id = PROVIDER_IDS.find((id) => id === category);
  if (provider_id) {
    return <ProviderIcon id={provider_id} {...settings} />;
  }

  return <CoreIcon {...settings} color={theme.color.darkerGrey} />;
};
