import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = boolean;

const [getWidgetLoading, setWidgetLoading] = createStore<State>(
  StoreId.WidgetLoading,
  false,
);

export { getWidgetLoading, setWidgetLoading };

export const useWidgetLoading = () => {
  const [loading] = useStore<State>(StoreId.WidgetLoading);
  return loading;
};
