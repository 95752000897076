import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = string | undefined;

const { setState, useStore } = createStore<State>(
  StoreId.NewAutomationTags,
  undefined,
);

export const setAutomationId = setState;
export const useNewAutomationTags = useStore;
