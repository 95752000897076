import { config } from '../../config';
import { headers } from '../auth/headers';

const getReports = () =>
  fetch(`${config.apiBaseUrl}/v1/report/reports`, {
    method: 'GET',
    ...headers(),
  });

const getReport = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${id}`, {
    method: 'GET',
    ...headers(),
  });

const duplicateReport = (id: string, form: any) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${id}/duplicate`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const createReport = (form: any) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const updateReport = (id: string, form: any) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const deleteReport = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const createReportView = (reportId: string, form: any) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${reportId}/views`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const duplicateReportView = (reportId: string, id: string) =>
  fetch(
    `${config.apiBaseUrl}/v1/report/reports/${reportId}/views/${id}/duplicate`,
    {
      method: 'POST',
      ...headers(),
    },
  );

const positionReportView = (reportId: string, id: string, form: any) =>
  fetch(
    `${config.apiBaseUrl}/v1/report/reports/${reportId}/views/${id}/position`,
    {
      method: 'PUT',
      body: JSON.stringify({
        ...form,
      }),
      ...headers(),
    },
  );

const deleteReportView = (reportId: string, id: string) =>
  fetch(`${config.apiBaseUrl}/v1/report/reports/${reportId}/views/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

export default {
  getReports,
  getReport,
  updateReport,
  deleteReport,
  createReport,
  duplicateReport,
  createReportView,
  duplicateReportView,
  positionReportView,
  deleteReportView,
};
