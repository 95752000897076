import { FC, PropsWithChildren, ReactNode } from 'react';
import { Illustration, Page, Container, Title } from './styled';
import { ReactComponent as Logo } from '../../assets/logo/logo-icon.svg';

type Props = PropsWithChildren & {
  illustration: ReactNode;
  title: string;
};

export const IllustratedPage: FC<Props> = ({
  illustration,
  title,
  children,
}) => (
  <Page>
    <Illustration>{illustration}</Illustration>
    <Container>
      <div>
        <Logo />
        <Title level={1}>{title}</Title>
        {children}
      </div>
    </Container>
  </Page>
);
