import { config } from '../../config';
import { headers } from '../auth/headers';

const getProcessors = () =>
  fetch(`${config.apiBaseUrl}/v1/processors`, {
    method: 'GET',
    ...headers(),
  });

export default { getProcessors };
