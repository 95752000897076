import { FC, lazy, memo } from 'react';
import { Route, Routes as Switch, Navigate } from 'react-router-dom';
import {
  Login,
  AcceptInvite,
  PasswordLess,
  MagicLogin,
  EmailConfirm,
  PasswordReset,
  Register,
} from './routes/auth';
import { Route as RouteType } from './types/ui/Route';
import { PublicRoute } from './routes/PublicRoute';
import { PrivateRoute } from './routes/PrivateRoute';
import { AdminRoute } from './routes/AdminRoute';
import { AsyncComponent } from './components/asyncComponent/AsyncComponent';

const Home = AsyncComponent(lazy(() => import('./routes/home/Home')));
const NotFound = AsyncComponent(lazy(() => import('./routes/404/NotFound')));
const Automations = AsyncComponent(
  lazy(() => import('./routes/automations/Automations')),
);
const Settings = AsyncComponent(
  lazy(() => import('./routes/settings/Settings')),
);
const Notifications = AsyncComponent(
  lazy(() => import('./routes/notifications/Notifications')),
);
const Admin = AsyncComponent(lazy(() => import('./routes/admin/Admin')));
const Provider = AsyncComponent(
  lazy(() => import('./routes/integrations/providers/provider/Provider')),
);
const Credential = AsyncComponent(
  lazy(() => import('./routes/integrations/credentials/Credential')),
);
const ShopifyAuthorize = AsyncComponent(
  lazy(
    () => import('./routes/integrations/providers/provider/shopify/Authorize'),
  ),
);
const RuleEngineRuleActivity = AsyncComponent(
  lazy(() => import('./routes/ruleEngine/rule/activity/Activity')),
);
const RuleEngineRule = AsyncComponent(
  lazy(() => import('./routes/ruleEngine/rule/Rule')),
);
const RuleEngineRules = AsyncComponent(
  lazy(() => import('./routes/ruleEngine/rules/Rules')),
);
const RuleEngineActivities = AsyncComponent(
  lazy(() => import('./routes/ruleEngine/activities/Activities')),
);

const Report = AsyncComponent(lazy(() => import('./routes/report/Report')));
const ReportSettings = AsyncComponent(
  lazy(() => import('./routes/report/settings/Settings')),
);
const Reports = AsyncComponent(lazy(() => import('./routes/reports/Reports')));
const Providers = AsyncComponent(
  lazy(() => import('./routes/integrations/providers/Providers')),
);

const CustomMetrics = AsyncComponent(
  lazy(() => import('./routes/adPlatform/customMetrics/CustomMetrics')),
);

const Launch = AsyncComponent(
  lazy(() => import('./routes/adPlatform/launch/Launch')),
);

const AutomationBuilder = AsyncComponent(
  lazy(() => import('./routes/automation/AutomationBuilder')),
);
const AutomationWidgets = AsyncComponent(
  lazy(() => import('./routes/automation/AutomationWidgets')),
);
const DataStores = AsyncComponent(
  lazy(() => import('./routes/storage/DataStores')),
);
const Dashboard = AsyncComponent(
  lazy(() => import('./routes/dashboard/Dashboard')),
);
const Onboarding = AsyncComponent(
  lazy(() => import('./routes/onboarding/Onboarding')),
);
const PasswordLessSuccess = AsyncComponent(
  lazy(() => import('./routes/auth/passwordLessSuccess/PasswordLessSuccess')),
);
const EmailConfirmSuccess = AsyncComponent(
  lazy(() => import('./routes/auth/emailConfirmSuccess/EmailConfirmSuccess')),
);

const PasswordResetSuccess = AsyncComponent(
  lazy(() => import('./routes/auth/passwordResetSuccess/PasswordResetSuccess')),
);

const publicRoute = (Element: FC) => (
  <PublicRoute>
    <Element />
  </PublicRoute>
);
const privateRoute = (Element: FC) => (
  <PrivateRoute>
    <Element />
  </PrivateRoute>
);

export const Routes = memo(() => (
  <Switch>
    <Route path="*" element={<Navigate to={RouteType.NotFound} />} />
    <Route path={RouteType.Root} element={<Home />} />
    <Route path={RouteType.NotFound} element={<NotFound />} />
    <Route path={RouteType.AcceptInvite} element={publicRoute(AcceptInvite)} />
    <Route path={RouteType.MagicLogin} element={publicRoute(MagicLogin)} />
    <Route
      path={RouteType.EmailConfirmSuccess}
      element={<EmailConfirmSuccess />}
    />
    <Route path={RouteType.EmailConfirm} element={<EmailConfirm />} />
    <Route
      path={RouteType.PasswordLessSuccess}
      element={publicRoute(PasswordLessSuccess)}
    />
    <Route path={RouteType.PasswordLess} element={publicRoute(PasswordLess)} />
    <Route path={RouteType.Login} element={publicRoute(Login)} />
    <Route path={RouteType.Register} element={publicRoute(Register)} />
    <Route
      path={RouteType.AdminMatch}
      element={
        <AdminRoute>
          <Admin />
        </AdminRoute>
      }
    />
    <Route path={RouteType.Onboarding} element={privateRoute(Onboarding)} />
    <Route path={RouteType.Dashboard} element={privateRoute(Dashboard)} />
    <Route path={RouteType.Report} element={privateRoute(Report)} />
    <Route
      path={RouteType.ReportSettings}
      element={privateRoute(ReportSettings)}
    />

    <Route path={RouteType.Reports} element={privateRoute(Reports)} />
    <Route
      path={RouteType.RuleEngineActivities}
      element={privateRoute(RuleEngineActivities)}
    />
    <Route
      path={RouteType.RuleEngineRule}
      element={privateRoute(RuleEngineRule)}
    />
    <Route
      path={RouteType.RuleEngineRuleActivity}
      element={privateRoute(RuleEngineRuleActivity)}
    />
    <Route
      path={RouteType.RuleEngineRules}
      element={privateRoute(RuleEngineRules)}
    />
    <Route
      path={RouteType.StorageDataStores}
      element={privateRoute(DataStores)}
    />
    <Route
      path={RouteType.AdPlatformCustomMetrics}
      element={privateRoute(CustomMetrics)}
    />
    <Route path={RouteType.AdPlatformLaunch} element={privateRoute(Launch)} />
    <Route
      path={RouteType.AutomationBuilder}
      element={privateRoute(AutomationBuilder)}
    />
    <Route
      path={RouteType.Automation}
      element={privateRoute(AutomationWidgets)}
    />
    <Route
      path={RouteType.AutomationFolder}
      element={privateRoute(Automations)}
    />
    <Route path={RouteType.Automations} element={privateRoute(Automations)} />
    <Route
      path={RouteType.ShopifyAuthorize}
      element={privateRoute(ShopifyAuthorize)}
    />
    <Route
      path={RouteType.CredentialsMatch}
      element={privateRoute(Credential)}
    />
    <Route
      path={RouteType.IntegrationsMatch}
      element={privateRoute(Provider)}
    />
    <Route path={RouteType.Integrations} element={privateRoute(Providers)} />
    <Route path={`${RouteType.Settings}/*`} element={privateRoute(Settings)} />
    <Route
      path={RouteType.Notifications}
      element={privateRoute(Notifications)}
    />
    <Route
      path={RouteType.PasswordResetSuccess}
      element={privateRoute(PasswordResetSuccess)}
    />
    <Route
      path={RouteType.PasswordReset}
      element={privateRoute(PasswordReset)}
    />
  </Switch>
));
