import { useEffect } from 'react';
import api from '../../api/report/reports';
import { useAuth } from '../useAuth';
import { Report } from '../../types/api/report/Report';
import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../../types/ui/StoreId';
import { View, ViewDetail } from '../../types/api/report/View';

export type State = {
  reports?: Report[];
  isLoading?: boolean;
};

const { getState, setState } = createStore<State>(StoreId.Reports, {});

export const addView = (reportId: string, view: View) => {
  setState((state) => ({
    ...state,
    reports: state.reports?.map((item) =>
      item.id === reportId
        ? {
            ...item,
            views: appendView(item.views, view),
          }
        : item,
    ),
  }));
};

const appendView = (views: View[], newView: View): View[] => {
  const list: View[] = [];
  let added = false;

  views.forEach((item, index) => {
    if (newView.position === item.position && !added) {
      // Insert the new view at its designated position
      list.push({ ...newView });
      // Push the current item with an incremented position
      list.push({ ...item, position: item.position + 1 });
      added = true; // Mark that new view is added
    } else if (added) {
      // Increment position of all subsequent items
      list.push({ ...item, position: item.position + 1 });
    } else {
      // Keep adding items as they are until we find the position for new view
      list.push(item);
    }
  });

  // If newView's position is beyond the last item's position and hasn't been added yet
  if (!added) {
    list.push({
      ...newView,
      position:
        newView.position <= views.length ? newView.position : views.length + 1,
    });
  }

  return list;
};

export const updatePositons = (reportId: string, views: View[]) => {
  setState((state) => ({
    ...state,
    reports: state.reports?.map((item) =>
      item.id === reportId ? { ...item, views } : item,
    ),
  }));
};

export const updateView = (reportId: string, view: View | ViewDetail) => {
  setState((state) => ({
    ...state,
    reports: state.reports?.map((item) =>
      item.id === reportId
        ? {
            ...item,
            views: item.views.map((vi) =>
              vi.id !== view.id ? vi : { ...vi, ...view },
            ),
          }
        : item,
    ),
  }));
};

export const removeView = (reportId: string, id: string) => {
  setState((state) => ({
    ...state,
    reports: state.reports?.map((item) =>
      item.id === reportId
        ? { ...item, views: item.views.filter((vi) => vi.id !== id) }
        : item,
    ),
  }));
};

export const fetchReports = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getReports();
    if (response.ok) {
      setState({ reports: await response.json() });
      return true;
    }
  } catch {}

  setState(({ reports }) => ({ reports }));
  return false;
};

export const setReport = (data: Report) => {
  setState(({ reports }) => ({
    reports: (reports || []).map((report) =>
      report.id === data.id ? data : report,
    ),
  }));
};

export const updateReport = async (id: string, form: any) => {
  try {
    const response = await api.updateReport(id, form);
    if (response.ok) {
      const creativeReport: Report = await response.json();
      setState((state) => ({
        ...state,
        reports: state.reports?.map((item) =>
          item.id === id ? creativeReport : item,
        ),
      }));
      return true;
    }
  } catch {}
  return false;
};

export const createReport = async (form: any) => {
  try {
    const response = await api.createReport(form);
    if (response.ok) {
      const creativeReport: Report = await response.json();
      setState((state) => ({
        ...state,
        reports: [creativeReport, ...(state.reports || [])],
      }));
      return creativeReport.id;
    }
  } catch {}

  return undefined;
};

export const duplicateReport = async (id: string, form: any) => {
  try {
    const response = await api.duplicateReport(id, form);
    if (response.ok) {
      const creativeReport: Report = await response.json();
      setState((state) => ({
        ...state,
        reports: [creativeReport, ...(state.reports || [])],
      }));
      return creativeReport.id;
    }
  } catch {}

  return false;
};

export const deleteReport = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.deleteReport(id);
    if (response.ok) {
      setState((state) => ({
        ...state,
        reports: state.reports?.filter((f) => f.id !== id),
        isLoading: false,
      }));
      return true;
    }
  } catch {}
  setState((state) => ({ ...state, isLoading: false }));
  return false;
};

export const useReports = () => {
  const { isAuthenticated } = useAuth();

  const [state] = useStore<State>(StoreId.Reports);
  const { reports, isLoading } = state;

  useEffect(() => {
    const { reports, isLoading } = getState();
    if (isAuthenticated) {
      if (!reports && !isLoading) {
        fetchReports();
      }
    } else if (reports) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    reports,
    isLoading,
  };
};
