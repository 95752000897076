import styled, { css } from 'styled-components';
import { touchSupport } from '../../util/touchSupport';
import { hexToRGBA } from '../../util/hexToRgba';

export interface ButtonProps {
  fullWidth?: boolean;
  disabled?: boolean;
  empty?: boolean;
  primary?: boolean;
  success?: boolean;
  warning?: boolean;
  small?: boolean;
  xsmall?: boolean;
  confirmed?: boolean;
  emptyDanger?: boolean;
  outline?: boolean;
  hasIcon?: boolean;
}

export const Button = styled.button<ButtonProps>`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  height: 56px;
  border: none;
  border-radius: 2px;
  color: ${({ theme }) => theme.color.lightest};
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.brandPrimary};
  padding: 0 ${({ theme }) => theme.spacing.m};
  font-weight: 600;
  font-size: 18px;

  ${!touchSupport &&
  css`
    &:hover {
      opacity: 0.8;
    }
  `}

  ${({ fullWidth }) =>
    fullWidth
      ? css`
          width: 100%;
        `
      : css`
          min-width: 158px;
        `}
        
${({ small }) =>
    small &&
    css`
      height: 42px;

      svg {
        height: 22px;
        width: 22px;
      }
    `}

${({ xsmall }) =>
    xsmall &&
    css`
      height: 32px;
      font-family: ${({ theme }) => theme.fontFamily.secondary};
      font-size: 16px;
      padding: 0 ${({ theme }) => theme.spacing.s};
      min-width: auto;

      svg {
        height: 18px;
        width: 18px;
      }
    `}


  ${({ empty }) =>
    empty &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.grey};
      color: ${({ theme }) => theme.color.darkerGrey};
    `}

  ${({ success }) =>
    success &&
    css`
      background-color: ${({ theme }) => theme.color.success};
    `}

  ${({ warning }) =>
    warning &&
    css`
      background-color: ${({ theme }) => theme.color.warning};
    `}

  ${({ confirmed }) =>
    confirmed &&
    css`
      background-color: ${({ theme }) => hexToRGBA(theme.color.success, 0.1)};
      color: ${({ theme }) => theme.color.success};
      pointer-events: none;
    `}

  ${({ primary }) =>
    primary &&
    css`
      background-color: ${({ theme }) => theme.color.brandPrimary};
      color: ${({ theme }) => theme.color.lightest};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.color.lighterGrey};
      color: ${({ theme }) => theme.color.lightGrey};
    `}

  ${({ emptyDanger }) =>
    emptyDanger &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.danger};
      color: ${({ theme }) => theme.color.danger};
    `}

  ${({ outline }) =>
    outline &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.color.brandPrimary};
      color: ${({ theme }) => theme.color.brandPrimary};
    `}

  ${({ hasIcon }) =>
    hasIcon &&
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      svg {
        flex-grow: 0;
        margin-right: 5px;
      }
      span {
        flex-grow: 1;
      }
    `}
`;

export const Text = styled.span`
  display: block;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > svg {
    max-height: 23px;
    max-width: 23px;
    margin: 0 ${({ theme }) => theme.spacing.s} -${({ theme }) =>
        theme.spacing.xs} 0;
    flex-shrink: 0;
  }
`;
