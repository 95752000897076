import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = boolean;

const [getGroupLoading, setGroupLoading] = createStore<State>(
  StoreId.GroupLoading,
  false,
);

export { getGroupLoading, setGroupLoading };

export const useGroupLoading = () => {
  const [loading] = useStore<State>(StoreId.GroupLoading);
  return loading;
};
