import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';
import { Coordinates } from '../types/api/automation/Graph';

export type State =
  | {
      type: 'recipe' | 'node' | 'group';
      coordinates: Coordinates;
    }
  | undefined;

const { setState, useStore } = createStore<State>(StoreId.AddMenu, undefined);

export const setAddMenu = setState;
export const useAddMenu = useStore;
