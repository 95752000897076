import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Form as AntForm, Input } from 'antd';
import { RegisterForm } from '../../../types/api/auth/RegisterForm';
import { TestAttribute } from '../../../types/ui/TestAttribute';
import { register, useAuth } from '../../../stores/useAuth';
import { StyledAlert, StyledButton, FormItem } from './styled';
import { usePasswordValidationRules } from '../../../hooks/usePasswordValidationRules';

type FormData = Omit<RegisterForm['user'], 'password_confirmation'> & {
  organizationName: RegisterForm['organization']['name'];
};

export const Form: FC = () => {
  const { t } = useTranslation('register');
  const { error, isLoading } = useAuth();
  const passwordRules = usePasswordValidationRules();

  return (
    <AntForm
      layout="vertical"
      onFinish={({ organizationName, password, ...rest }: FormData) =>
        register({
          user: { ...rest, password, password_confirmation: password },
          organization: { name: organizationName },
        })
      }
      validateMessages={{ required: t('error:form.required') }}
      requiredMark={false}
    >
      {error && <StyledAlert type="error" message={error} showIcon />}
      <FormItem
        name="organizationName"
        label={t('organizationName')}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input size="large" />
      </FormItem>
      <FormItem
        name="name"
        label={t('name')}
        rules={[{ required: true }]}
        hasFeedback
      >
        <Input size="large" />
      </FormItem>
      <FormItem
        name="email"
        label={t('email')}
        rules={[
          { required: true },
          { type: 'email', message: t('error:form.email.invalid') },
        ]}
        hasFeedback
      >
        <Input size="large" />
      </FormItem>
      <FormItem name="password" label={t('password')} rules={passwordRules}>
        <Input.Password size="large" />
      </FormItem>
      <StyledButton
        type="primary"
        htmlType="submit"
        loading={isLoading}
        data-test-id={TestAttribute.RegisterButton}
        size="large"
      >
        {t('submitButton')}
      </StyledButton>
    </AntForm>
  );
};
