export const searchBy = (
  search: string,
  value: string,
  label?: string,
): boolean => {
  let searchTerms = search.toLowerCase().split(' ');

  // remove blanks when have real terms
  if (searchTerms.length > 1) {
    searchTerms = searchTerms.filter((item) => item !== '');
  }
  const word = label || value;

  return searchTerms.every((st) => word.toLowerCase().includes(st));
};

export const searchByAnyOf = (search: string, values: string[]) => {
  let searchTerms = search.toLowerCase().split(' ');
  // remove blanks when have real terms
  if (searchTerms.length > 1) {
    searchTerms = searchTerms.filter((item) => item !== '');
  }
  return values.some((value) =>
    searchTerms.every((st) => value.toLowerCase().includes(st)),
  );
};

export const searchByMulit = (
  search: string,
  value: string,
  isSelected: (v: string) => boolean,
  label?: string,
): boolean => {
  let searchTerms = search.toLowerCase().split(' ');
  const word = label || value;
  // remove blanks when have real terms
  if (searchTerms.length > 1) {
    searchTerms = searchTerms.filter((item) => item !== '');
  }

  return searchTerms.every(
    (st) => word.toLowerCase().includes(st) && !isSelected(value),
  );
};
