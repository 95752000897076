import { config } from '../../config';
import { headers } from '../auth/headers';

const getTags = () =>
  fetch(`${config.apiBaseUrl}/v1/automation/tags`, {
    method: 'GET',
    ...headers(),
  });

export default {
  getTags,
};
