import styled from 'styled-components';
import { media } from '../../../styles/media';

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  ${media.md`
    display: flex;
    flex-direction: column;
  `}
`;

export const Content = styled.div`
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};
  padding-bottom: 0;
  font-size: 18px;
  ${media.md`
    width: 300px;
  `}
`;

export const ActionContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    flex-grow: 1;
    margin: ${({ theme }) => theme.spacing.s};
  }
`;
