import styled, { css } from 'styled-components';
import { media } from '../../../styles/media';

interface BubbleProps {
  type: string;
}

export const Container = styled.div`
  z-index: 1000;
  display: flex;
  flex-direction: row;
  position: fixed;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  user-select: none;
  pointer-events: none;

  * {
    user-select: none;
  }

  button {
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    flex-grow: 0;
    padding: 0;
    margin: 0;
    margin-left: ${({ theme }) => theme.spacing.s};
    font-size: 0.9em;
    font-weight: bold;
    color: ${({ theme }) => theme.color.warning};
    font-family: ${({ theme }) => theme.fontFamily.primary};
    text-transform: uppercase;
    transition: opacity 0.2s;
    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }

  @keyframes shownotification {
    0% {
      bottom: -10%;
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    85% {
      bottom: 30px;
    }
    100% {
      bottom: 20px;
    }
  }

  animation: shownotification 0.5s ease-in-out;
`;

export const Content = styled.div`
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.s};
  border-radius: 10px;
  left: 50%;
  background: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  transition: background-color 0.2s;
  pointer-events: auto;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.75);
  }

  ${media.md`
    max-width: 500px;
  `}
`;

export const IconContainer = styled.div<BubbleProps>`
  flex-grow: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.lightest};
  padding-left: ${({ theme }) => theme.spacing.s};
  ${({ type }) =>
    type === 'success' &&
    css`
      color: ${({ theme }) => theme.color.success};
    `}

  ${({ type }) =>
    type === 'error' &&
    css`
      color: ${({ theme }) => theme.color.danger};
    `}
  ${({ type }) =>
    type === 'warning' &&
    css`
      color: ${({ theme }) => theme.color.warning};
    `}
`;

export const TextContainer = styled.div`
  flex-grow: 1;
  color: ${({ theme }) => theme.color.lightest};
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.m};
  line-height: 1.2;
  display: flex;
  align-items: center;
`;

export const CloseContainer = styled.div`
  flex-grow: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.lightest};
  padding-right: ${({ theme }) => theme.spacing.s};

  svg {
    height: 10px;
    width: 10px;
  }
`;
