import { FC, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './styled';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-solid.svg';

type Props = ComponentProps<typeof StyledButton> & {
  type?: 'login' | 'register';
};

export const FacebookButton: FC<Props> = ({
  children,
  type = 'login',
  ...rest
}) => {
  const { t } = useTranslation('facebook');
  return (
    <StyledButton {...rest}>
      <FacebookIcon />
      <span>{t(type)}</span>
    </StyledButton>
  );
};
