import { FC } from 'react';
import { useAuth } from '../../../stores/useAuth';
import { Spinner } from '../../../components/spinner/Spinner';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Route } from '../../../types/ui/Route';

export const MagicLogin: FC = () => {
  const { t } = useTranslation('magicLink');
  const navigate = useNavigate();
  const { isLoading, error } = useAuth();

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t('errorMessage')}
        extra={
          <Button onClick={() => navigate(Route.PasswordLess)}>
            {t('newLink')}
          </Button>
        }
      />
    );
  }

  return null;
};
