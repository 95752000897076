import { FC, useState } from 'react';
import { Container } from './styled';
import { Modal, ModalProps } from '../../modal/Modal';
import { Form, FormGroup, InputContainer } from '../../forms/Forms';
import { Input } from '../../../../components';
import { Label } from '../../typography/Typography';
import { Button } from '../../button/Button';
import { createGroup } from '../../../../stores/useGraph';
import { getAutomationId } from '../../../../stores/useAutomationId';
import { useEditorActions } from '../../../../stores/useEditorActions';
import { useGroupIds } from '../../../../stores/useGroupIds';
import { Coordinates } from '../../../../types/api/automation/Graph';

interface Props extends ModalProps {
  onClose: () => void;
  modalTitle?: string;
  coordinates: Coordinates;
}

export const Content: FC<Props> = ({ onClose, modalTitle, coordinates }) => {
  const { selectedElementIds } = useEditorActions();
  const groupIds = useGroupIds();
  const [title, setTitle] = useState<string>('My Node Group');
  const [loading, setLoading] = useState<boolean | undefined>(undefined);

  return (
    <Modal onClose={onClose} title={modalTitle}>
      <Container>
        <Form
          onSubmit={async (e) => {
            e.preventDefault();
            const automation_id = getAutomationId();
            if (!automation_id) {
              return;
            }
            const node_ids = selectedElementIds
              ?.filter((item) => item.klass === 'node')
              .map((item) => item.id);
            const group_ids = selectedElementIds
              ?.filter((item) => item.klass === 'group')
              .map((item) => item.id);

            const group_id = groupIds?.[groupIds.length - 1];
            setLoading(true);
            const sucess = await createGroup({
              automation_id,
              group_id,
              title,
              description: '',
              node_ids,
              group_ids,
              ...coordinates,
            });
            setLoading(false);
            sucess && onClose();
          }}
        >
          <FormGroup>
            <Label htmlFor="title">Title</Label>
            <InputContainer>
              <Input
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </InputContainer>
          </FormGroup>
          <FormGroup>
            <Button type="submit" disabled={loading}>
              Create
            </Button>
          </FormGroup>
        </Form>
      </Container>
    </Modal>
  );
};
