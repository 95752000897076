import { FC, PropsWithChildren } from 'react';
import { StyledContent, Container } from './styled';
import { DashboardNavigation } from '../dashboardNavigation/DashboardNavigation';
import { DashboardHeader } from './dashboardHeader/DashboardHeader';
import { useLocation } from 'react-router-dom';
import { Route } from '../../../types/ui/Route';
import { theme } from '../../../styles/theme';

export const DashboardLayout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const isRuleEnginePage = pathname.startsWith(Route.RuleEngine);
  const isReportsPage = pathname === Route.Reports;
  const color =
    isRuleEnginePage || isReportsPage ? theme.color.brandPrimary18 : undefined;

  return (
    <Container>
      <DashboardNavigation />
      <StyledContent color={color}>
        <DashboardHeader />
        {children}
      </StyledContent>
    </Container>
  );
};
