import { media } from '../../../styles/media';
import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledContent = styled(Layout.Content)<{ color?: string }>`
  background: ${({ theme, color }) => color || theme.color.backgroundGrey};
  overflow-x: clip;
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Grid = styled.div<{ sidebarCollapsed: boolean }>`
  display: grid;

  ${({ sidebarCollapsed }) =>
    sidebarCollapsed
      ? media.lg`
          grid-template-columns: 1fr 8fr 3fr;
        `
      : media.lg`
          grid-template-columns: 1fr 8fr 1fr;
        `}
`;

export const Main = styled.main`
  overflow-x: hidden;
  padding: ${({ theme }) => theme.spacing.l} ${({ theme }) => theme.spacing.m};
  ${media.lg`
    grid-column: 2;
  `}
`;
