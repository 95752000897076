import { Typography } from 'antd';
import { FC } from 'react';
import { Processor, Socket } from '../../../../types/api/processor/Processor';
import { NodeIcon } from '../../../graphEditor/elements/NodeIcon';
import {
  Container,
  Header,
  IconBackground,
  Content,
  SocketContainer,
  SocketTitle,
  SocketCircle,
  SocketRow,
  StyledParagraph,
} from './styled';

export const ProcessorRender: FC<Processor> = ({
  inputs,
  outputs,
  category,
  title,
}) => (
  <Container>
    <Header>
      <IconBackground>
        <NodeIcon category={category} settings={{ width: 25, height: 25 }} />
      </IconBackground>
      <StyledParagraph>{title}</StyledParagraph>
    </Header>
    <Content>
      <SocketContainer>
        {Object.keys(inputs).map((key) => (
          <Input key={key} {...inputs[key]} />
        ))}
      </SocketContainer>
      <SocketContainer>
        {Object.keys(outputs)
          .filter((key) => !['error', 'failed'].includes(outputs[key].id))
          .map((key) => (
            <Output key={key} {...outputs[key]} />
          ))}
      </SocketContainer>
    </Content>
  </Container>
);

const Input: FC<Socket> = ({ id, schema_id }) => (
  <SocketRow>
    <SocketCircle schema_id={schema_id} />
    <SocketTitle>
      <Typography.Paragraph>{id}</Typography.Paragraph>
    </SocketTitle>
  </SocketRow>
);

const Output: FC<Socket> = ({ id, schema_id }) => (
  <SocketRow isOutput>
    <SocketTitle>
      <Typography.Paragraph>{id}</Typography.Paragraph>
    </SocketTitle>
    <SocketCircle schema_id={schema_id} />
  </SocketRow>
);
