import { config } from '../../config';
import { ActAsOrganizationFrom } from '../../types/api/admin/ActAsOrganizationFrom';
import { headers } from '../auth/headers';

const actAsOrganization = (id: string, form: ActAsOrganizationFrom) =>
  fetch(`${config.apiBaseUrl}/v1/admin/tokens/${id}/act_as_organization`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const clearActAsOrganization = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/admin/tokens/${id}/act_as_organization`, {
    method: 'DELETE',
    ...headers(),
  });

export default { actAsOrganization, clearActAsOrganization };
