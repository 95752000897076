import { FC } from 'react';
import marked from 'marked';
import { Container, Content as Main, ActionContainer } from './styled';
import { Modal, ModalProps } from '../modal/Modal';
import { ConfirmationData } from '../../../types/ui/ConfirmationData';
import { Button } from '../button/Button';

type Props = ConfirmationData & ModalProps;

export const Content: FC<Props> = ({
  onClose,
  title,
  actions,
  description,
}) => (
  <Modal onClose={onClose} title={title}>
    <Container>
      <Main
        dangerouslySetInnerHTML={{
          __html: marked(description),
        }}
      />
      <ActionContainer>
        {actions.map((a, index) => (
          <Button
            /* eslint-disable-next-line react/no-array-index-key */
            key={`action-${index}`}
            success={a.type === 'success'}
            danger={a.type === 'danger'}
            warning={a.type === 'warning'}
            onClick={a.onClick}
          >
            {a.title}
          </Button>
        ))}
      </ActionContainer>
    </Container>
  </Modal>
);
