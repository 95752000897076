import { useState } from 'react';
import { PasswordLessForm } from '../types/api/auth/PasswordLessForm';
import { postPasswordLess } from '../api/auth/auth';

export const usePasswordLess = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState(false);

  const onSubmit = async (form: PasswordLessForm) => {
    setLoading(true);
    setSuccess(false);
    setError(undefined);
    try {
      const response = await postPasswordLess(form);
      setLoading(false);
      if (response.ok) {
        setSuccess(true);
      } else {
        setError(response.statusText);
      }
    } catch {
      setLoading(false);
      setError(
        'An error occured while trying to get your password less login.',
      );
    }
  };

  return { success, error, isLoading, onSubmit };
};
