import styled, { css } from 'styled-components';
import { media } from '../../../styles/media';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  label {
    display: block;
    flex-grow: 1;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
  }

  button {
    width: auto;
    flex-grow: 0;
    align-self: flex-start;
  }

  p {
    color: ${({ theme }) => theme.color.darkerGrey};
    letter-spacing: 0.2px;
    margin-top: 0;
    line-height: 1.3;
  }

  ${media.md`
        max-width: 30vw;
    `}
`;

export const Form = styled.form`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;

  label {
    display: block;
    flex-grow: 1;
  }

  input {
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
  }

  button {
    width: auto;
    flex-grow: 0;
    align-self: flex-start;
  }

  p {
    color: ${({ theme }) => theme.color.darkerGrey};
    letter-spacing: 0.2px;
    margin-top: 0;
    line-height: 1.3;
  }

  ${media.md`
        max-width: 30vw;
    `}
`;

interface FormGroupProps {
  flexDirection?: string;
  push?: boolean;
  alignRight?: boolean;
}

export const FormGroup = styled.div<FormGroupProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  ${({ push }) =>
    push &&
    css`
      padding: 10px 0 10px 30px;
    `}

  ${({ alignRight }) =>
    alignRight &&
    css`
      text-align: right;
      align-items: flex-end;

      button,
      input,
      select {
        align-self: flex-end;
      }
    `}
`;

export const InputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  & > div {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.xs};
    font-weight: normal;

    label {
      font-weight: 400;
    }
  }
`;

export const Separator = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  margin-top: ${({ theme }) => theme.spacing.s};
  margin-bottom: ${({ theme }) => theme.spacing.m};
  background: ${({ theme }) => theme.color.lighterGrey};
`;

export const SubHeading = styled.div`
  display: block;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.m};
  font-size: 16px;
  border-top: 1px solid ${({ theme }) => theme.color.lightGrey};
  padding-top: 10px;
  font-family: ${({ theme }) => theme.fontFamily.secondary};
`;
