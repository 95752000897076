import styled from 'styled-components';
import { media } from '../../../../styles/media';

export const Container = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.s};
  min-width: 90vw;
  ${media.md`
    display: flex;
    flex-direction: column;
    min-width: 500px;
  `}
`;
