import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 48px;
  background: ${({ theme }) => theme.color.lightest};
  padding: 0 ${({ theme }) => theme.spacing.m};
  display: flex;
  justify-content: right;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray300};
  box-sizing: border-box;
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  padding: 0 ${({ theme }) => theme.spacing.m};
  cursor: pointer;
  transition: background-color 0.35s;
  :hover {
    background-color: ${({ theme }) => theme.color.primary1};
  }
  > * {
    flex-shrink: 0;
  }
  > div {
    flex-shrink: 1;
    padding: 0 ${({ theme }) => theme.spacing.s};
  }
`;

export const NotificationContainer = styled(Link)<{ selected: boolean }>`
  padding: 0 ${({ theme }) => theme.spacing.m};
  display: flex;
  align-items: center;
  height: 100%;

  cursor: pointer;
  transition: background-color 0.35s;
  :hover {
    background-color: ${({ theme }) => theme.color.primary1};
  }

  > span {
    padding: ${({ theme }) => theme.spacing.xs};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.color.primary3};
      cursor: initial;
    `}
`;
