import { FC } from 'react';
import { Content } from './Content';
import { useAddMenu } from '../../../../stores/useAddMenu';
import { reset as resetEditorActions } from '../../../../stores/useEditorActions';

interface Props {
  title: string;
}

export const NewModal: FC<Props> = ({ title }) => {
  const [addMenu, setAddMenu] = useAddMenu();
  if (addMenu?.type !== 'group') return null;
  // TODO: Fix => Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
  return (
    <Content
      onClose={() => {
        setAddMenu(undefined);
        resetEditorActions();
      }}
      modalTitle={title}
      coordinates={addMenu.coordinates}
    />
  );
};
