import styled, { css } from 'styled-components';
import { hexToRGBA } from '../../../util/hexToRgba';
import { media } from '../../../styles/media';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  box-sizing: border-box;
`;

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing.s};
  width: 100%;
  top: 0;
  left: 0;
  box-sizing: border-box;
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.lightest};
  box-shadow: 0 2px 10px 0 ${({ theme }) => hexToRGBA(theme.color.black, 0.07)};
  position: relative;
  box-sizing: border-box;
  max-height: 98%;
  flex-grow: 0;
  width: 100%;
  max-width: 99%;
  display: flex;
  flex-direction: column;
  ${media.md`
        width: auto;
      `}
`;

interface MainProps {
  noscroll?: boolean;
}

export const Main = styled.div<MainProps>`
  flex-grow: 1;

  ${({ noscroll }) =>
    !noscroll &&
    css`
      overflow: scroll;
      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    `}
`;

export const FixedContainer = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-color: ${({ theme }) => hexToRGBA(theme.color.black, 0.33)};
  display: flex;
`;
