import { FC } from 'react';
import { ReactComponent as Snapchat } from '../../../assets/platform/snapchat.svg';
import { ReactComponent as Facebook } from '../../../assets/platform/facebook.svg';
import { ReactComponent as Slack } from '../../../assets/platform/slack.svg';
import { ReactComponent as LinkedIn } from '../../../assets/platform/linkedin.svg';
import { ReactComponent as Microsoft365 } from '../../../assets/platform/microsoft365.svg';
import { ReactComponent as GoogleDrive } from '../../../assets/platform/google_drive.svg';
import { ReactComponent as GoogleSheets } from '../../../assets/platform/google_sheets.svg';
import { ReactComponent as GoogleAds } from '../../../assets/platform/google_ads.svg';
import { ReactComponent as Apple } from '../../../assets/platform/apple.svg';
import { ReactComponent as Taboola } from '../../../assets/platform/taboola.svg';
import { ReactComponent as GoogleAnalytics } from '../../../assets/platform/google_analytics.svg';
import { ReactComponent as GoogleBigQuery } from '../../../assets/platform/google_big_query.svg';
import { ReactComponent as GoogleDv360 } from '../../../assets/platform/google_dv360.svg';
import { ReactComponent as Youtube } from '../../../assets/platform/youtube.svg';
import { ReactComponent as TikTok } from '../../../assets/platform/tik_tok.svg';
import { ReactComponent as Tableau } from '../../../assets/platform/tableau.svg';
import { ReactComponent as Emarsys } from '../../../assets/platform/emarsys.svg';
import { ReactComponent as Hyros } from '../../../assets/platform/hyros.svg';
import { ReactComponent as AppsFlyer } from '../../../assets/platform/apps_flyer.svg';
import { ReactComponent as Adjust } from '../../../assets/platform/adjust.svg';
import { ReactComponent as Outbrain } from '../../../assets/platform/outbrain.svg';
import { ReactComponent as MondayCom } from '../../../assets/platform/monday_com.svg';
import { ReactComponent as Asana } from '../../../assets/platform/asana.svg';
import { ReactComponent as AwsAthena } from '../../../assets/platform/aws_athena.svg';
import { ReactComponent as Airtable } from '../../../assets/platform/airtable.svg';
import { ReactComponent as Shopify } from '../../../assets/platform/shopify.svg';
import { ReactComponent as Twitter } from '../../../assets/platform/twitter.svg';
import { ReactComponent as Pinterest } from '../../../assets/platform/pinterest.svg';
import { ReactComponent as Dropbox } from '../../../assets/platform/dropbox.svg';
import { ReactComponent as DropboxBusiness } from '../../../assets/platform/dropbox_business.svg';
import { ReactComponent as Notion } from '../../../assets/platform/notion.svg';
import { ReactComponent as Squaredance } from '../../../assets/platform/squaredance.svg';
import { ReactComponent as Sigma } from '../../../assets/platform/sigma.svg';
import { ReactComponent as Frontify } from '../../../assets/platform/frontify.svg';
import { ReactComponent as Shotstack } from '../../../assets/platform/shotstack.svg';

const mapping = {
  apple_search_ads: Apple,
  apple_app_store_connect: Apple,
  dropbox_business: DropboxBusiness,
  dropbox: Dropbox,
  facebook: Facebook,
  google_ads: GoogleAds,
  google_analytics: GoogleAnalytics,
  google_drive: GoogleDrive,
  google_sheets: GoogleSheets,
  google_big_query: GoogleBigQuery,
  google_dv360: GoogleDv360,
  youtube: Youtube,
  hyros: Hyros,
  linkedin: LinkedIn,
  microsoft365: Microsoft365,
  monday_com: MondayCom,
  asana: Asana,
  airtable: Airtable,
  notion: Notion,
  outbrain: Outbrain,
  pinterest: Pinterest,
  shopify: Shopify,
  slack: Slack,
  snapchat: Snapchat,
  taboola: Taboola,
  tik_tok: TikTok,
  twitter_ads: Twitter,
  twitter: Twitter,
  tableau: Tableau,
  emarsys: Emarsys,
  apps_flyer: AppsFlyer,
  adjust: Adjust,
  squaredance: Squaredance,
  aws_athena: AwsAthena,
  sigma: Sigma,
  frontify: Frontify,
  shotstack: Shotstack,
};

type Props = {
  id: keyof typeof mapping;
  disabled?: boolean;
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  fillOpacity?: number;
  className?: string;
};

export const ProviderIcon: FC<Props> = ({
  id,
  height,
  width,
  x,
  y,
  fillOpacity,
  className,
}) => {
  const Component = mapping[id];

  // TODO: Fix internal integraions which are not exposed like 'hcti', 'ocr_space'
  if (!Component) return null;
  return (
    <Component
      className={className}
      fillOpacity={fillOpacity}
      x={x}
      y={y}
      height={height || 25}
      width={width || 25}
    />
  );
};
