import styled, { css } from 'styled-components';
import { media, breakpoints } from '../styles/media';

interface Props {
  flexrow?: boolean;
  flexcol?: boolean;
  fullHeight?: boolean;
}

export const PageContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ContentWrapper = styled.div<Props>`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;
  justify-self: stretch;

  ${media.sm`
    max-width: ${breakpoints.sm};
  `}

  ${media.md`
    max-width: ${breakpoints.md};
    padding: 0;
  `}

  ${media.lg`
    max-width: ${breakpoints.lg};
    padding: 0;
  `}

  ${media.xl`
    max-width: ${breakpoints.xl};
    padding: 0;
  `}

  margin: 0 auto;

  ${({ flexrow, flexcol }) => {
    switch (true) {
      case flexrow:
        return flexRow;
      case flexcol:
        return flexCol;
    }
  }}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
`;

const flexRow = css`
  display: block;
  ${media.md`
    display: flex;
    flex-direction: row;
  `}
`;

const flexCol = css`
  display: block;
  ${media.md`
    display: flex;
    flex-direction: column;
  `}
`;
