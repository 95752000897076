import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../../styles/media';

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.color.brandSecondary};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.1px;

  ${media.md`
    font-size: 18px;
    line-height: 25px;
    letter-spacing: initial;
  `}
`;
