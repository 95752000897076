import fetch from 'isomorphic-unfetch';
import { config } from '../../config';
import { InviteCreateForm } from '../../types/api/entity/InviteCreateForm';
import { InviteSignupForm } from '../../types/api/entity/InviteSignupForm';
import { headers } from '../auth/headers';

const createInvite = (form: InviteCreateForm) =>
  fetch(`${config.apiBaseUrl}/v1/entity/invites`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const resendInvite = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/entity/invites/${id}/resend`, {
    method: 'POST',
    ...headers(),
  });

const deleteInvite = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/entity/invites/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const postInviteSignup = (id: string, form: InviteSignupForm) =>
  fetch(`${config.apiBaseUrl}/v1/entity/invites/${id}/signup`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const getInvites = () =>
  fetch(`${config.apiBaseUrl}/v1/entity/invites`, {
    method: 'GET',
    ...headers(),
  });

const getInvite = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/entity/invites/${id}`);

export default {
  getInvite,
  getInvites,
  postInviteSignup,
  createInvite,
  resendInvite,
  deleteInvite,
};
