import { FC, PropsWithChildren } from 'react';
import { GlobalStyle } from '../styles/GlobalStyle';
import { PageViewTracker } from '../components/v2/pageViewTracker/PageViewTracker';
import { PageContainer } from './styled';
import { ConfirmationModal } from '../components/v2/confirmationModal/ConfirmationModal';
import { NewModal as NewTagsModal } from '../components/v2/tags/newModal/NewModal';
import { NewModal as NewNodeModal } from '../components/v2/nodes/newModal/NewModal';
import { NewModal as NewGroupModal } from '../components/v2/groups/newModal/NewModal';
import { Notifications } from './notifications/Notifications';
import { DashboardLayout } from '../components/dashboard/dashboardLayout/DashboardLayout';
import { useLocation } from 'react-router-dom';
import { Route } from '../types/ui/Route';
import { ModalAndNotificationProvider } from './notifications/ModalAndNotificationProvider';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <GlobalStyle>
      <ModalAndNotificationProvider />
      <PageViewTracker />
      <PageContainer>
        <NewTagsModal title="Add New Label" />
        <NewNodeModal />
        <NewGroupModal title="Add Group" />
        <ConfirmationModal />
        <Notifications />

        {pathname === Route.StorageDataStores ||
        pathname === Route.Reports ||
        pathname === Route.RuleEngineRules ||
        pathname === Route.RuleEngineActivities ||
        pathname === Route.Automations ||
        pathname.startsWith('/automations/folder') ||
        pathname === Route.Notifications ||
        pathname.startsWith(Route.Settings) ||
        pathname.startsWith(Route.AdPlatform) ||
        pathname.startsWith(Route.Admin) ||
        pathname.startsWith(Route.Integrations) ? (
          <DashboardLayout>{children}</DashboardLayout>
        ) : (
          children
        )}
      </PageContainer>
    </GlobalStyle>
  );
};
