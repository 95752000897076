import { Groups } from '../../types/api/automation/Graph';

export const removeGroupAndAdaptParent = (
  groups: Groups,
  groupId: string,
  parentId?: string,
): Groups => {
  const { [groupId]: removed, ...filtered } = groups;
  const parent = parentId ? filtered[parentId] : undefined;
  if (parentId && parent) {
    return {
      ...filtered,
      [parentId]: {
        ...parent,
        publishable_input_ids: [
          ...new Set([
            ...parent.publishable_input_ids,
            ...removed.publishable_input_ids,
          ]),
        ],
        publishable_output_ids: [
          ...new Set([
            ...parent.publishable_output_ids,
            ...removed.publishable_output_ids,
          ]),
        ],
      },
    };
  }
  return filtered;
};
