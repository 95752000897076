import { FC, memo, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from '../../../util/trackEvent';
import { Route } from '../../../types/ui/Route';

const getPathnameWithoutId = (pathname: string) => {
  if (pathname.startsWith(Route.Automations)) {
    return Route.Automations;
  }
  if (pathname.startsWith(Route.Integrations)) {
    return Route.Integrations;
  }
  return pathname;
};

export const PageViewTracker: FC = memo(() => {
  const { pathname } = useLocation();

  const page = useMemo(() => getPathnameWithoutId(pathname), [pathname]);

  useEffect(() => {
    trackPage(page);
  }, [page]);

  return null;
});
