import { useEffect } from 'react';
import api from '../api/integration/credentials';
import { Credential } from '../types/api/integration/Credential';
import { CredentialForm } from '../types/api/integration/CredentialForm';
import { setNotification } from './useUiNotifications';
import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';
import { useAuth } from '../stores/useAuth';
import { Error as ResponseError } from '../types/api/Error';

export interface State {
  credentials?: Credential[];
  isLoading?: boolean;
  isLoadingOnDelete?: boolean;
}

const { setState, useStore, getState } = createStore<State>(
  StoreId.Credentials,
  {},
);

export const fetchCredentials = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getCredentials();
    if (response.ok) {
      setState({ credentials: await response.json() });
      return true;
    }
  } catch {}

  setState((state) => ({ credentials: state.credentials }));
  setNotification({
    type: 'error',
    entity: 'credential',
    description: 'An error occured on fetching integrations',
  });
  return false;
};

export const deleteCredential = async (id: string) => {
  setState((state) => ({ ...state, isLoadingOnDelete: true }));
  try {
    const response = await api.deleteCredential(id);
    if (response.ok) {
      setState((state) => ({
        credentials: state.credentials?.filter((item) => item.id !== id),
      }));
      setNotification({
        type: 'success',
        entity: 'credential',
        description: 'Successfully deleted integration.',
      });
      return true;
    }
  } catch {}

  setState((state) => ({ credentials: state.credentials }));
  setNotification({
    type: 'error',
    entity: 'credential',
    description: 'An error occured on deleting an integrations',
  });
  return false;
};

export const updateCredential = async (id: string, form: CredentialForm) => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.updateCredential(id, form);
    if (response.ok) {
      const credentialResponse = await response.json();
      setState(({ credentials }) => ({
        credentials: credentials?.map((c) =>
          c.id === id ? credentialResponse : c,
        ),
      }));
      return true;
    }
  } catch {}

  setState((state) => ({ credentials: state.credentials }));
  return false;
};

export const createCredential = async (
  form: CredentialForm,
): Promise<ResponseError | true> => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.createCredential(form);
    if (response.ok) {
      const credentialResponse = await response.json();
      setState(({ credentials }) => ({
        credentials: [...(credentials || []), credentialResponse],
      }));
      return true;
    }
    setState((state) => ({ ...state, isLoading: false }));
    // Parse the error response and return it
    const errorResponse: ResponseError = await response.json();
    return errorResponse;
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred.';
    // Handle other exceptions if needed
    return {
      code: 'UNKNOWN_ERROR',
      error: errorMessage,
    };
  } finally {
    setState((state) => ({ credentials: state.credentials, isLoading: false }));
  }
};

export const useCredentials = () => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore();
  const { isLoading, credentials, isLoadingOnDelete } = state;

  useEffect(() => {
    const { isLoading, credentials } = getState();
    if (isAuthenticated) {
      if (!credentials && !isLoading) {
        fetchCredentials();
      }
    } else if (credentials) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    credentials,
    isLoading,
    isLoadingOnDelete,
  };
};
