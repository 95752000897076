import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { trackEvent } from '../../../util/trackEvent';
import { ReactComponent as Integrations } from '../../../assets/illustrations/integrations.svg';
import { Form } from './Form';
import { Divider } from './styled';
import { IllustratedPage } from '../../../components/illustratedPage/IllustratedPage';
import { FacebookButton } from '../../../components/facebookButton/FacebookButton';
import { GoogleButton } from '../../../components/googleButton/GoogleButton';
import { Space } from 'antd';
import { useLocation } from 'react-router-dom';

export const Login: FC = () => {
  const { t } = useTranslation('login');
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const redirect_url = params.get('redirect_url');

  let fbURL = `${config.apiBaseUrl}/v1/integration/facebook/sign_in`;
  if (redirect_url) {
    fbURL = `${fbURL}?redirect_url=${redirect_url}`;
  }

  let googleURL = `${config.apiBaseUrl}/v1/integration/google/sign_in`;
  if (redirect_url) {
    googleURL = `${googleURL}?redirect_url=${redirect_url}`;
  }

  return (
    <IllustratedPage illustration={<Integrations />} title={t('title')}>
      <Form />
      <Divider>
        <span>{t('or')}</span>
      </Divider>
      <Space direction="vertical" size="middle">
        <FacebookButton
          onClick={() => trackEvent('Login With Facebook')}
          href={fbURL}
        />
        <GoogleButton
          onClick={() => trackEvent('Login With Google')}
          href={googleURL}
        />
      </Space>
    </IllustratedPage>
  );
};
