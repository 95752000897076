import { config } from '../config';
import { NotificationForm } from '../types/api/NotificationForm';
import { headers } from './auth/headers';

const updateNotification = async (id: string, form: NotificationForm) =>
  fetch(`${config.apiBaseUrl}/v1/notifications/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const batchUpdateNotification = (ids: string[]) =>
  fetch(`${config.apiBaseUrl}/v1/notifications/batch`, {
    method: 'PUT',
    body: JSON.stringify({
      ids,
    }),
    ...headers(),
  });

const getNotifications = async () =>
  fetch(`${config.apiBaseUrl}/v1/notifications`, {
    method: 'GET',
    ...headers(),
  });

export default {
  getNotifications,
  batchUpdateNotification,
  updateNotification,
};
