import { config } from '../../config';
import { headers } from '../auth/headers';

const getRules = () =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules`, {
    method: 'GET',
    ...headers(),
  });

const getRule = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules/${id}`, {
    method: 'GET',
    ...headers(),
  });

const duplicateRule = (id: string, form: any) =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules/${id}/duplicate`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const createRule = (form: any) =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules`, {
    method: 'POST',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const abortRule = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules/${id}/abort`, {
    method: 'POST',
    ...headers(),
  });

const updateRule = (id: string, form: any) =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules/${id}`, {
    method: 'PUT',
    body: JSON.stringify({
      ...form,
    }),
    ...headers(),
  });

const deleteRule = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/rule_engine/rules/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

export default {
  getRules,
  getRule,
  updateRule,
  deleteRule,
  abortRule,
  createRule,
  duplicateRule,
};
