import { Socket } from '../../types/api/automation/Graph';
import { Connecting } from '../../types/ui/Connecting';

export const isConnectableInput = (
  input: Pick<Socket, 'id' | 'schema'>,
  connecting?: Connecting,
) => {
  if (!connecting) return true;
  if (connecting.input) return false;
  if (!connecting.output) return false;
  if (input.schema === 'any' && connecting.output.schema !== 'trigger')
    return true;
  if (connecting.output.schema === 'any' && input.schema !== 'trigger')
    return true;
  return connecting.output.schema === input.schema;
};
