import {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { Button, Input, Modal, Tree } from 'antd';
import { useProcessors } from '../../../stores/useProcessors';
import { DownOutlined } from '@ant-design/icons';
import { Icon } from '../../v2/automation/editor/Icon';
import { ProviderIcon } from '../../v2/provider/ProviderIcon';
import { Key } from 'antd/es/table/interface';
import {
  Processor,
  Sockets,
  Socket,
} from '../../../types/api/processor/Processor';
import { useTranslation } from 'react-i18next';
import { createNode } from '../../../stores/useGraph';
import { Coordinates } from '../../../types/api/automation/Graph';
import { useGroupIds } from '../../../stores/useGroupIds';
import { useNodeLoading } from '../../../stores/useNodeLoading';
import { useAutomationId } from '../../../stores/useAutomationId';
import { Content, Selection, TreeContainer } from './styled';
import { searchBy, searchByAnyOf } from '../../../util/searchBy';
import { DataNode } from 'antd/lib/tree';
import { useTreeNavigation } from './hooks/useTreeNavigation';
import { mapDataNodeToKeys } from './utils/mapDataNodeToKeys';
import { useKeyPress } from '../../../hooks/useKeyPress';
import { SelectedProcessor } from './SelectedProcessor';
import { useAuth } from '../../../stores/useAuth';
import {
  ProviderId,
  PROVIDER_IDS,
} from '../../../types/api/integration/ProviderId';
import { useCredentials } from '../../../stores/useCredentials';

const PROCESSOR_BLACKLIST = [
  'start',
  'stop',
  'extract',
  'patch_json_v2',
  'patch_json_list_v2',
  'create_data_store',
];

const toArray = (sockets?: Sockets): Socket[] => {
  if (!sockets) return [];

  const sortedKeys = Object.keys(sockets).sort((a, b) =>
    sockets[a].id > sockets[b].id ? 1 : -1,
  );

  return sortedKeys.map((k) => sockets[k]);
};

const genCore = (list?: Processor[]) => {
  const id = 'core';
  const core = {
    title: 'Core',
    key: id,
    selectable: false,
    isLeaf: false,
    icon: <Icon type="node" width={14} height={14} />,
  };
  if (!list) return;

  const coreList = list.filter((item) => item.category === id);
  const subCategoryKeys = coreList
    .map((item) => item.sub_category)
    .filter((value, index, self) => self.indexOf(value) === index);

  const children = subCategoryKeys.map((subCategoryKey) => {
    const coreSubList = coreList.filter(
      (item) => item.sub_category === subCategoryKey,
    );
    const children = coreSubList.map((item) => ({
      title: item.title,
      key: item.id,
    }));

    if (children.length < 1) {
      return;
    }

    return {
      title: subCategoryKey,
      key: `${id}-${subCategoryKey}`,
      isLeaf: false,
      selectable: false,
      children,
    };
  });

  if (children.length < 1) {
    return;
  }

  return { ...core, children };
};

const genIntegration = (
  id: ProviderId,
  title: string,
  disabled: boolean,
  searchValue: string,
  list?: Processor[],
) => {
  const fillOpacity = disabled ? 0.5 : 1;
  const category = {
    title,
    key: id,
    isLeaf: false,
    selectable: false,
    disabled,
    icon: (
      <ProviderIcon id={id} height={14} width={14} fillOpacity={fillOpacity} />
    ),
  };
  if (disabled && searchValue.length < 1) return category;
  if (!list) return;

  const categoryList = list.filter((item) => item.category === id);
  const subCategoryKeys = categoryList
    .map((item) => item.sub_category)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort();

  if (subCategoryKeys.length === 1 && subCategoryKeys[0] === undefined) {
    const children = categoryList.map((item) => ({
      title: item.title,
      key: item.id,
    }));
    if (children.length < 1) {
      return;
    }
    return { ...category, children };
  }

  const children = subCategoryKeys.map((subCategoryKey) => {
    const categorySubList = categoryList.filter(
      (item) => item.sub_category === subCategoryKey,
    );
    const children = categorySubList.map((item) => ({
      title: item.title,
      key: item.id,
    }));
    if (children.length < 1) {
      return;
    }
    return {
      title: subCategoryKey,
      key: `${id}-${subCategoryKey}`,
      isLeaf: false,
      selectable: false,
      children,
    };
  });

  if (children.length < 1) {
    return;
  }

  return { ...category, children };
};

const genTreeData = (
  integrationCategories: {
    id: ProviderId;
    title: string;
    disabled: boolean;
  }[],
  searchValue: string,
  list?: Processor[],
): DataNode[] => [
  {
    title: 'Processors',
    key: 'processors',
    isLeaf: false,
    selectable: false,
    icon: <Icon type="processor" width={14} height={14} />,
    children: [
      genCore(list),
      ...integrationCategories.map((item) =>
        genIntegration(item.id, item.title, item.disabled, searchValue, list),
      ),
    ].filter(Boolean) as DataNode[],
  },
  {
    title: 'Groups',
    key: 'groups',
    isLeaf: false,
    selectable: false,
    icon: <Icon type="group" width={14} height={14} />,
  },
];

type Props = {
  open: boolean;
  onClose: () => void;
  coordinates: Coordinates;
};

const INIT_EXPANDED_KEYS = ['processors', 'groups'];

export const Library: FC<Props> = ({ open, onClose, coordinates }) => {
  const { processorlist } = useProcessors();
  const { token, isGod, isMineMarketing } = useAuth();
  const { credentials } = useCredentials();

  const connectedProviderIds = useMemo(
    () =>
      credentials
        ?.map((item) => item.provider_id)
        .filter((value, index, array) => array.indexOf(value) === index),
    [credentials],
  );

  const enabledProviderIds = token?.organization?.details.enabled_provider_ids;
  const { t } = useTranslation('processors');

  const automationId = useAutomationId();
  const isLoading = useNodeLoading('on_create_id');
  const groupIds = useGroupIds();

  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>(INIT_EXPANDED_KEYS);

  const [selectedProcessorId, setSelectedProcessorId] = useState<string>();

  const list = useMemo(() => {
    const isConnected = (category?: string) => {
      if (!category || !PROVIDER_IDS.includes(category as ProviderId))
        return true;
      return connectedProviderIds?.includes(category as ProviderId);
    };

    return processorlist?.filter(({ deprecated, id, category }) => {
      let baseFilter =
        isConnected(category) &&
        !deprecated &&
        !PROCESSOR_BLACKLIST.includes(id);

      // KIT-2014 only mine marketing uses this node.
      if (!isMineMarketing) {
        baseFilter = baseFilter && id !== 'connect_credential_id';
      }

      if (isGod) {
        return baseFilter;
      }

      return baseFilter;
    });
  }, [processorlist, connectedProviderIds, isGod, isMineMarketing]);

  const onExpand = (
    keys: Key[],
    { expanded, node }: { node: DataNode; expanded: boolean },
  ) => {
    const subKeys = mapDataNodeToKeys(node);
    setExpandedKeys(
      expanded
        ? [...keys, ...subKeys]
        : keys.filter((key) => !subKeys.includes(`${key}`)),
    );
    setAutoExpandParent(false);
  };

  const onChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(target.value);

    if (target.value.length < 1 && expandedKeys !== INIT_EXPANDED_KEYS) {
      setExpandedKeys(INIT_EXPANDED_KEYS);
      setSelectedProcessorId(undefined);
    } else {
      setAutoExpandParent(true);
    }
  };

  const searchedList = useMemo(
    () =>
      list?.filter(
        (item) =>
          (item.sub_category && searchBy(searchValue, item.sub_category)) ||
          (item.category && searchBy(searchValue, item.category)) ||
          searchBy(searchValue, item.description) ||
          searchBy(searchValue, item.title) ||
          searchBy(searchValue, item.id) ||
          searchByAnyOf(
            searchValue,
            toArray(item.inputs).map(({ schema_id }) => schema_id),
          ) ||
          searchByAnyOf(
            searchValue,
            toArray(item.outputs).map(({ schema_id }) => schema_id),
          ),
      ),
    [list, searchValue],
  );

  const treeData = useMemo(() => {
    const integrationCategories =
      enabledProviderIds?.map((id) => ({
        disabled: !connectedProviderIds?.includes(id),
        title: t(`provider:${id}.title`),
        id,
      })) || [];

    return genTreeData(integrationCategories, searchValue, searchedList);
  }, [t, searchValue, searchedList, enabledProviderIds, connectedProviderIds]);

  const allKeys = useMemo(
    () => treeData.flatMap(mapDataNodeToKeys),
    [treeData],
  );

  useEffect(() => {
    if (searchValue.length < 1) {
      return;
    }
    setExpandedKeys(allKeys);
  }, [allKeys, searchValue]);

  useEffect(() => {
    if (searchValue.length < 1) {
      return;
    }
    setSelectedProcessorId(searchedList?.[0]?.id);
  }, [searchedList, searchValue]);

  const visibleKeys = useMemo(
    () => allKeys.filter((key) => expandedKeys.includes(key)),
    [allKeys, expandedKeys],
  );

  useTreeNavigation(visibleKeys, setSelectedProcessorId);

  const handleOk = useCallback(async () => {
    if (!selectedProcessorId) {
      return;
    }
    const automation_group_id = groupIds?.[groupIds.length - 1];
    const ok = await createNode({
      automation_id: automationId,
      automation_group_id,
      processor_id: selectedProcessorId,
      ...coordinates,
    });
    if (ok) {
      onClose();
    }
  }, [automationId, groupIds, selectedProcessorId, coordinates, onClose]);

  useKeyPress('Enter', handleOk);

  const selectedProcessor = processorlist?.find(
    ({ id }) => id === selectedProcessorId,
  );

  return (
    <Modal
      width={1000}
      confirmLoading={isLoading}
      title={t('library.title')}
      open={open}
      onCancel={onClose}
      // TODO: remove when antd autofocus within a modal issue is fixed https://github.com/ant-design/ant-design/issues/41239
      afterOpenChange={() => document.getElementById('library-search')?.focus()}
      footer={[
        <Button key="cancel" onClick={onClose}>
          {t('library.cancel')}
        </Button>,
        <Button
          key="add"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
          disabled={!selectedProcessorId}
        >
          {t('library.add')}
        </Button>,
      ]}
    >
      <Content>
        <Selection>
          <Input.Search
            id="library-search"
            loading={!treeData}
            style={{ marginBottom: 8 }}
            placeholder={t('library.searchPlaceholder')}
            onChange={onChange}
            autoFocus
          />
          <TreeContainer>
            <Tree
              showIcon
              onSelect={([id]) => setSelectedProcessorId(`${id}`)}
              selectedKeys={selectedProcessorId ? [selectedProcessorId] : []}
              onExpand={onExpand}
              switcherIcon={<DownOutlined />}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              treeData={treeData}
            />
          </TreeContainer>
        </Selection>
        <SelectedProcessor processor={selectedProcessor} />
      </Content>
    </Modal>
  );
};
