import { useEffect } from 'react';
import api from '../../api/ruleEngine/rules';
import { useAuth } from '../useAuth';
import { Rule } from '../../types/api/ruleEngine/Rule';
import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../../types/ui/StoreId';
import { Error as ResponseError } from '../../types/api/Error';

export type State = {
  rules?: (Rule & { level?: string; platform?: string })[];
  isLoading?: boolean;
};

const { getState, setState } = createStore<State>(StoreId.Rules, {});

export const fetchRules = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getRules();
    if (response.ok) {
      const rules = await response.json();
      setState({ rules });
      return true;
    }
  } catch {}

  setState(({ rules }) => ({ rules }));
  return false;
};

export const setRule = (data: Rule) => {
  setState(({ rules }) => {
    const newRules = rules?.map((rule) => (rule.id === data.id ? data : rule));
    return { rules: newRules };
  });
};

export const updateRule = async (id: string, form: any) => {
  try {
    const response = await api.updateRule(id, form);
    if (response.ok) {
      const rule: Rule = await response.json();
      setState((state) => ({
        ...state,
        rules: state.rules?.map((item) => (item.id === id ? rule : item)),
      }));
      return true;
    }
    const error: ResponseError = await response.json();
    return error;
  } catch {}
  return false;
};

export const createRule = async (form: any) => {
  try {
    const response = await api.createRule(form);
    if (response.ok) {
      const rule: Rule = await response.json();
      setState((state) => ({
        ...state,
        rules: [rule, ...(state.rules || [])],
      }));
      return rule.id;
    }
  } catch {}

  return undefined;
};

export const duplicateRule = async (id: string, form: any) => {
  try {
    const response = await api.duplicateRule(id, form);
    if (response.ok) {
      const rule: Rule = await response.json();
      setState((state) => ({
        ...state,
        rules: [rule, ...(state.rules || [])],
      }));
      return rule.id;
    }
  } catch {}

  return false;
};

export const deleteRule = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.deleteRule(id);
    if (response.ok) {
      setState((state) => ({
        ...state,
        rules: state.rules?.filter((f) => f.id !== id),
        isLoading: false,
      }));
      return true;
    }
  } catch {}
  setState((state) => ({ ...state, isLoading: false }));
  return false;
};

export const abortRule = async (id: string) => {
  const state = getState();
  if (!state) {
    return;
  }
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.abortRule(id);
    if (response.ok) {
      const rule: Rule = await response.json();
      setState((state) => ({
        ...state,
        rules: state.rules?.map((item) => (item.id === id ? rule : item)),
        isLoading: false,
      }));
      return true;
    }
  } catch {}
  setState((state) => ({ ...state, isLoading: false }));
  return false;
};

export const useRules = () => {
  const { isAuthenticated } = useAuth();

  const [state] = useStore<State>(StoreId.Rules);
  const { rules, isLoading } = state;

  useEffect(() => {
    const { rules, isLoading } = getState();
    if (isAuthenticated) {
      if (!rules && !isLoading) {
        fetchRules();
      }
    } else if (rules) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    rules,
    isLoading,
  };
};
