import { Form, Button, Alert } from 'antd';
import styled from 'styled-components';

export const FormItem = styled(Form.Item)`
  label {
    font-size: 16px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const ForgotPasswordLink = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;

export const RegisterLink = styled(StyledButton)`
  margin-top: ${({ theme }) => theme.spacing.l};
  color: ${({ theme }) => theme.color.secondary6};
`;

export const Divider = styled.div`
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.neutral5};
  margin: ${({ theme }) => theme.spacing.l} 0;
  height: 0;

  > span {
    background: ${({ theme }) => theme.color.white};
    padding: 0 ${({ theme }) => theme.spacing.m};
    display: inline-block;
    transform: translateY(-50%);
  }
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;
