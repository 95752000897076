import { FC, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Route } from '../../../types/ui/Route';
import { ReactComponent as Integrations } from '../../../assets/illustrations/integrations.svg';
import { IllustratedPage } from '../../../components/illustratedPage/IllustratedPage';
import { Description, FormItem, StyledButton, StyledAlert } from './styled';
import { usePasswordReset } from '../../../hooks/usePasswordReset';
import { useAuth } from '../../../stores/useAuth';
import { usePasswordValidationRules } from '../../../hooks/usePasswordValidationRules';

export const PasswordReset: FC = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation('passwordReset');
  const [form] = Form.useForm();
  const { success, error, isLoading, onSubmit } = usePasswordReset();
  const passwordRules = usePasswordValidationRules();

  useEffect(() => {
    if (token?.user.auth_type === 'integration') {
      navigate(Route.Settings);
    }
  }, [token, navigate]);

  return (
    <IllustratedPage illustration={<Integrations />} title={t('title')}>
      <Description type="secondary">{t('description')}</Description>
      <Form
        form={form}
        layout="vertical"
        onFinish={({ newPassword }) => onSubmit(newPassword)}
        validateMessages={{ required: t('error:form.required') }}
        onValuesChange={() => form.validateFields(['repeatNewPassword'])}
        requiredMark={false}
      >
        {error && (
          <StyledAlert type="error" message={t(`error:${error}`)} showIcon />
        )}
        {success && <Navigate to={Route.PasswordResetSuccess} />}
        <FormItem
          name="newPassword"
          label={t('newPassword')}
          rules={passwordRules}
          hasFeedback
        >
          <Input.Password size="large" />
        </FormItem>
        <FormItem
          name="repeatNewPassword"
          label={t('repeatNewPassword')}
          rules={[
            {
              validator: (_, value) =>
                value === form.getFieldValue('newPassword')
                  ? Promise.resolve()
                  : Promise.reject(t('error:form.password.repeat-mismatch')),
            },
          ]}
          hasFeedback
        >
          <Input.Password size="large" />
        </FormItem>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={isLoading}
          size="large"
        >
          {t('submitButton')}
        </StyledButton>
      </Form>
    </IllustratedPage>
  );
};
