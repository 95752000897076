export enum Route {
  NotFound = '/404',
  AcceptInvite = '/accept-invite',
  Admin = '/admin',
  AdminMatch = '/admin/:id',
  AdminWorkers = '/admin/workers',
  AdminOrganizations = '/admin/organizations',
  AdminAutomations = '/admin/automations',
  AdminAutomationIssues = '/admin/automation-issues',
  AdminNodes = '/admin/nodes',
  AdminInvites = '/admin/invites',
  AdminReports = '/admin/reports',
  AdminRules = '/admin/rules',
  AdminUsers = '/admin/users',
  AdminLogs = '/admin/logs',
  AdminRuns = '/admin/runs',
  AdminJobs = '/admin/jobs',
  Automation = '/automations/:automation_id',
  AutomationBuilder = '/automations/:automation_id/builder',
  Automations = '/automations',
  AutomationFolder = '/automations/folder/:folder_id',
  StorageDataStores = '/storage/datastores',
  Help = '/help',
  ShopifyAuthorize = '/integrations/shopify/authorize',
  AdPlatform = '/ad-platform',
  AdPlatformCustomMetrics = '/ad-platform/custom-metrics',
  AdPlatformLaunch = '/ad-platform/launch',
  CredentialsMatch = '/integrations/:id/:credential_id',
  Integrations = '/integrations',
  IntegrationsMatch = '/integrations/:id',
  Login = '/login',
  MagicLogin = '/magic-login',
  EmailConfirm = '/email-confirm',
  EmailConfirmSuccess = '/email-confirm/success',
  Notifications = '/notifications',
  PasswordLess = '/password-less',
  PasswordLessSuccess = '/password-less/success',
  PasswordReset = '/password-reset',
  PasswordResetSuccess = '/password-reset/success',
  Register = '/register',
  Root = '/',
  Onboarding = '/onboarding',
  Dashboard = '/dashboard',
  Report = '/reports/:id',
  ReportSettings = '/reports/:id/settings',
  ReportView = '/reports/:id/view/:view_id',
  Reports = '/reports',
  RuleEngine = '/rule-engine',
  RuleEngineRule = '/rule-engine/rules/:id',
  RuleEngineRuleActivity = '/rule-engine/rules/:id/activities/:activity_id',
  RuleEngineRules = '/rule-engine/rules',
  RuleEngineActivities = '/rule-engine/activities',
  Settings = '/settings',
  SettingsMatch = '/settings/:id',
  SettingsOrganization = '/settings/organization',
  SettingsUsers = '/settings/users',
  SettingsSecrets = '/settings/secrets',
  SettingsNotifications = '/settings/notifications',
  SettingsDetails = '/settings/details',
  SettingsApi = '/settings/api',
}
