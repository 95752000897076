import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = boolean;

const [getEdgeLoading, setEdgeLoading] = createStore<State>(
  StoreId.EdgeLoading,
  false,
);

export { getEdgeLoading, setEdgeLoading };

export const useEdgeLoading = () => {
  const [loading] = useStore<State>(StoreId.EdgeLoading);
  return loading;
};
