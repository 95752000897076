import { FC } from 'react';
import { Content } from './Content';
import { useConfirmation } from '../../../stores/useConfirmation';

interface Props {
  title?: string;
}

export const ConfirmationModal: FC<Props> = () => {
  const confirmationData = useConfirmation();
  if (!confirmationData) return null;
  return <Content {...confirmationData} />;
};
