import { config } from '../../config';
import { GroupCreateForm } from '../../types/api/automation/GroupCreateForm';
import { ResetGroupSocketForm } from '../../types/api/automation/ResetGroupSocketForm';

import { GroupEditForm } from '../../types/api/automation/GroupEditForm';
import { GroupTogglePublishSocketForm } from '../../types/api/automation/GroupTogglePublishSocketForm';
import { headers } from '../auth/headers';

const getGroups = () =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups`, {
    method: 'GET',
    ...headers(),
  });

const getGroup = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}`, {
    method: 'GET',
    ...headers(),
  });

const createGroup = (form: GroupCreateForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const destroyGroup = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

const updateGroup = (id: string, form: GroupEditForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const publishGroupSocket = (id: string, form: GroupTogglePublishSocketForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}/socket`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const unpublishGroupSocket = (id: string, form: GroupTogglePublishSocketForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}/socket`, {
    method: 'DELETE',
    body: JSON.stringify(form),
    ...headers(),
  });

const ungroupGroup = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}/ungroup`, {
    method: 'DELETE',
    ...headers(),
  });

const resetGroup = (id: string, form: ResetGroupSocketForm) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}/reset`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

const duplicateGroup = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/automation/groups/${id}/duplicate`, {
    method: 'POST',
    ...headers(),
  });

export default {
  duplicateGroup,
  createGroup,
  destroyGroup,
  getGroup,
  getGroups,
  resetGroup,
  publishGroupSocket,
  unpublishGroupSocket,
  ungroupGroup,
  updateGroup,
};
