import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Space } from 'antd';
import { Container } from './styled';

export const ErrorMessage: FC = () => {
  const { t } = useTranslation('genericPageError');
  return (
    <Container>
      <Typography.Title>{t('title')}</Typography.Title>
      <Space direction="vertical">
        <Typography.Text>{t('text')}</Typography.Text>
        <Button type="primary" onClick={() => window.location.reload()}>
          {t('button')}
        </Button>
      </Space>
    </Container>
  );
};
