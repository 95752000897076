import { css, BaseThemedCssFunction } from 'styled-components';
import { theme } from './theme';

export type Breakpoint = 'xxl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';

export const breakpoints: Record<Breakpoint, string> = {
  xxl: '1600px',
  xl: '1440px',
  lg: '992px',
  md: '768px',
  sm: '576px',
  xs: '0px',
};

type Media = Record<Breakpoint, BaseThemedCssFunction<typeof theme>>;

export const media: Media = Object.keys(breakpoints).reduce(
  (acc: Media, label: string) => {
    const breakpoint = label as Breakpoint;
    acc[breakpoint] = (first: any, ...args: any[]) => {
      const v = css(first, ...args);
      return css`
        @media (min-width: ${breakpoints[breakpoint]}) {
          ${v}
        }
      `;
    };

    return acc;
  },
  {} as Media,
);
