import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = string;

const [getAutomationId, setAutomationId] = createStore<State | undefined>(
  StoreId.AutomationId,
  undefined,
);

export { getAutomationId, setAutomationId };

export const useAutomationId = () => {
  const [automationId] = useStore<State>(StoreId.AutomationId);
  return automationId;
};
