import { Notification, NotificationContent } from '../types/ui/Notification';
import { genUUID } from '../util';
import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';

export type State = Notification;

const { getState, setState, useStore } = createStore<State | undefined>(
  StoreId.UiNotifications,
  undefined,
);
const ACTION_TIME = 10000;
const ERROR_TIME = 10000;
const SUCCESS_TIME = 5000;

const timing = (type: 'success' | 'error', hasAction: boolean) => {
  const t = type === 'success' ? SUCCESS_TIME : ERROR_TIME;
  if (hasAction) {
    return t + ACTION_TIME;
  }
  return t;
};

const clear = (id: string) => {
  const state = getState();
  if (!state || state.id !== id) {
    return;
  }
  setState(undefined);
};

const reset = () => setState(undefined);

export const helpers = { timing, clear, reset, genUUID };

export const getNotification = getState;

export const setNotification = (nc: NotificationContent) => {
  const id = helpers.genUUID();
  setState({ ...nc, id, onClick: () => helpers.clear(id) });
  setTimeout(() => clear(id), helpers.timing(nc.type, Boolean(nc.action)));
};

export const useUiNotifications = () => {
  const [notification] = useStore();
  return notification;
};
