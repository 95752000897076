import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = Record<string, boolean>;

const [getNodeLoading, setNodeLoading] = createStore<State>(
  StoreId.NodeLoading,
  {},
);

export { getNodeLoading, setNodeLoading };

export const useNodeLoading = (id?: string) => {
  const [state] = useStore<State>(StoreId.NodeLoading);
  return Boolean(id && state[id]);
};
