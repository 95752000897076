import { FC, Suspense, ComponentType, ReactNode, ComponentProps } from 'react';
import { ErrorBoundary } from '../../errorBoundary/ErrorBoundary';

export const AsyncComponent =
  <T extends Object>(
    Component: ComponentType<T>,
    fallback: ReactNode = null,
  ): FC<ComponentProps<typeof Component>> =>
  (props) =>
    (
      <Suspense fallback={fallback}>
        <ErrorBoundary>
          <Component {...props} />
        </ErrorBoundary>
      </Suspense>
    );
