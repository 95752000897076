import { RuleObject } from 'antd/lib/form';
import { useTranslation } from 'react-i18next';

const hasNumber = (password: string) => /\d/.test(password);
const hasLowerCase = (password: string) => /[a-z]/.test(password);
const hasUpperCase = (password: string) => /[A-Z]/.test(password);
const hasSymbol = (password: string) => /[^a-zA-Z0-9]/.test(password);
const hasMinimumLength = (password: string) => password.length >= 8;

export const usePasswordValidationRules = () => {
  const { t } = useTranslation();
  const rules: RuleObject[] = [
    { required: true },
    {
      validator: (_, value) =>
        !value || hasMinimumLength(value)
          ? Promise.resolve()
          : Promise.reject(t('error:form.password.length')),
    },
    {
      validator: (_, value) =>
        !value || hasNumber(value)
          ? Promise.resolve()
          : Promise.reject(t('error:form.password.no-number')),
    },
    {
      validator: (_, value) =>
        !value || hasLowerCase(value)
          ? Promise.resolve()
          : Promise.reject(t('error:form.password.no-lowercase')),
    },
    {
      validator: (_, value) =>
        !value || hasUpperCase(value)
          ? Promise.resolve()
          : Promise.reject(t('error:form.password.no-uppercase')),
    },
    {
      validator: (_, value) =>
        !value || hasSymbol(value)
          ? Promise.resolve()
          : Promise.reject(t('error:form.password.no-symbol')),
    },
  ];
  return rules;
};
