import { Token } from '../types/api/auth/Token';

type EventType =
  | 'Account Created'
  | 'Register'
  | 'Link To Register'
  | 'Registered'
  | 'Logged In'
  | 'Login With Facebook'
  | 'Login With Google'
  | 'Signed Out'
  | 'Account Added User'
  | 'Integration Created'
  | 'Automation Created'
  | 'Automation Deleted'
  | 'Automation Duplicated'
  | 'Node Clicked'
  | 'Automation Published'
  | 'Automation Scheduled'
  | 'Automation Run'
  | 'Report::Report Created'
  | 'Report::Report Duplicated'
  | 'Report::Report Updated'
  | 'Report::Report Deleted'
  | 'Report::View::Field Added'
  | 'Report::View::Field Removed'
  | 'Report::View::Fields ChangedOrder'
  | 'Report::View::Filters Changed'
  | 'Report::View::DateRange Changed'
  | 'Report::View::SortDirection Changed'
  | 'Report::View::Pagination Changed'
  | 'Report::View::GroupBy Changed'
  | 'Report::View Renamed'
  | 'Report::View Updated'
  | 'Report::View Created'
  | 'Report::View Duplicated'
  | 'Report::View Deleted'
  | 'Report::Value Created'
  | 'Report::Value Updated'
  | 'Report::Value Deleted'
  | 'Report::Metric Created'
  | 'Report::Metric Updated'
  | 'Report::Metric Deleted'
  | 'Report::Dimension Created'
  | 'Report::Dimension Updated'
  | 'Report::Dimension Deleted'
  | 'Report::DataSource Created'
  | 'Report::DataSource Updated'
  | 'Report::DataSource Deleted'
  | 'RuleEngine::Rule Created'
  | 'RuleEngine::Rule Updated'
  | 'RuleEngine::Rule Duplicated'
  | 'RuleEngine::Rule Deleted'
  | 'RuleEngine::Rule Aborted'
  | 'RuleEngine::Rule Paused'
  | 'RuleEngine::Rule Activated'
  | 'AdPlatform::CustomMetric Created'
  | 'AdPlatform::CustomMetric Updated'
  | 'AdPlatform::CustomMetric Deleted';
type Property =
  | 'type'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'
  | 'username'
  | 'title'
  | 'platform'
  | 'recipeName'
  | 'name'
  | 'schedule'
  | 'buttonType'
  | 'id'
  | 'rule_id'
  | 'rule_title'
  | 'report_id'
  | 'report_title'
  | 'view_id'
  | 'view_title'
  | 'field_identifier'
  | 'field_name'
  | 'filter_field'
  | 'filter_operator'
  | 'filter_value'
  | 'date_preset';

type Properties = { [K in Property]?: any };
type Context = Record<'groupId', string>;

export const trackIdentity = (token?: Token) => {
  if (process.env.NODE_ENV === 'test') return;
  if (!token) throw new Error('No token for tracking available');
  if (token.user.role === 'god') return;
  window.analytics?.identify(token.user.id, {
    name: token.user.name,
    email: token.user.email,
    context: {
      companyId: token.organization.id,
      groupId: token.organization.id,
      organization_id: token.organization.id,
    },
  });
};

export const trackGroup = (token?: Token) => {
  if (process.env.NODE_ENV === 'test') return;
  if (!token) throw new Error('No token for tracking available');
  window.analytics?.group(token.organization.id, {
    name: token.organization.name,
    plan: token.organization.plan,
  });
};

export const trackPage = (page: string) => {
  if (process.env.NODE_ENV === 'test') return;
  window.analytics?.page(page);
};

export const trackEvent = (
  event: EventType,
  token?: Token,
  properties?: Properties,
  context?: Context,
) => {
  if (process.env.NODE_ENV === 'test') return;
  if (token?.user.role === 'god') return;
  const groupId = token?.organization.id;
  window.analytics?.track(event, properties, {
    context: { ...context, groupId },
  });
};
