import { config } from '../../config';
import { OrganizationForm } from '../../types/api/entity/OrganizationForm';
import { headers } from '../auth/headers';

const getOrganizations = (params: URLSearchParams) =>
  fetch(`${config.apiBaseUrl}/v1/admin/organizations?${params.toString()}`, {
    method: 'GET',
    ...headers(),
  });

const updateOrganization = (id: string, form: OrganizationForm) =>
  fetch(`${config.apiBaseUrl}/v1/admin/organizations/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

const deleteOrganization = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/admin/organizations/${id}`, {
    method: 'DELETE',
    ...headers(),
  });

export default { getOrganizations, deleteOrganization, updateOrganization };
