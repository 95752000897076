import { useEffect, useMemo } from 'react';
import api from '../api/processor/processors';
import { Processors, Processor } from '../types/api/processor/Processor';
import { setNotification } from './useUiNotifications';
import { StoreId } from '../types/ui/StoreId';
import { useStore, createStore } from 'usestore-react';
import { useAuth } from '../stores/useAuth';

const toArray = (processors?: Processors): Processor[] | undefined => {
  if (!processors) return undefined;
  // let's stort by title for now
  const sortedKeys = Object.keys(processors).sort((a, b) =>
    processors[a].title > processors[b].title ? 1 : -1,
  );
  // build an sorted array
  return sortedKeys.map((k) => processors[k]);
};

export type State = {
  processors?: Processors;
  processorlist?: Processor[];
  isLoading?: boolean;
};

const { setState, getState } = createStore<State>(StoreId.Processors, {});

export const fetchProcessors = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getProcessors();
    if (response.ok) {
      setState({ processors: await response.json() });
      return true;
    }
  } catch {}

  setState((state) => ({ processors: state.processors }));
  setNotification({
    entity: 'processor',
    type: 'error',
    description: 'An error occured on fetching processors.',
  });
  return false;
};

export const useProcessors = () => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore<State>(StoreId.Processors);
  const { isLoading, processors } = state;

  useEffect(() => {
    const { isLoading, processors } = getState();
    if (isAuthenticated) {
      if (!processors && !isLoading) {
        fetchProcessors();
      }
    } else if (processors) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    isLoading,
    processors,
    processorlist: useMemo(() => toArray(processors), [processors]),
  };
};
