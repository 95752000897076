import { useEffect } from 'react';
import { wait } from '../util/wait';
import api from '../api/automation/automations';
import { useAuth } from '../stores/useAuth';
import { Automation } from '../types/api/automation/Automation';
import { Error } from '../types/api/Error';
import { Run, StartRunForm } from '../types/api/automation/Run';
import { AutomationCreateData } from '../types/api/automation/AutomationCreateForm';
import { AutomationUpdateForm } from '../types/api/automation/AutomationUpdateForm';
import { setNotification } from './useUiNotifications';
import { createStore, useStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';

export type State = {
  automations?: Automation[];
  isLoading?: boolean;
};

const { getState, setState } = createStore<State>(StoreId.Automations, {});

export const fetchAutomations = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getAutomations();
    if (response.ok) {
      setState({ automations: await response.json() });
      return true;
    }
  } catch {}

  setState(({ automations }) => ({ automations }));
  setNotification({
    type: 'error',
    entity: 'automations',
    description: 'An error occured on fetching automations.',
  });
  return false;
};

export const deleteAutomation = async (id: string) => {
  // start deletion
  const t0 = performance.now();

  try {
    const response = await api.deleteAutomation(id);
    if (response.ok) {
      const t1 = performance.now();
      await wait(Math.max(1000 - (t1 - t0), 0));
      setState((state) => ({
        automations: state.automations?.filter((item) => item.id !== id),
      }));

      setNotification({
        type: 'success',
        entity: 'automation',
        description: 'Automation is successfully deleted.',
      });
      return true;
    }
  } catch {}

  setState((state) => ({ automations: state.automations }));

  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured on deletion of automation.',
  });
  return false;
};

export const abortAutomation = async (id: string) => {
  try {
    const response = await api.abortAutomation(id);
    if (response.ok) {
      const run: Run = await response.json();
      setAutomationFromRun(run);
      return true;
    }
  } catch {}

  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured on aborting of automation.',
  });
  return false;
};

export const updateAutomationRunDetails = (
  automation: Automation,
  run: Run,
) => {
  if (run.status === 'running') {
    return {
      ...automation,
      current_run_id: run.id,
      latest_run_id: automation.current_run_id,
    };
  }
  return {
    ...automation,
    current_run_id: undefined,
    latest_run_id: run.id,
  };
};

const setAutomationFromRun = (run: Run) => {
  setState((state) => ({
    automations: state.automations?.map((item) =>
      item.id === run.automation_id
        ? updateAutomationRunDetails(item, run)
        : item,
    ),
  }));
};

export const setAutomation = (automation: Automation) =>
  setState((state) => ({
    automations: state.automations?.map((item) =>
      item.id === automation.id ? automation : item,
    ),
  }));

export const runAutomation = async (id: string, form?: StartRunForm) => {
  try {
    const response = await api.runAutomation(id, form);
    if (response.ok) {
      const run: Run = await response.json();
      setAutomationFromRun(run);
      return true;
    }
  } catch {}

  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occurred on starting of automation.',
  });
  return false;
};

export const updateAutomation = async (
  id: string,
  form: AutomationUpdateForm,
) => {
  try {
    const response = await api.updateAutomation(id, form);
    if (response.ok) {
      const automation: Automation = await response.json();
      setAutomation(automation);
      return true;
    }
  } catch {}
  setNotification({
    type: 'error',
    entity: 'automation',
    description: 'An error occured on updating the automation.',
  });
  return false;
};

export const duplicateAutomation = async (id: string) => {
  setState((state) => ({ ...state }));
  try {
    const response = await api.duplicateAutomation(id);
    if (response.ok) {
      const automation: Automation = await response.json();

      setState((state) => ({
        automations: [automation, ...(state.automations || [])],
        isLoading: false,
      }));

      return automation.id;
    }

    const error: Error = await response.json();
    return error;
  } catch {
    setState((state) => ({ ...state }));
  }

  return { code: 'unknown', error: 'Unknown error' };
};

export const createAutomation = async (data: AutomationCreateData) => {
  try {
    const response = await api.createAutomation(data);
    if (response.ok) {
      const automation: Automation = await response.json();

      setState((state) => ({
        automations: [automation, ...(state.automations || [])],
      }));

      return automation.id;
    }

    const error: Error = await response.json();
    return error;
  } catch {}

  return { code: 'unknown', error: 'Unknown error' };
};

export const useAutomations = () => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore<State>(StoreId.Automations);
  const { automations, isLoading } = state;

  useEffect(() => {
    const { automations, isLoading } = getState();
    if (isAuthenticated) {
      if (!automations && !isLoading) {
        fetchAutomations();
      }
    } else if (automations) {
      setState({});
    }
  }, [isAuthenticated]);

  return {
    automations,
    isLoading,
  };
};
