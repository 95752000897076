import { useEffect, useState } from 'react';
import api from '../api/entity/invites';
import { Invite } from '../types/api/entity/Invite';
import { Error } from '../types/api/Error';
import { humanizeError } from '../util/humanizeError';

interface State {
  data?: Invite;
  isLoading?: boolean;
  error?: string;
}

export const useInvite = (id?: string | null) => {
  const [state, setState] = useState<State>({});
  const { data, isLoading, error } = state;

  useEffect(() => {
    if (!id) {
      setState({ error: 'invite/not_found' });
      return;
    }
    const fetch = async (id: string) => {
      setState({ isLoading: true });
      try {
        const response = await api.getInvite(id);
        const json = await response.json();
        if (response.ok) {
          setState({ data: json });
        } else {
          setState({ error: humanizeError(json as Error) });
        }
      } catch {
        setState({ error: 'generic' });
      }
    };
    if (!data && !isLoading) {
      fetch(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, isLoading, error };
};
