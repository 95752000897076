import { Coordinates } from '../../../../types/api/automation/Graph';

/**
 * Returns a middle point through which a line could be drawn to be able to
 * connect a and b points using only straight non-diagonal lines.
 * @param a
 * @param b
 * @returns
 */
export const getMiddlePoint = (a: Coordinates, b: Coordinates) =>
  Math.abs(a.x - b.x) < Math.abs(a.y - b.y)
    ? { ...a, y: b.y }
    : { ...a, x: b.x };
