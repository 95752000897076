import { useEffect } from 'react';
import api from '../api/notifications';
import { Notification } from '../types/api/Notification';
import { NotificationForm } from '../types/api/NotificationForm';
import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';
import { useAuth } from '../stores/useAuth';

export interface State {
  notifications?: Notification[];
  isLoading?: boolean;
}

const { setState, useStore, getState } = createStore<State>(
  StoreId.Notifications,
  {},
);

export const fetchNotifications = async () => {
  setState((state) => ({ ...state, isLoading: true }));
  try {
    const response = await api.getNotifications();
    if (response.ok) {
      setState({ notifications: await response.json() });
      return true;
    }
  } catch {}

  setState(({ notifications }) => ({ notifications }));
  return false;
};

export const updateNotification = async (
  id: string,
  form: NotificationForm,
) => {
  const { notifications } = getState();
  const initialNotification = notifications?.find(
    (notification) => notification.id === id,
  );
  if (!initialNotification) {
    return true;
  }
  // optimistic update
  setState(({ notifications }) => ({
    notifications: notifications?.map((notification) =>
      notification.id === id ? { ...notification, ...form } : notification,
    ),
  }));
  try {
    const response = await api.updateNotification(id, form);
    if (response.ok) {
      return true;
    }
  } catch {}

  setState(({ notifications }) => ({
    notifications: notifications?.map((notification) =>
      notification.id === id ? initialNotification : notification,
    ),
  }));

  return false;
};

export const updateNotifications = (notification: Notification) =>
  setState(({ notifications }) => ({
    notifications: [...(notifications || []), notification],
  }));

export const markAllAsRead = async () => {
  const { notifications = [] } = getState();
  const unread = notifications.filter(({ read }) => !read);
  if (unread.length < 1) {
    return true;
  }
  setState({
    notifications: notifications.map((notification) => ({
      ...notification,
      read: true,
    })),
  });
  const ids = unread.map(({ id }) => id);
  try {
    const response = await api.batchUpdateNotification(ids);
    if (response.ok) {
      await response.json();
      return true;
    }
  } catch {}
  setState({ notifications });
  return false;
};

export const useNotifications = () => {
  const { isAuthenticated } = useAuth();
  const [state] = useStore();
  const { isLoading, notifications } = state;

  useEffect(() => {
    const { isLoading, notifications } = getState();
    if (isAuthenticated) {
      if (!notifications && !isLoading) {
        fetchNotifications();
      }
    } else if (notifications) {
      setState({});
    }
  }, [isAuthenticated]);

  return { notifications, isLoading };
};
