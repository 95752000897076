import { FC, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Route as RouteType } from '../types/ui/Route';
import { useAuth } from '../stores/useAuth';
import { isPathAllowed } from '../util/auth/isPathAllowed';

const customRedirectPath = (search: string) => {
  const params = new URLSearchParams(search);
  const redirect_url = params.get('redirect_url');
  if (!redirect_url) return;

  const uri = new URL(redirect_url);
  return `${uri.pathname}${uri.search}`;
};

export const PublicRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isInitialized, isAuthenticated, allowedRoutes } = useAuth();
  const { search } = useLocation();

  if (!isInitialized) {
    // TODO: do we want to display the loader the same way PrivateRoute does?
    return null;
  }
  if (isAuthenticated) {
    const path = customRedirectPath(search);
    const toRoute = path || RouteType.Automations;

    if (isPathAllowed(allowedRoutes, toRoute)) {
      return <Navigate to={toRoute} />;
    }

    return <Navigate to={allowedRoutes[0]} />;
  }
  return <>{children}</>;
};
