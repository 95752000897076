import styled from 'styled-components';
import { Typography } from 'antd';
import { media } from '../../styles/media';

export const Page = styled.div`
  flex-grow: 1;
  display: grid;

  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const Illustration = styled.div`
  background: ${({ theme }) => theme.color.primary1};
  display: none;
  ${media.md`
    display: grid;
    place-items: center end;

    > svg {
      max-width: 960px;
    }
  `}
`;

export const Container = styled.div`
  display: grid;
  place-items: center;
  max-width: 960px;

  padding: ${({ theme }) => theme.spacing.m};
  ${media.md`
    padding: ${({ theme }) => theme.spacing.l};
  `}

  > div {
    display: flex;
    flex-direction: column;
    place-items: center;
    width: 100%;
    max-width: 400px;

    > * {
      width: 100%;
    }
  }
`;

export const Title = styled(Typography.Title)`
  text-align: center;
  && {
    margin: ${({ theme }) => theme.spacing.l} 0;
  }
`;
