import { UserOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { FC } from 'react';
import { updateToken } from '../../../stores/admin/useAuth';
import {
  useOrganizations,
  fetchOrganizations,
} from '../../../stores/admin/useOrganizations';
import { useAuth } from '../../../stores/useAuth';
import { Token } from '../../../types/api/auth/Token';
import { OrgSwitcherMenu } from './styled';
import { buildQueryParams } from '../../../util/admin/buildQueryParams';

export const OrganizationSwitcher: FC = () => {
  const { token, isGod } = useAuth();
  if (!token || !isGod) return null;

  return <OrganizationSwitcherMenu token={token} />;
};

const onSearch = (
  value: string,
  event?:
    | React.ChangeEvent<HTMLInputElement>
    | React.MouseEvent<HTMLElement>
    | React.KeyboardEvent<HTMLInputElement>,
) => {
  event?.stopPropagation();
  event?.preventDefault();

  fetchOrganizations(buildQueryParams({ name: value }));
};

const OrganizationSwitcherMenu: FC<{ token: Token }> = ({ token }) => {
  const { organizations } = useOrganizations();

  const sortedOrganizations = organizations?.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  );
  const ownOrgId = token?.home_organization?.id;
  const ownOrgSelected =
    !token?.home_organization || ownOrgId === token?.organization.id;

  const sortedOrganizationsArray = sortedOrganizations ?? []; // Default to empty array if falsy

  const organization_items = sortedOrganizationsArray.map(({ id, name }) => ({
    key: id,
    onClick: () => updateToken(token.id, { organization_id: id }),
    label: id === ownOrgId ? <b>{name}</b> : name,
  }));

  const submenuItems = [
    {
      key: 'name-search',
      label: (
        <Input.Search
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onSearch={onSearch}
        />
      ),
    },
    ...organization_items,
  ];

  const menuItems = [
    {
      key: token.organization.name,
      icon: <UserOutlined />,
      label: token.organization.name,
      children: submenuItems,
    },
  ];

  return (
    <OrgSwitcherMenu
      key="organization"
      $inverse={!ownOrgSelected}
      items={menuItems}
    />
  );
};
