import { ConfirmationData } from '../types/ui/ConfirmationData';

import { StoreId } from '../types/ui/StoreId';
import { useStore, createStore } from 'usestore-react';

export type State = ConfirmationData;

const [, setConfirmation] = createStore<State | undefined>(
  StoreId.Confirmation,
  undefined,
);

export { setConfirmation };

export const useConfirmation = () => {
  const [state] = useStore<State>(StoreId.Confirmation);
  return state;
};
