import styled, { css } from 'styled-components';
import { media } from '../../../styles/media';

interface ContainerProps {
  disabled?: boolean;
}

export const Container = styled.input<ContainerProps>`
  width: 100%;
  height: 46px;
  padding: 0 ${({ theme }) => theme.spacing.s};
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.lightest};

  color: ${({ theme }) => theme.color.darkest};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  letter-spacing: 0.4px;
  appearance: none;
  transition: border-color 0.3s;

  ${media.md`
    font-size: 20px;
  `}

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.grey};
      background-color: ${({ theme }) => theme.color.backgroundGrey};
      border-color: ${({ theme }) => theme.color.lightGrey};
    `}

    ${({ disabled }) =>
    !disabled &&
    css`
      outline: none;
      :focus,
      :hover {
        border-color: ${({ theme }) => theme.color.darkGrey};
      }
    `}


  /* The placeholder styles cannot be combined because otherwise the browsers dont recognize them */
  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${({ theme }) => theme.color.lightGrey};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.lightGrey};
  }
  ::placeholder {
    /* Most modern browsers support this now. */
    color: ${({ theme }) => theme.color.lightGrey};
  }
`;
