import { FC } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Route } from '../../../types/ui/Route';
import { ReactComponent as Integrations } from '../../../assets/illustrations/integrations.svg';
import { IllustratedPage } from '../../../components/illustratedPage/IllustratedPage';
import { usePasswordLess } from '../../../hooks/usePasswordLess';
import {
  BackLink,
  Description,
  FormItem,
  StyledButton,
  StyledAlert,
} from './styled';

export const PasswordLess: FC = () => {
  const { t } = useTranslation('passwordLess');
  const { error, isLoading, onSubmit, success } = usePasswordLess();
  return (
    <IllustratedPage illustration={<Integrations />} title={t('title')}>
      <Description type="secondary">{t('description')}</Description>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        validateMessages={{ required: t('error:form.required') }}
        requiredMark={false}
      >
        {error && <StyledAlert type="error" message={error} showIcon />}
        {success && <Navigate to={Route.PasswordLessSuccess} />}
        <FormItem
          name="email"
          label={t('email')}
          rules={[
            { required: true },
            { type: 'email', message: t('error:form.email.invalid') },
          ]}
          hasFeedback
        >
          <Input size="large" />
        </FormItem>
        <StyledButton
          type="primary"
          htmlType="submit"
          loading={isLoading}
          size="large"
        >
          {t('submitButton')}
        </StyledButton>
      </Form>
      <Link to={Route.Login}>
        <BackLink type="link" icon={<ArrowLeftOutlined />}>
          {t('login')}
        </BackLink>
      </Link>
    </IllustratedPage>
  );
};
