import {
  Coordinates,
  SocketId,
  Groups,
  Nodes,
} from '../types/api/automation/Graph';
import { ElementId } from '../types/ui/ElementId';
import { StoreId } from '../types/ui/StoreId';
import { useStore, createStore } from 'usestore-react';
import { getNode, createEdge } from './useGraph';
import { createRect } from '../util/createRect';
import { Connecting } from '../types/ui/Connecting';
import { Connect } from '../types/ui/Connect';
import { findElementsInRect } from '../util/findElementsInRect';
import { isConnectableOutput } from '../util/graph/isConnectableOutput';
import { isConnectableInput } from '../util/graph/isConnectableInput';
import { AnySubType } from '../types/ui/AnySubType';
import { getMiddlePoint } from '../components/graphEditor/edges/utils/getMiddlePoint';

export interface State {
  connecting?: Connecting;
  cursor?: Coordinates;
  editElementId?: ElementId;
  editSocketId?: string;
  hoveredEdgeId?: string;
  selectedElementIds?: ElementId[];
  selecting?: Coordinates;
  inspectSocketId?: SocketId;
  latestFetchedNodeId?: string;
  socketData?: any;
  socketUiState?: AnySubType;
}

const { setState, getState, reset } = createStore<State>(
  StoreId.EditorActions,
  {},
);

const modifySelectedElementIds = (
  shiftKey: boolean,
  selectedElementIds?: ElementId[],
  elementId?: ElementId,
) => {
  if (!elementId) return;
  if (!shiftKey || !selectedElementIds) return [elementId];
  if (
    selectedElementIds.some(
      (item) => item.klass === elementId.klass && item.id === elementId.id,
    )
  ) {
    return selectedElementIds.filter((item) => item.id !== elementId.id);
  }
  return [...selectedElementIds, elementId];
};

export const resetSelecting = () => setState(({ connecting, ...rest }) => rest);

export { reset };

export const getSelectedElementIds = () => getState().selectedElementIds;

export const setSelectedElementIds = (
  shiftKey: boolean,
  elementId?: ElementId,
) => {
  const state = getState();
  const selectedElementIds = modifySelectedElementIds(
    shiftKey,
    state.selectedElementIds,
    elementId,
  );

  if (selectedElementIds && selectedElementIds.length > 1) {
    setState({ selectedElementIds });
    return;
  }
  setState({ ...state, selectedElementIds });
};

export const finalizeSelecting = (nodes?: Nodes, groups?: Groups) => {
  const { selecting, cursor, ...rest } = getState();
  if (!selecting || !cursor || !nodes) {
    return;
  }

  const selectedElementIds = findElementsInRect(
    createRect(cursor, selecting),
    nodes,
    groups,
  );

  setState({
    ...rest,
    selectedElementIds,
  });
};

export const addPoint = () =>
  setState(({ connecting, cursor, ...rest }) => {
    if (!connecting || !cursor) {
      return { ...rest };
    }
    const { points = [] } = connecting;
    const lastPoint = points[points.length - 1] || connecting;
    const middle = getMiddlePoint(lastPoint, cursor);
    return {
      ...rest,
      cursor,
      connecting: { ...connecting, points: [...points, middle, cursor] },
    };
  });

export const setEditElementId = (editElementId?: ElementId) =>
  setState(({ inspectSocketId, editSocketId, ...rest }) => ({
    ...rest,
    editElementId,
  }));

export const setInspectSocketId = (
  elementId: ElementId,
  inspectSocketId: SocketId,
) =>
  setState(({ editSocketId, ...rest }) => ({
    ...rest,
    inspectSocketId,
    selectedElementIds: [elementId],
    editElementId: elementId,
  }));

export const clearSocketId = () =>
  setState(({ socketData, socketUiState, editElementId, ...rest }) => rest);

export const setEditSocketId = (
  elementId: ElementId,
  editSocketId: string,
  node_id: string,
) => {
  const { latestFetchedNodeId } = getState();

  setState(({ socketData, inspectSocketId, ...rest }) => ({
    ...rest,
    latestFetchedNodeId: node_id,
    editElementId: elementId,
    editSocketId,
    selectedElementIds: [elementId],
  }));

  // Fetch node when not already fetched
  if (latestFetchedNodeId !== node_id) {
    getNode(node_id, { use_caching: true });
  }
};

export const setHoveredEdgeId = (hoveredEdgeId?: string) =>
  setState((state) => ({ ...state, hoveredEdgeId }));

export const setCursor = (cursor: Coordinates) =>
  setState((state) => ({ ...state, cursor }));

export const startSelecting = (selecting: Coordinates) =>
  setState((state) => ({
    ...state,
    selecting,
    cursor: selecting,
  }));

export const startConnecting = (connecting: Connecting) =>
  setState(({ selecting, ...rest }) => ({ ...rest, connecting }));

export const connect = (c: Connect, automation_id: string) => {
  const { connecting, cursor } = getState();

  if (
    // if connecting is not started stop
    !connecting ||
    // if connect socket is the same as the selected in connection stop
    (connecting.input && c.input) ||
    (connecting.output && c.output)
  ) {
    return setState(({ connecting, cursor, ...rest }) => rest);
  }

  // validation of schemas
  if (
    !(
      (c.output && isConnectableOutput(c.output, connecting)) ||
      (c.input && isConnectableInput(c.input, connecting))
    )
  ) {
    return setState(({ connecting, cursor, ...rest }) => rest);
  }

  // finish connection and check if input and output is set
  const { input, output, points = [] } = { ...connecting, ...c };

  if (!output || !input) return;

  if (points.length > 0 && cursor) {
    const middle = getMiddlePoint(points.at(-1)!, cursor);
    points.push(middle);
  }

  if (c.input) {
    // normally we start from input to output so if we finalize
    // connection with an input then we must reverse the points
    points.reverse();
  }

  // create Connection
  createEdge({
    automation_id,
    input_id: input.id,
    output_id: output.id,
    points,
  });
  // clean up connection when done
  setState(({ connecting, cursor, ...rest }) => rest);
};

export const setSocketData = (socketData: any) =>
  setState((state) => ({ ...state, socketData }));

export const setSocketUiState = (socketUiState: any) =>
  setState((state) => ({ ...state, socketUiState }));

export const useEditorActions = () => {
  const [state] = useStore<State>(StoreId.EditorActions);
  return state;
};
