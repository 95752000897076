import { FC, PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { Route as RouteType } from '../types/ui/Route';
import { useAuth } from '../stores/useAuth';
import { Spinner } from '../components/spinner/Spinner';

export const AdminRoute: FC<PropsWithChildren> = ({ children }) => {
  const { isInitialized, isAuthenticated, isGod } = useAuth();
  if (!isInitialized) {
    return <Spinner />;
  }
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{ pathname: RouteType.Login, search: window.location.search }}
      />
    );
  }
  if (!isGod) {
    <Navigate
      to={{ pathname: RouteType.Root, search: window.location.search }}
    />;
  }
  return <>{children}</>;
};
