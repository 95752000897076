import { FC } from 'react';
import { Content } from './Content';
import {
  useNewAutomationTags,
  setAutomationId,
} from '../../../../stores/useNewAutomationTags';

interface Props {
  title?: string;
}

export const NewModal: FC<Props> = ({ title }) => {
  const [automationId] = useNewAutomationTags();
  if (automationId === undefined) return null;
  return (
    <Content
      onClose={() => setAutomationId(undefined)}
      title={title}
      automationId={automationId}
    />
  );
};
