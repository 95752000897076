import { FC, useState, useEffect, ChangeEvent, useRef } from 'react';
import { usePlatform } from '../../hooks/usePlatform';
import {
  Container,
  Placeholder,
  Title,
  OptionsContainer,
  Option as OptionView,
  SelectContainer,
  Spinner,
} from './styled';
import { ReactComponent as ChevronDown } from '../../assets/ui/chevronDown.svg';
import { NativeSelect } from './NativeSelect';
import { TestAttribute } from '../../types/ui/TestAttribute';
import { Checkbox } from '../checkbox/Checkbox';
import { useClickOutside } from 'use-events';

export interface Option<T extends string = string> {
  value: T;
  /**
   * Display value of the option. If not provided will use value as the label.
   */
  label?: string;
  disabled?: boolean;
}

export interface SelectProps<T extends string = string> {
  name?: string;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  placeHolder?: string;
  test?: HTMLSelectElement;
  options: Option<T>[];
  multiple?: boolean;
  small?: boolean;
  required?: boolean;
  selectedValues?: T[];
  onChange?: ((selectedValue: T) => any) | ((selectedValues: T[]) => any);
  onBlur?: () => void;
}

export const Select: FC<SelectProps> = ({
  options,
  loading,
  disabled,
  selectedValues = [],
  placeHolder,
  onChange: onChangeCallback,
  onBlur,
  multiple,
  name,
  className,
  small,
}) => {
  const { desktop } = usePlatform();
  const [open, setOpenState] = useState<boolean>(false);
  const [selected, setSelected] = useState<string[]>(selectedValues);
  const setOpen = (openState: boolean) => {
    if (!openState && onBlur) {
      onBlur();
    }
    setOpenState(openState);
  };
  const ref = useRef(null);
  useClickOutside([ref], () => desktop && open && setOpen(false));

  const isSelected = (value: string) => selected.includes(value);

  const selectedOptions = options.filter(({ value }) => isSelected(value));
  const label = selectedOptions
    .map(({ label, value }) => label || value)
    .join(', ');

  const toggle = () => desktop && setOpen(!open);

  const onChange = ({ target }: ChangeEvent<HTMLSelectElement>) =>
    multiple
      ? updateValues(
          new Array(target.selectedOptions.length)
            .fill(null)
            .map((_, index) => target.selectedOptions[index].value),
        )
      : changeSelected(target.value);

  const changeSelected = (value: string) => {
    if (!multiple) {
      updateValues([value]);
      return;
    }
    if (selected.includes(value)) {
      updateValues(selected.filter((val) => val !== value));
    } else {
      updateValues([...selected, value]);
    }
  };
  const updateValues = (values: string[]) => {
    setSelected(values);
    if (onChangeCallback) {
      type Callback = (value: string | string[]) => any;
      (onChangeCallback as Callback)(multiple ? values : values[0]);
    }
    if (!multiple && open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (selectedValues !== selected) {
      setSelected(selectedValues);
    }
  }, [selectedValues, selected]);

  return (
    <Container
      selected={selected.length > 0}
      {...{ ref, open, className, disabled }}
    >
      <SelectContainer
        small={small}
        onClick={toggle}
        data-test-id={TestAttribute.SelectContainer}
      >
        {selected.length < 1 && (
          <Placeholder ellipsis>{placeHolder}</Placeholder>
        )}
        {selected.length > 0 && <Title ellipsis>{label}</Title>}
        <NativeSelect
          {...{ onChange, onBlur, options, selected, multiple, name, disabled }}
        />
        <ChevronDown />
        <Spinner isVisible={loading!} />
      </SelectContainer>
      {desktop && open && (
        <OptionsContainer
          data-test-id={TestAttribute.SelectOptions}
          small={small}
          className="options"
        >
          {options.map(({ value, label, disabled }) => (
            <OptionView
              key={value}
              value={value}
              onClick={() => !disabled && changeSelected(value)}
              selected={isSelected(value)}
              disabled={disabled}
            >
              {multiple && (
                <Checkbox checked={isSelected(value)} disabled={disabled} />
              )}
              <Title ellipsis>{label || value}</Title>
            </OptionView>
          ))}
        </OptionsContainer>
      )}
    </Container>
  );
};
