import { Token } from '../types/api/auth/Token';
import { Error } from '../types/api/Error';
import { postToken, postUser, deleteToken, getToken } from '../api/auth/auth';
import { matchPath } from 'react-router-dom';
import { trackGroup, trackIdentity } from '../util/trackEvent';
import { RegisterForm } from '../types/api/auth/RegisterForm';
import { LoginForm } from '../types/api/auth/LoginForm';
import { createStore } from 'usestore-react';
import { StoreId } from '../types/ui/StoreId';
import { humanizeError } from '../util/humanizeError';
import { Route } from '../types/ui/Route';
import { config } from '../config';
import { SetStateAction } from 'react';

const PolicyActionMap: Record<string, Route> = {
  'report:index': Route.Reports,
};

export interface AuthState {
  token?: Token;
  error?: string;
  isLoading?: boolean;
}

const [getAutoLoginState, setAutoLoginState, useAutoLoginState] = createStore(
  StoreId.AuthAutoInitialize,
  false,
);
const { getState, setState, useStore } = createStore<AuthState>(StoreId.Auth, {
  isLoading: true,
});

export const setToken = (actionOrToken: SetStateAction<AuthState['token']>) =>
  setState((state) => {
    const token =
      typeof actionOrToken === 'function'
        ? actionOrToken(state.token)
        : actionOrToken;

    if (config.useAuthorizationHeader && token) {
      sessionStorage.setItem('token_id', token.id);
    }

    if (token) {
      // eslint-disable-next-line no-console
      console.debug('User logged in as:', token.user);
    }

    return { token };
  });

export const loginFromForm = async (form: LoginForm) => {
  setState({ isLoading: true });
  try {
    const response = await postToken(form);
    if (response.ok) {
      const token: Token = await response.json();
      setToken(token);
    } else {
      const error: Error = await response.json();
      setState({ error: humanizeError(error) });
    }
  } catch {
    setState({ error: 'An error occured while trying to login' });
  }
};

export const register = async (form: RegisterForm) => {
  setState({ isLoading: true });
  try {
    const response = await postUser(form);
    if (response.ok) {
      const token: Token = await response.json();
      setToken(token);
    } else {
      const error: Error = await response.json();
      setState({ error: humanizeError(error) });
    }
  } catch {
    setState({ error: 'An error occured while trying to register' });
  }
};

export const logout = async () => {
  const { token } = getState();
  if (!token) {
    return;
  }
  // Logout must always succeed for the user
  setState({});
  try {
    deleteToken('cookie');
    sessionStorage.removeItem('token_id');
  } catch {
    // We could log the issue to some service, but the user must never see this and for him the token is lost
  }
};

const tokenParams = (pathname: string) => {
  const folderMatch = matchPath(
    { path: Route.AutomationFolder, end: false },
    pathname,
  );
  if (folderMatch) {
    const folder_id = folderMatch?.params.folder_id;
    if (!folder_id) return;
    return new URLSearchParams(`folder_id=${folder_id}`);
  }

  const automationMatch = matchPath(
    { path: Route.Automation, end: false },
    pathname,
  );

  if (automationMatch) {
    const automation_id = automationMatch?.params.automation_id;
    if (!automation_id) return;
    return new URLSearchParams(`automation_id=${automation_id}`);
  }

  const reportMatch = matchPath({ path: Route.Report, end: false }, pathname);

  if (reportMatch) {
    const report_id = reportMatch?.params.id;
    if (!report_id) return;
    return new URLSearchParams(`report_id=${report_id}`);
  }

  const ruleMatch = matchPath(
    { path: Route.RuleEngineRule, end: false },
    pathname,
  );

  if (ruleMatch) {
    const rule_id = ruleMatch?.params.id;
    if (!rule_id) return;
    return new URLSearchParams(`rule_id=${rule_id}`);
  }
};

const extractTokenId = () => {
  const sessionTokenId = sessionStorage.getItem('token_id');
  const { search, pathname } = window.location;
  const params = new URLSearchParams(search);
  const paramToken = params.get('token') || '';
  if (config.useAuthorizationHeader) {
    return sessionTokenId || paramToken;
  }

  if (pathname === Route.MagicLogin) {
    return paramToken;
  }

  return 'cookie';
};

export const loginFromToken = async () => {
  const tokenId = extractTokenId();
  const { pathname } = window.location;

  try {
    const response = await getToken(tokenId, tokenParams(pathname));
    const isInitialized = getAutoLoginState();
    if (response.ok) {
      const token: Token = await response.json();
      setToken(token);
      trackIdentity(token);
      trackGroup(token);
    } else if (response.status === 404) {
      window.location.href = Route.NotFound;
    } else if (!isInitialized) {
      if (pathname.includes(Route.MagicLogin)) {
        setState({ error: 'An error occured while trying to login' });
      } else {
        // do not show an error on inital call to get token
        setState({});
      }
    } else {
      setState({ error: 'An error occured while trying to login' });
    }
  } catch {
    setState({ error: 'An error occured while trying to login' });
  }
  setAutoLoginState(true);
};

// Immediately trigger login from cookie or token
loginFromToken();

export const useAuth = () => {
  const [isInitialized] = useAutoLoginState();
  const [state] = useStore();
  const { token } = state;
  // basically only for guest users we will reduce the vision.
  // for the other users as they have Role policies in place we do not.
  // TODO: make the backend service smarter and give more knowledge about the frontend so we could get this data from the backend and render the frontend based on that.
  const isGuest = token?.user.role === 'guest';
  const policyStatements = token?.user.policy_statements;

  const allowedRoutes = () => {
    if (isGuest) {
      // if a guest has no policies we show just the report overview for now
      const guestRoutes = policyStatements?.map(
        (item) => PolicyActionMap[item.action],
      );

      if (guestRoutes && guestRoutes.length > 0) return guestRoutes;

      // TODO: we need kind of a new dashboard screen
      return [Route.Reports];
    }
    return Object.values(Route);
  };

  return {
    isAuthenticated: !!token,
    isGod: token?.user.role === 'god',
    isAdmin: token?.user.role === 'admin' || token?.user.role === 'god',
    isDefault: token?.user.role === 'default',
    isPlanDefault: token?.organization.plan === 'default',
    allowedRoutes: allowedRoutes(),
    // Only in production
    isMineMarketing:
      token?.organization.id === '30114442-c8e7-4dc6-bbdb-64f8c53c7a55',
    isInitialized,
    ...state,
  };
};
