import fetch from 'isomorphic-unfetch';
import { config } from '../../config';
import { UserForm } from '../../types/api/entity/UserForm';
import { headers } from '../auth/headers';
import { PolicyStatement } from '../../types/api/entity/User';

export const getUsers = () =>
  fetch(`${config.apiBaseUrl}/v1/entity/users`, {
    method: 'GET',
    ...headers(),
  });

export const archiveUser = (id: string) =>
  fetch(`${config.apiBaseUrl}/v1/entity/users/${id}/archive`, {
    method: 'POST',
    ...headers(),
  });

export const updateUser = (id: string, form: UserForm) =>
  fetch(`${config.apiBaseUrl}/v1/entity/users/${id}`, {
    method: 'PUT',
    body: JSON.stringify(form),
    ...headers(),
  });

export const createPolicyStatement = (
  id: string,
  form: { policy_statement: PolicyStatement },
) =>
  fetch(`${config.apiBaseUrl}/v1/entity/users/${id}/policy_statements`, {
    method: 'POST',
    body: JSON.stringify(form),
    ...headers(),
  });

export default {
  updateUser,
  archiveUser,
  createPolicyStatement,
  getUsers,
};
