import styled from 'styled-components';

export const Button = styled.button`
  margin: ${({ theme }) => theme.spacing.s} 0;
  padding: ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.color.warning};
  color: ${({ theme }) => theme.color.white};
  border-radius: 4px;
  cursor: pointer;
`;
