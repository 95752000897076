import { FC, ComponentProps } from 'react';
import { Card, Empty, Space, Typography } from 'antd';
import { Info } from './styled';
import { ProcessorRender } from './processorRender/ProcessorRender';
import { useTranslation } from 'react-i18next';

type Props = {
  processor?: ComponentProps<typeof ProcessorRender>;
};

export const SelectedProcessor: FC<Props> = ({ processor }) => {
  const { t } = useTranslation('processors');
  return (
    <Info>
      {!processor && <Empty description={t('library.noSelection')} />}
      {processor && (
        <Card>
          <Space direction="vertical" size={20}>
            <ProcessorRender {...processor} />
            <Typography.Paragraph>{processor.description}</Typography.Paragraph>
          </Space>
        </Card>
      )}
    </Info>
  );
};
