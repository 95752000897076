import { Coordinates } from '../types/api/automation/Graph';
import { Rect } from '../types/ui/Rect';

const isPointInside =
  ({ x1, y1, x2, y2 }: Omit<Rect, 'isPointInside'>) =>
  ({ x, y }: Coordinates) =>
    x > x1 && x < x2 && y > y1 && y < y2;

export const createRect = (
  coordsA: Coordinates,
  coordsB: Coordinates,
): Rect => {
  const xs =
    coordsA.x < coordsB.x
      ? { x1: coordsA.x, x2: coordsB.x }
      : { x1: coordsB.x, x2: coordsA.x };
  const ys =
    coordsA.y < coordsB.y
      ? { y1: coordsA.y, y2: coordsB.y }
      : { y1: coordsB.y, y2: coordsA.y };

  const rect = { ...xs, ...ys };
  return { ...rect, isPointInside: isPointInside(rect) };
};
