import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  height: 46px;
  border-bottom: 1px solid ${({ theme }) => theme.color.lighterGrey};
`;

export const TitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.m};
  font-size: 20px;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: ${({ theme }) => theme.color.darkest};
`;

export const CloseContainer = styled.div`
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px ${({ theme }) => theme.spacing.m};
  cursor: pointer;
  color: ${({ theme }) => theme.color.grey};
  transition: color 0.2s;

  svg {
    height: 18px;
    width: 18px;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.color.darkest};
  }
`;
