import { FC, ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButton } from './styled';
import { ReactComponent as Icon } from '../../assets/icons/google-signin.svg';
import { ReactComponent as IconDisabled } from '../../assets/icons/google-signin-disabled.svg';

type Props = ComponentProps<typeof StyledButton> & {
  type?: 'login' | 'register';
};

export const GoogleButton: FC<Props> = ({
  children,
  type = 'login',
  disabled,
  ...rest
}) => {
  const { t } = useTranslation('google');
  return (
    <StyledButton {...rest} disabled={disabled}>
      {disabled ? <IconDisabled /> : <Icon />}
      <span>{t(type)}</span>
    </StyledButton>
  );
};
