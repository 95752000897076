import { FC } from 'react';
import { ColorContainer as Container, ColorInput } from './styled';
import { ReactComponent as Check } from '../../../../assets/ui/check.svg';

interface Props {
  color: string;
  title: string;
  onChange: (v: any) => void;
  checked?: boolean;
}

export const ColorSelect: FC<Props> = ({ color, title, onChange, checked }) => (
  <Container color={color} htmlFor={title}>
    <ColorInput
      type="radio"
      name="color"
      id={title}
      value={color}
      onChange={onChange}
      checked={checked}
    />
    <Check />
  </Container>
);
