import { StoreId } from '../types/ui/StoreId';
import { createStore } from 'usestore-react';

export type State = string[];

const { setState, useStore } = createStore<State | undefined>(
  StoreId.EditorGroupIds,
  undefined,
);

const updateGroupIds = (
  groupIds?: string[],
  groupId?: string,
): string[] | undefined => {
  if (!groupIds && !groupId) return;
  if (!groupIds && groupId) return [groupId];

  if (groupIds && groupId) {
    const index = groupIds.indexOf(groupId);
    if (index >= 0) {
      return groupIds.slice(0, index + 1);
    }
    return [...groupIds, groupId];
  }
};

export const setGroupIds = setState;

export const setGroupId = (groupId?: string) =>
  setState((state) => updateGroupIds(state, groupId));

export const useGroupIds = () => {
  const [state] = useStore();
  return state;
};
